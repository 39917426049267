import React, { useState, useEffect } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Choose1 from "../../Components/seperate Components/Choose1";
import slider1 from "../../assets/slider/slider-shape-1.png";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

const GapAnalysis = () => {
  const [description, setDescription] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          "https://mars.zithas.com/api/currentopening/wordpress-developer"
        );
        setDescription(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Business Software Audit Expert in England, UK | Zithas Technologies
        </title>
        <meta
          name="description"
          content="At Zithas Technologies, we offer the finest business software audit services in Bristol and within England with the help of our software audit experts."
        />
        <meta
          name="keywords"
          content="business software audit, business software audit england, 
        business software audit expert england
        "
        />
        <link rel="canonical" href="localhost:3001/business-software-audit" />
      </Helmet>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg pt-220 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="bd-page-title text-center z-index"
                style={{ bottom: "45px" }}
              >
                <h2
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Business software Audit Expert in Bristol, England
                </h2>
                <p style={{ color: "black" }}>
                  Stay ahead of the competition with the guidance of a business
                  software audit expert in England, Bristol
                </p>
                <div className="breadcrumb-menu"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Choose1 />
      <section className="bd-choose-area fix">
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="bd-choose-shape">
            <div className="row ">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 first_resp_audit">
                <div className="bd-choose-text mb-30 z-index">
                  <div className="bd-section-title-wrapper mb-35">
                    <h2 className="bd-section-title title_resp mb-15 ml-15">
                      <span style={{ color: "#ffbf1e" }}>
                        Business Software Audit :
                      </span>{" "}
                      A Necessity, Not a Hype!
                    </h2>
                    <p className="ml-15 para_one">
                      Our professional team work closely with a variety of
                      sources to identify gaps in business performance and then
                      craft innovative solutions to help improve operational
                      efficiency.
                    </p>
                    <h4 className="ml-15 para_one">
                      Business software audit can be the key to streamline your
                      organization!
                    </h4>
                    <p className="ml-15 para_one">
                      Our data modelling and analytics capabilities assist us in
                      identifying previously unnoticed areas for improvement,
                      and our{" "}
                      <Link to="/bespoke-systems" style={{ fontWeight: "500" }}>
                        custom system
                      </Link>{" "}
                      can automate the work process within your organisation. We
                      can help you achieve your goals by working together.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 second_resp_audit">
                <div className="bd-choose-img mb-30 z-index mt-125">
                  <div className="bd-choose-info-wrapper">
                    <div className="container ">
                      <div className="row mb-50">
                        <div className="col cell_resp mr-50">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/Data-Collection_fmhukf.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Risk Identification
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div
                              className="bd-choose-info-icon mb-15"
                              style={{ color: "#5e5858" }}
                            >
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/Data-Analysis_j7xnsp.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Business Analysis
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container ">
                      <div className="row second_flex ">
                        <div className="col cell_resp cell_padd_resp mr-50">
                          <div className="animate-hover">
                            <div
                              className="bd-choose-info-icon mb-15"
                              style={{ color: "#5e5858" }}
                            >
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/Buisness-Planning_rumqtc.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Compliance Enforcement
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/automated-process-64_f0p7gm.avif"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Fraud Detection
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-0" style={{ maxWidth: "1250px" }}>
        <div className="row three_section_resp">
          <div className="col-sm" style={{ color: "black" }}>
            <div className="top-border-line"></div>
            <div
              className="bd-about-text-five-fact"
              data-animation="fadeInUp"
              data-delay="1.1s"
            >
              <div className="section-number pb-30">01</div>
              <h4
                className="bd-slider-fact-title text-heading pb-30"
                style={{ fontSize: "26px" }}
              >
                Business Process and Rules
              </h4>
              <span
                className="bd-slider-fact-subtitle"
                style={{ color: "#5e5858" }}
              >
                Business processes are critical, but they do not need to be
                overly complicated. Create a workable balance for your company
                and enforce rules.
              </span>
            </div>
          </div>
          <div className="col-sm ml-40 block_two " style={{ color: "black" }}>
            <div className="top-border-line"></div>
            <div
              className="bd-about-text-five-fact"
              data-animation="fadeInUp"
              data-delay="1.1s"
            >
              <div className="section-number pb-30">02</div>
              <h4
                className="bd-slider-fact-title text-heading pb-30"
                style={{ fontSize: "26px" }}
              >
                Software architecture used in systems
              </h4>
              <span
                className="bd-slider-fact-subtitle"
                style={{ color: "#5e5858" }}
              >
                Most software systems are designed to be specific, which can be
                frustrating. We help you create a system that works best for
                you!
              </span>
            </div>
          </div>
          <div className="col-sm ml-40 block_two" style={{ color: "black" }}>
            <div className="top-border-line"></div>
            <div
              className="bd-about-text-five-fact"
              data-animation="fadeInUp"
              data-delay="1.1s"
            >
              <div className="section-number pb-30">03</div>
              <h4
                className="bd-slider-fact-title text-heading pb-30"
                style={{ fontSize: "26px" }}
              >
                Data access mechanisms and structures
              </h4>
              <span
                className="bd-slider-fact-subtitle"
                style={{ color: "#5e5858" }}
              >
                You can navigate software data internally between two different
                programmes by using a data access mechanism.
              </span>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-slider-area pt-80" style={{ height: "700px" }}>
        <div className="bd-slider-actives" style={{ height: "700px" }}>
          <div className="swiper-wrappers" style={{ height: "700px" }}>
            <div className="bd-single-slider bd-slider-height bd-single-slider-overlay-invisible d-flex align-items-center swiper-slides">
              <div className="bd-slide-bg">
                <img
                  src="https://img.zithas.co.uk/software-audit-banner_th7wsk.avif"
                  alt="img not found"
                  style={{ height: "100%" }}
                />
              </div>
              <div className="bd-slide-shape">
                <img
                  src={slider1}
                  style={{
                    height: "700px",
                    width: "1800px",
                    backgroundColor: "rgba(0,0,0,0.7)",
                  }}
                  alt="img not found"
                />
              </div>
              <div className="container" style={{ maxWidth: "1180px" }}>
                <div className="row">
                  <div className="col-12">
                    <div className="bd-slider-two bd-response z-index mb-200 pb-20">
                      <h1
                        className="bd-slider-title-two mb-25 wow fadeInUp"
                        data-wow-delay=".2s"
                        style={{ color: "#c89fdb" }}
                      >
                        Make your Business That Rocks in England UK
                      </h1>
                      <h4
                        className="bd-slider-subtitle-two mb-45 wow fadeInUp"
                        style={{}}
                        data-wow-delay=".4s"
                      >
                        With our expert consultations, we can help you
                        understand your business and make the most of it.
                      </h4>
                      <div
                        className="bd-slider-btn  wow fadeInUp"
                        data-wow-delay=".6s"
                      >
                        <Link
                          to={"/about-us"}
                          className="theme-btn theme-btn-rounded"
                        >
                          About US
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-slider-area fix">
        <div className="bd-slider-actives">
          <div className="swiper-wrappers">
            <div
              className="bd-single-slider gray-bg bd-slider-height w-full position-relative pt-80 pb-80"
              style={{ minHeight: "0px" }}
            >
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row align-items-center">
                  <div className="col-xl-7">
                    <div className="bd-slider-six">
                      <h1
                        className="bd-slider-six-title  sixth-title mb-15 wow fadeInUp"
                        data-wow-delay=".2s"
                      >
                        <span style={{ color: "#ffbf1e" }}>
                          Business Software Audit
                        </span>{" "}
                        That Ace Your Business
                      </h1>
                      <p
                        className="mb-20 para_resp_one"
                        style={{ fontSize: "18px" }}
                      >
                        A business software audit reviews a company's
                        operations, including its systems and processes, to
                        identify inefficiencies, waste, and risks. It also seeks
                        to identify opportunities for growth by highlighting
                        areas for improvement.
                      </p>
                      <p
                        className="para_resp_one"
                        style={{ width: "95%", fontSize: "18px" }}
                      >
                        Our Business software audits can be used to provide
                        insights into how your business is performing and
                        identify areas where you can improve. Additionally, we
                        can also be used in support of risk mitigation decisions
                        as well as strategic planning. Analytics can be used to
                        measure profits, losses, and customer feedback; this
                        data can then be used to create detailed reports that
                        help you make informed decisions.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-5">
                    <div className="bd-slider-six-img">
                      <img
                        src="https://img.zithas.co.uk/business-audit-last-section_zyqxiq.avif"
                        className="rounded-10"
                        alt="img not found"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for Business Analyst Expert?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great analyst, Let's get started right now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default GapAnalysis;
