import React from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import '../../Styles/Redirecting Styles/FooterStyle/Policy.css'
import Footer from '../../Components/Footer';
import "../../Styles/Redirecting Styles/NavbarStyles/Software.css"
import { Helmet } from 'react-helmet';

const TermsAndCondition = () => {
    return (
        <div>
            <Helmet>
                <title>Terms and Conditions | Best SEO Company in England, UK | Zithas Technologies</title>
                <meta name='description' content='See our company terms and conditions. We are the best SEO company in the UK offering the best digital marketing services and custom software solutions.' />

                <meta name="keywords" content="zithas technologies terms and conditions" />
            </Helmet>
            <Header1 />
            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg_policy pt-175 pb-155 main-privacy-responsive" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index">
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Terms and Conditions</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <section className="bd-about-six-area    pt-80">
                <div className="container" style={{ maxWidth: "1250px" }}>
                    <div className="row">
                        <div className="col-lg-12 col-md-8">
                            <div className="bd-about-six-text">
                                <div className="bd-section-title-wrapper mb-25">
                                </div>
                                <div className="bd-about-six-feature pt-20">
                                    <ul className="mb-45">
                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i> Introduction</li>
                                        <p className="soft-para mb-25">  These Terms and Conditions govern your use of our services and website. By using our services, you agree to be bound by these terms and conditions. If you do not agree with these terms and conditions, you should not use our services.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Payment and Billing</li>
                                        <p className="soft-para mb-25 ">You will be billed for the services you use on a recurring basis, as outlined in your service agreement. You must provide valid payment information to use our services. If your payment information is invalid or your account becomes past due, we may suspend or terminate your service.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Service Availability</li>
                                        <p className="soft-para mb-25">We will make every effort to ensure that our services are available at all times. However, we do not guarantee that our services will be available at all times or that they will be free from errors or interruptions. We reserve the right to suspend or terminate service at any time for any reason.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Intellectual Property</li>
                                        <p className="soft-para mb-25">All content and materials on our website and in our services, including software, text, images, and other materials, are protected by copyright and other intellectual property laws. You may not use these materials without our express written permission.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Indemnification</li>
                                        <p className="soft-para mb-25">You agree to indemnify and hold us harmless from any claims, losses, damages, and expenses, including attorney's fees, arising out of or in connection with your use of our services or website.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Governing Law</li>
                                        <p className="soft-para mb-25">These terms and conditions are governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of or in connection with these terms and conditions will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Content</li>
                                        <p className="soft-para mb-25">Client agrees to provide all product information & images required for the support of Zithas Technologies Ltd. efforts. Zithas Technologies Ltd. provide additional service of Content writing.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Managing Clients Internal Resources</li>
                                        <p className="soft-para mb-25">Client agrees that all resources will report to Zithas Technologies Ltd. on an as-needed basis to implement the program effectively. Resources will be managed and provided tasks to support the development, day to day efforts, and daily management set forth by Zithas Technologies Ltd. and the executive committee of the client.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Additional Services Fees</li>
                                        <p className="soft-para mb-25"> In the event, the Client would like to use Zithas Technologies Ltd. for services outside of the scope of services additional charges may apply.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Copyrights and Trademarks</li>
                                        <p className="soft-para mb-25">The Client guarantees that all text, graphics, videos, photos, content, designs, trademarks, or other artwork given to Zithas Technologies Ltd. are either owned by the Client or authorized for use. The Client agrees to protect Zithas Technologies Ltd. from any claim or suit related to these elements.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Ownership to Deliverables</li>
                                        <p className="soft-para mb-25">The newly developed website belongs exclusively to the Client, and Zithas Technologies Ltd. may reuse certain modules under a written agreement. Zithas Technologies Ltd. retains intellectual rights to previous items, except for the project, but can continue using them if they are licensed for development.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Work Credit</li>
                                        <p className="soft-para mb-25"> Client agrees to allow Zithas Technologies Ltd. to use clients name and website for company promotions, online portfolio, past clients list, on social media, in print material, etc. for advertising and promoting Zithas Technologies Ltd. services to other companies.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Project Validity</li>
                                        <p className="soft-para mb-25">The agreement is valid for 6 months from signing, and the project can be paused if the Client doesn't provide necessary information or response. The validity period can be extended based on mutual cooperation. If the contract expires or is terminated, it must be restarted with new requirements and payment. Notification of termination must be sent via email by both parties.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Fax/Scanned/ Digital Signed Copy of Signature</li>
                                        <p className="soft-para mb-25">Both parties agree that a faxed or scanned or digital signed copy of the signed document by either or both parties shall be considered acceptable, legal, and legally binding.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Maintenance</li>
                                        <p className="soft-para mb-25">The Client understands that once the website is completed and installed to the Clients web space the Client takes on the responsibility of maintaining all aspects the site, such as text, images, video and sound files. Zithas Technologies Ltd. will make changes to the site per the Clients request which will be billed at the rate.</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </div >
    )
}

export default TermsAndCondition