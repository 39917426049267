import React, { useState } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPhone } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Components/Footer";
import "../../Styles/Redirecting Styles/NavbarStyles/mobile.css";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const MobileApp = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Helmet>
        <title>Best Mobile App Development Company in London, England</title>
        <meta
          name="description"
          content="Being one of the top mobile app development companies in England,UK, we offer the best mobile application development services in Wales, Cardiff, London, and Bristol."
        />
        <meta
          name="keywords"
          content="
                app development,
                mobile application,
                mobile apps development uk,
                mobile apps development bristol,
                mobile apps development london,
                mobile apps development wales,
                mobile apps development cardiff,
                mobile app development england, 
                mobile app company england, 
                mobile app development bristol,
                mobile app development london,
                mobile app development cardiff,
                mobile app development uk,
                mobile app development company,
                app development company london,
                mobile app company london,
                app development company,
                best app development company,
                best mobile app company,
                top app development companies in uk,
                mobile application development companies,
                mobile application development companies bristol
                "
        />
      </Helmet>

      <Header1 />

      {/* Banner section */}
      <div
        className="bd-page-title-area bd-page-title-bg4 pt-220 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="bd-page-title text-center z-index"
                style={{ bottom: "45px" }}
              >
                <h1 className="breadcrumb-title1 mobile-main-title">
                  Mobile App Development
                </h1>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      Empower your business with the latest mobile technology.
                      Stay ahead of the competition with our innovative
                      solutions.
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-choose-area fix pb-80 pt-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="bd-choose-shape">
            <div className="row">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 pr-20">
                <div className="bd-choose-text mb-30 z-index">
                  <div className="bd-section-title-wrapper mb-25">
                    <h2 className="bd-section-title mb-15 first-mobile-tittle">
                      Get Your{" "}
                      <span style={{ color: "#ffbf1e" }}>
                        {" "}
                        Business Mobilising{" "}
                      </span>{" "}
                      in Bristol, England
                    </h2>
                  </div>

                  <p
                    className="mb-20"
                    style={{ width: "95%", fontSize: "18px" }}
                  >
                    At our mobile app development company catering to Bristol,
                    London, Cardiff, England and Wales, we understand the
                    importance of mobile technology for businesses in today's
                    digital age. We specialize in developing mobile applications
                    that can enhance the way your business operates and help you
                    to reach new heights.
                  </p>
                  <p className="mb-20" style={{ fontSize: "18px" }}>
                    Our mobile app development services located in London,
                    Wales, Cardiff, England and Bristol can help your business
                    reach new customers, increase sales and improve customer
                    loyalty. With a mobile application, you can provide your
                    customers with a convenient and personalized experience,
                    which will help to increase customer engagement and drive
                    business growth. With our company's mobile application
                    development services, you can be confident that your
                    business will stay ahead of the competition and reach new
                    heights in Bristol and the surrounding areas.{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                <div className="bd-choose-img text-end  z-index">
                  <div className="row align-items-center custom-mar-15 top-resp">
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                        <img
                          src="https://img.zithas.co.uk/mobile-development-section-1.1_mjhkpe.avif"
                          className="bordertop-img1"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15">
                        <img
                          src="https://img.zithas.co.uk/mobile-development-section-1_p0elfe.avif"
                          className="bordertop-img2"
                          alt="img not found"
                        />
                      </div>
                      <div className="bd-choose-img-inner mb-15">
                        <img
                          src="https://img.zithas.co.uk/mobile-main-left_hcqulg.avif"
                          className="bordertop-img3"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="bd-choose-shape bd-choose-shape-2"
                    src={Shape2}
                    alt="shape img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-choose-area  fix">
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="bd-choose-shape">
            <div className="row second-mobile-responsive">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 second-mobile-section1">
                <h2 className="bd-section-title mb-10 ml-15 four-point-tittle">
                  Digitalize Your Business to Succeed
                </h2>
                <div className="bd-choose-text z-index">
                  <div className="bd-section-title-wrapper">
                    <p className="ml-25 mobile-para1">
                      We specialize in streamlining business processes,{" "}
                      <Link
                        to="/business-automation"
                        style={{ fontWeight: "500" }}
                      >
                        automating repetitive tasks
                      </Link>
                      , and implementing new technologies to improve efficiency
                      and productivity. By using our service, your business can
                      experience a radical change in how it operates, which can
                      lead to increased revenue, improved customer satisfaction,
                      and a more efficient workforce. We can help you to
                      identify areas of improvement and develop a plan to
                      address them. Our{" "}
                      <Link to="/hire-a-resource" style={{ fontWeight: "500" }}>
                        team of experts
                      </Link>{" "}
                      will work closely with you to implement the changes and
                      ensure that the transition is smooth and successful.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 second-mobile-section2">
                <div className="bd-choose-img mb-30 z-index">
                  <div className="bd-choose-info-wrapper">
                    <div className="container">
                      <div className="row mb-50">
                        <div
                          className="col-6 four-section"
                          style={{ marginRight: "0px" }}
                        >
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/billboard-64_t3iwkx.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Increased Brand Awareness
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/business-64_twuqgz.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Higher Business Engagement Level{" "}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ml-15">
                      <div className="col-6 four-section2 ">
                        <div className="animate-hover">
                          <div className="bd-choose-info-icon mb-15">
                            <i>
                              <img
                                src="https://img.zithas.co.uk/communication-64_f7z6bt.webp"
                                alt="img not found"
                              />
                            </i>
                          </div>
                          <div className="bd-choose-info-text">
                            <h2
                              className="bd-choose-info-title mb-15"
                              style={{ color: "#5e5858" }}
                            >
                              Direct Communication Options
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="animate-hover">
                          <div className="bd-choose-info-icon mb-15">
                            <i>
                              <img
                                src="https://img.zithas.co.uk/revenue-64_xemz7w.webp"
                                alt="img not found"
                              />
                            </i>
                          </div>
                          <div className="bd-choose-info-text">
                            <h2
                              className="bd-choose-info-title mb-15"
                              style={{ color: "#5e5858" }}
                            >
                              Boosting Sales & Revenues
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-six-area  pt-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <h2 className="bd-section-title mb-20 third-mobile-tittle">
            Boost Competitive Edge with the Right{" "}
            <span style={{ color: "#ffbf1e" }}> Mobile App</span>{" "}
          </h2>
          <ul style={{ color: "black", fontSize: "20px", listStyle: "none" }}>
            <li>
              The process of creating a mobile application can involve different
              phases such as design, development, testing, and release. It can
              be either a native or hybrid app, depending on the approach taken
              during the development stage.
            </li>
          </ul>
          <div
            className="bd-service service-mobile mb-30 mt-30"
            style={{ backgroundColor: "var(--clr-bg-gray)" }}
          >
            <div className="row third-mobile-section1">
              <div className="col-lg-6 mobile-subpoint-section">
                <h4 style={{ fontWeight: "600" }}>Native Mobile Application</h4>
                <div className="bd-about-six-text">
                  <div className="bd-section-title-wrapper mb-25">
                    <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                      Native mobile applications are specifically designed for a
                      specific mobile operating system, such as Swift for iOS
                      and Java, Kotlin for Android. These apps are not
                      compatible with other platforms and can only be run on the
                      device and OS for which they were developed. They can be
                      found and updated on the appropriate app store such as the
                      App Store or Google Play Store.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-8 mobile-subpoint-section ">
                <div className="bd-about-six-img">
                  <div className="bd-about-six-feature six-feature-mobile pl-140">
                    <h4 className="mb-15" style={{ fontWeight: "600" }}>
                      Key Features
                    </h4>
                    <ul className="">
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Accessed by the icons on the home screen
                      </li>
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Fastest and provide a reliable experience
                      </li>
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Advantage of numerous mobile features
                      </li>
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Application works in offline mode
                      </li>
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Tends to Have Fewer Bugs
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bd-service mb-30 service-mobile"
            style={{ backgroundColor: "var(--clr-bg-gray)" }}
          >
            <div className="row third-mobile-section1">
              <div className="col-lg-6 mobile-subpoint-section">
                <h4 style={{ fontWeight: "600" }}>Hybrid Mobile Application</h4>
                <div className="bd-about-six-text">
                  <div className="bd-section-title-wrapper mb-25">
                    <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                      Hybrid applications, which are created using JavaScript
                      and HTML5 like web applications, can be accessed and
                      downloaded through both web browsers and app stores. These
                      apps require minimal maintenance and can be slower than
                      native apps due to their dependence on the browser's
                      speed. They are designed to display the content of a
                      website in an app-like format.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-8 mobile-subpoint-section">
                <div className="bd-about-six-img">
                  <div className="bd-about-six-feature six-feature-mobile pl-140">
                    <h4 className="mb-15" style={{ fontWeight: "600" }}>
                      Key Features
                    </h4>
                    <ul className="">
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Work on various platforms
                      </li>
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        The maintenance is simple
                      </li>
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                        High-speed capability
                      </li>
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Attractive user interface
                      </li>
                      <li
                        style={{
                          fontSize: "18px",
                          color: "var(--clr-body-text)",
                        }}
                      >
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Target a Wider User Base
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-six-area pt-70">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <h2 className="bd-section-title mb-15 fourth-sec-tittle">
              Transform your Business with
              <span style={{ color: "#ffbf1e" }}> Mobile Innovation</span>
            </h2>
            <div className="col-lg-6 " style={{ width: "100%" }}>
              <div className="bd-about-six-text">
                <div className="bd-section-title-wrapper ">
                  <div className="bd-about-six-feature">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        marginBottom: "25px",
                      }}
                    >
                      There are many companies that offer mobile application
                      services, so it is important to select the right one for
                      business.
                    </p>
                    <ul className="">
                      <li style={{ fontSize: "22px", fontWeight: "600" }}>
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Do your research{" "}
                      </li>
                      <p className="pb-20 pl-25">
                        It's crucial to do your homework and find out what each
                        firm has to offer before selecting one to create your
                        mobile application. This will aid you in selecting the
                        best provider for your unique requirements.
                      </p>

                      <li style={{ fontSize: "22px", fontWeight: "600" }}>
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>{" "}
                        Look for features
                      </li>
                      <p className="pl-25">
                        The characteristics that are essential to your business
                        should be taken into account while searching for the
                        best app development company to create your mobile
                        application. Customer loyalty programmes,
                        advertisements, and functionality unique to your sector
                        or product are a few examples of these characteristics.
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-service-details-area pt-80 pb-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row ">
            <div className="col-lg-8" style={{ width: "100%" }}>
              <div className="bd-service-details1">
                <div className="row pb-10 fifth-responsive-sec">
                  <div className="col-xl-5 col-lg-6 mobile-fifth-sec1">
                    <h5 className="mb-10 fourth-sec-tittle">
                      {" "}
                      <span style={{ color: "#ffbf1e" }}>Tech Savvy Team </span>
                      for Business Success
                    </h5>
                    <div className="bd-service-details-feature ">
                      <div>
                        <p style={{ fontSize: "17px" }}>
                          At Zithas Technologies, our company is comprised of a
                          team of tech enthusiasts who are dedicated to
                          assisting businesses in the UK with their digital
                          needs. We understand the importance of having easy to
                          navigate and use technology for your business, which
                          is why we are committed to providing our clients with
                          the best solutions. Whether you need help with website
                          design,{" "}
                          <Link
                            to="/digital-marketing"
                            style={{ fontWeight: "500" }}
                          >
                            digital marketing
                          </Link>
                          , or any other tech-related service, our team is ready
                          to assist you. Contact us today to learn more about
                          how we can help your business thrive in the digital
                          age.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 mobile-fifth-sec2">
                    <div
                      className="bd-service-details-feature-img"
                      style={{ paddingTop: "10px" }}
                    >
                      <img
                        src="https://img.zithas.co.uk/mobile-development-last-section_fd8wss.avif"
                        alt="img not found"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for App Development Expert?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great App Development, Let's get started right
                  now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MobileApp;
