import React, { useState } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Components/Footer";
import "../../Styles/Redirecting Styles/NavbarStyles/Dedicate.css";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const DedicatedResource = () => {
  return (
    <div>
      <Helmet>
        <title>
          Hire Dedicated Resources from Zithas Technologies in England, UK
        </title>
        <meta
          name="description"
          content="Are you looking to hire a dedicated developer team to expand your business in Bristol, Cardiff, Wales, London, and within England? Hire our team to develop software, web and mobile app."
        />
        <meta
          name="keywords"
          content="
                hire dedicated resources,
                best dedicated resources,
                dedicated resources bristol,
                dedicated resources london,
                dedicated resources wales,
                dedicated resources england, 
                dedicated resources cardiff,
                hire dedicated resources uk
                "
        />
      </Helmet>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg6 pt-220 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="bd-page-title text-center z-index"
                style={{ bottom: "45px" }}
              >
                <h1
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Hire A Resource in UK
                </h1>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <nav className="breadcrumb-trail breadcrumbs">
                      <p style={{ color: "black" }}>
                        Our dedicated resource will take care of the heavy
                        lifting, allowing you to focus on growth and expansion.
                      </p>
                    </nav>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-about-area">
        <div className="bd-custom-container">
          <div className="bd-about-four-bg  pt-80 pb-80 fix">
            <div className="bd-about-shape-box">
              <img
                className="bd-about-shape bd-about-shape-1"
                src="assets/img/shape/about-shape-3.png"
                alt="shape img not found"
              />
              <img
                className="bd-about-shape bd-about-shape-2"
                src="assets/img/shape/about-shape-4.png"
                alt="shape img not found"
              />
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8  first_dedicate">
                    <div className="bd-choose-img text-end mb-30 z-index top-resp">
                      <div className="row align-items-center custom-mar-15">
                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                          <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                            <img
                              src="https://img.zithas.co.uk/dedicate-resource-1.1_kcjlyy.avif"
                              className="resp-img bordertop-img1"
                              alt="img not found"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                          <div className="bd-choose-img-inner mb-15">
                            <img
                              src="https://img.zithas.co.uk/dedicate-resource-1_eh2ngd.avif"
                              className="resp-img bordertop-img2"
                              alt="img not found"
                            />
                          </div>
                          <div className="bd-choose-img-inner mb-15">
                            <img
                              src="https://img.zithas.co.uk/dedicate-main-b_rwpr1s.avif"
                              className="resp-img bordertop-img3"
                              alt="img not found"
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        className="bd-choose-shape bd-choose-shape-2"
                        src={Shape2}
                        alt="shape img not found"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 second_dedicated">
                    <div className="bd-about-text bd-about-text-four z-index">
                      <div className="bd-section-title-wrapper">
                        <h2 className="bd-section-title md-pr-50 dedicate-first-tittle">
                          {" "}
                          Get More Done in <br /> Less Time
                        </h2>
                        <p className="">
                          In order to be successful, it is important to focus on
                          your goals and stay on track. By hiring England's best
                          dedicated resources in your business, you can avoid
                          some of the common pitfalls that can lead to success.
                          These resources can help you stay focused and on
                          course, preventing you from slipping and falling
                          behind in your competitive marketplace.
                        </p>

                        <p className="">
                          A speedier project turnaround time can be achieved
                          with the assistance of dedicated resources, which can
                          also keep you organised and effective. You can also
                          run a more prosperous firm with the help of our
                          resources from Bristol, UK. Employees, resources, or a
                          combination of both are devoted resources in some
                          businesses. As a result, your companies can better
                          manage their resources and have more control over the
                          working environment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-choose-area fix">
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="bd-choose-shape">
            <div className="row dedicated_first_responsive">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 deicated-first_sec">
                <div className="bd-choose-text z-index">
                  <h2 className="bd-section-title ml-15 second_sec-tittle">
                    <span style={{ color: "#ffbf1e" }}>
                      {" "}
                      Hire a Dedicated Developer &
                    </span>{" "}
                    Goodbye tidy work
                  </h2>
                  <div className="bd-section-title-wrapper">
                    <p className="ml-25 dedicat-para1">
                      If you're looking to hire the best-dedicated resources to
                      help you build your business in England, UK, then you're
                      at the right place to consider finding them. We ensure
                      that you have the necessary skills and experience to
                      develop and manage a product to ease up your flow. In
                      addition, you'll want to ensure that the developers you
                      hire are enthusiastic about their work and consider it an
                      crucial part of their job description. Importantly we
                      ensure you're getting the best deal possible.{" "}
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2 deicated-second_sec">
                <div className="bd-choose-img  z-index">
                  <div className="bd-choose-info-wrapper">
                    <div className="container">
                      <div className="row mb-50">
                        <div
                          className="col-6 first-point-col "
                          style={{ marginRight: "0px" }}
                        >
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/workforce-64_kfn3zw.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Robust and Diverse Professional
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/sand-timer-64_zuuoqh.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Speedier Turnaround
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row ">
                        <div className="col third-point-col mr-50">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/report-64_lnmgdx.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Assurance of Success
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/infrastructure-64_dg4ygu.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Built-in Network
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-brand-area-two pb-80 pt-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div
            className="row second-dedicated-resp"
            style={{ alignItems: "center" }}
          >
            <div className="col-xl-4 col-lg-6 col-md-6 third-sec1-responsive">
              <div className="col-12">
                <div className="bd-section-title-wrapper mb-20">
                  <h2 className="bd-section-title mb-0 pl-0 fourth_sec_tittle-dedicat">
                    {" "}
                    Stay Engaged with the aid of a{" "}
                    <span style={{ color: "#ffbf1e" }}>Bristol Team</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-6 col-md-6 third-sec2-responsive">
              <div className="bd-brand-active-two swiper-container">
                <div className="swiper-wrapper">
                  <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                    When it comes to running a successful business, It is
                    essential to hire a resource. By having our team of experts
                    who are focused on your specific industry, your business can
                    stay ahead of the competition and stay profitable. Our team
                    of experts from Bristol includes{" "}
                    <Link to="/digital-marketing" style={{ fontWeight: "500" }}>
                      marketing professionals
                    </Link>
                    , sales people, and more who are committed to helping you
                    reach your goals. We understand that not every business is
                    the same, so we are constantly working to perfect our
                    products and services so that they can meet or exceed your
                    expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-area pb-80">
        <div className="bd-custom-container">
          <div className="bd-about-four-bg pb-0 fix">
            <div className="bd-about-shape-box">
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8 fourth_responsive_section1">
                    <div className="bd-about-text bd-about-text-four z-index">
                      <div className="bd-section-title-wrapper">
                        <h2 className="bd-section-title mb-15  md-pr-50 dedicate-last-tittle">
                          Get the{" "}
                          <span style={{ color: "#ffbf1e" }}> Best Squad </span>{" "}
                          in Bristol, England and stop feeling stressed at work!
                        </h2>
                        <p className="">
                          With our tailored resources and strategies, we've made
                          it easy for you to find the right resources for your
                          business in Bristol itself! We've gathered dedicated
                          resources that's specific to your situation, so you
                          can make the most informed decisions. Our goal is to
                          help you succeed, and we'll continue to provide
                          valuable resources so you can stay competitive and
                          profitable.Our business resource provides accurate and
                          up-to-date result on projects that can help your
                          business to operate smoothly. We include information
                          on everything from the right types of resources to the
                          best ways to use them, so you can make the most
                          efficient use of your limited resources.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 pl-30 fourth_responsive_section2">
                    <div className="bd-about-img">
                      <img
                        src="https://img.zithas.co.uk/dedicate-resource-last-section_uqxcak.avif"
                        className="xss-w-full rounded-10"
                        alt="about img not found"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Are you looking to hire a dedicated developer?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great Developer, Let's get started right now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default DedicatedResource;
