import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import {
  faChevronRight,
  faEnvelopeOpenText,
  faMapLocation,
  faPhone,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Redirecting Styles/FooterStyle/Contact.css";
import Footer from "../../Components/Footer";
import Spinner from "../../Components/seperate Components/Spinner";
import swal from "sweetalert";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [service, setService] = useState("");
  const [description, setDescription] = useState("");
  const [spinner, setSpinner] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      let res = await fetch("https://mars.zithas.com/api/contactus", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          mobile: mobile,
          company_name: company_name,
          service: service,
          description: description,
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMobile("");
        setCompany_name("");
        setService("");
        setDescription("");
        setSpinner(false);
        swal({
          icon: "success",
          text: "Data has been send Sucessfully we will get in touch with you as soon as Possible",
        });
      } else {
        alert("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  function ValidateEmail() {
    var email = document.getElementById("txtEmail").value;
    var lblError = document.getElementById("lblError");
    lblError.innerHTML = "";
    var expr =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (!expr.test(email)) {
      lblError.innerHTML = "Invalid email address.";
    }
  }

  return (
    <div>
      <Helmet>
        <title>
          Free Quote for Website Design and App Development in England, UK
        </title>
        <meta
          name="description"
          content="Are you planning to build an eCommerce website? Great, get in touch with us now and request a free quote for website design and Mobile App Development."
        />
        <meta
          name="keywords"
          content="
                website design company,
                mobile app development uk,
                mobile app development england, 
                build ecommerce website
                "
        />
      </Helmet>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg_contact pt-175 pb-155 "
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Contact Us
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs"></nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bd-contact-area pt-105">
        <div className="container">
          <div className="row ">
            <div className="col-xxl-4 col-xl-5 col-lg-5">
              <h2
                className="bd-section-title mt-20 mb-35"
                style={{ fontSize: "34px", paddinLeft: "35px" }}
              >
                Don't hesitate <br /> to drop us <br /> a line{" "}
              </h2>
              <div className="bd-contact-wrapper pb-20">
                <div className="bd-contct-item gray-bg mb-30">
                  <div className="bd-contct-item-icon">
                    <i>
                      {" "}
                      <FontAwesomeIcon icon={faPhone} />
                    </i>
                  </div>
                  <div className="bd-contct-item-text">
                    <h5 className="bd-contct-item-text-title ">Phone</h5>
                    <ul style={{ listStyle: "none" }}>
                      <li
                        style={{
                          color: "var(--clr-theme-1)",
                          fontWeight: "550",
                        }}
                      >
                        General Inquiry
                      </li>
                    </ul>
                    <a href="tel:(0) 776-742-6142" className="mbb-5 pb-20">
                      (0) 776-742-6142
                    </a>
                  </div>
                </div>
                <div className="bd-contct-item gray-bg mb-30">
                  <div className="bd-contct-item-icon">
                    <i>
                      {" "}
                      <FontAwesomeIcon icon={faEnvelopeOpenText} />
                    </i>
                  </div>
                  <div className="bd-contct-item-text">
                    <h5 className="bd-contct-item-text-title">Email</h5>
                    <a href="mailto:hello@zithas.co.uk" className="mbb-5">
                      hello@zithas.co.uk
                    </a>
                  </div>
                </div>
                <div
                  className="bd-contct-item gray-bg mb-30"
                  style={{ paddingRight: "20px" }}
                >
                  <div className="bd-contct-item-icon">
                    <i>
                      <FontAwesomeIcon icon={faMapLocation} />
                    </i>
                  </div>
                  <div className="bd-contct-item-text">
                    <h5 className="bd-contct-item-text-title">Location</h5>
                    <ul style={{ listStyle: "none" }}>
                      <li
                        style={{
                          color: "var(--clr-theme-1)",
                          fontWeight: "550",
                        }}
                      >
                        Zithas Technologies LTD (UK)
                      </li>
                      <span className="pb-20">
                        {" "}
                        Thornbury, Bristol, England, UK
                      </span>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-7 col-lg-7">
              <div className="bd-contact-form-wrapper mb-50">
                <h5 className="bd-section-subtitle mb-15 subtitle-without-border pl-5">
                  Let's
                </h5>
                <h2 className="bd-section-title mb-35">Get in Touch</h2>
                {spinner && <Spinner />}
                <form onSubmit={handleSubmit}>
                  <div className="row contact-reponsive">
                    <div className="col-md-6">
                      <div className="bd-contact-field mb-30">
                        <input
                          type="text"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="contact-form form-reponsive"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="bd-contact-field mb-30">
                        <input
                          type="text"
                          className="form-reponsive"
                          value={company_name}
                          onChange={(e) => setCompany_name(e.target.value)}
                          placeholder="Company Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-6 email-form-responsive">
                      <div className="bd-contact-field mb-30">
                        <input
                          type="email"
                          value={email}
                          className="form-reponsive"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          id="txtEmail"
                          onKeyUp={ValidateEmail}
                          required
                        />
                        <span id="lblError" className="text-danger"></span>
                      </div>
                    </div>
                    <div className="col-6 email-form-responsive">
                      <div className="bd-contact-field mb-30">
                        <input
                          type="text"
                          value={mobile}
                          className="form-reponsive phone-field"
                          onChange={(e) => setMobile(e.target.value)}
                          placeholder="Phone No"
                          required
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key))
                              event.preventDefault();
                          }}
                        />
                      </div>
                    </div>
                    <h2
                      className="bd-section-title mt-20 mb-35"
                      style={{ fontSize: "34px" }}
                    >
                      Select the Sevice Which You Want
                    </h2>

                    <div className="bd-contact-field mb-30">
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        style={{
                          width: "100%",
                          height: "60px",
                          backgroundColor: "var(--clr-bg-gray)",
                          border: "none",
                          borderRadius: "10px",
                          color: "var(--clr-body-text)",
                          fontWeight: "400",
                          fontSize: "16px",
                          paddingLeft: "20px",
                        }}
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                        required
                      >
                        <option
                          style={{
                            color: "var(--clr-body-text)",
                            fontSize: "16px",
                          }}
                        >
                          Select the Services
                        </option>
                        <option value="Business software Audit">
                          {" "}
                          Business software Audit{" "}
                        </option>
                        <option value="Bespoke System">Bespoke System</option>
                        <option value="Business Automation">
                          Business Automation
                        </option>
                        <option value="Software Development">
                          Software Development
                        </option>
                        <option value="Mobile Apps Development">
                          Mobile Apps Development
                        </option>
                        <option value="Dedicated Resource">
                          Dedicated Resource
                        </option>
                        <option value="Digital Marketing">
                          Digital Marketing
                        </option>
                        <option value="Hosting Services">
                          Hosting Services
                        </option>
                        <option value="MICRO Services">MICRO Services</option>
                        <option value="Maintenance Packages">
                          Maintenance Packages
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <div className="bd-contact-field mb-30">
                        <textarea
                          placeholder="Share your Requirement for Service Selected"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          style={{ height: "220px", width: "100%" }}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="bd-contact-field">
                        <button type="submit" className="theme-btn">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
