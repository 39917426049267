import React, { useState } from 'react'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animateScroll as scroll } from 'react-scroll';
import "../../Styles/seperate Comp Css/Modal.css"
import { Link } from 'react-router-dom';

const Pagination = ({ postPerPage, totalPosts,paginate, currentPosts }) => {
    const pageNumbers = [];
    const [currentPage, setCurrentPage] = useState(1);
    for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
        pageNumbers.push(i);
    }
    const toggleContact = () => {
        scroll.scrollToTop(0, 0);
    }
    return (
        <div className="container pb-80 pt-80">
            <div className="row">
                <div className="col-12">
                    <div className="basic-pagination" page-numbers>
                        <ul>
                            <li>
                                {pageNumbers.includes(currentPage - 1) && <a className="page-numbers" onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                    paginate(currentPage - 1);
                                }}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </a>}
                            </li>
                            {pageNumbers.map((number) => {
                                return (
                                    <li className="bd-responisve-pagination" key={number}>
                                        <Link to={number} className={`page-numbers ${currentPage === number ? "active" : ""}`}
                                        onClick={() =>{
                                        paginate(number);
                                        setCurrentPage(number);}} >
                                            {number}
                                        </Link>
                                    </li>
                                )
                            })}
                            <li>{pageNumbers.includes(currentPage + 1) && <a className="page-numbers" onClick={() => {
                                setCurrentPage(currentPage + 1);
                                paginate(currentPage + 1);
                            }}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </a>}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pagination