import React, { useState } from "react";
import Shape4 from "../assets/brand/about-shape-4.png";
import About1 from "../assets/shape/about-shape-1.png";
import About2 from "../assets/brand/about-shape-2.png";
import About5 from "../assets/brand/about-shape-5.png";
import Choose5 from "../assets/brand/choose-shape-2.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/About.css";
import { faDownload, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import pdf1 from "../assets/company-pdf/Company Profile.pdf";
import "../Styles/seperate Comp Css/Modal.css";
import axios from "axios";
import Spinner from "./seperate Components/Spinner";

const About = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);

    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/portfolio",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        axios({
          url: "https://mars.zithas.com/api/getpdf", //your url
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          alert("Plaese click ok button to Download Company Portfolio");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "company-Portfolio.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [key, setKey] = useState("Transform");

  return (
    <div>
      <section className="bd-about-area ">
        <div className="bd-about-shape-box about-main-responsive pt-100 ">
          <img
            className="bd-about-shape bd-about-shape-2"
            src={Shape4}
            alt="shape img not found"
          />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 first-section-about">
                <div className="bd-about-img mb-30">
                  <img
                    src="https://img.zithas.co.uk/uk-flag-image_z4eybi.avif"
                    style={{ width: "365px", height: "500px" }}
                    className="xss-w-full rounded-10"
                    alt="about img not found"
                  />
                  <img
                    className="bd-about-seconday-img rounded-10"
                    src="https://img.zithas.co.uk/home-girl-specs_pil7id.avif"
                    style={{ border: "1px solid #e3dfdf" }}
                    alt="about img not found"
                  />
                  <img
                    className="bd-about-shape bd-about-shape-3"
                    src={About1}
                    alt="shape img not found"
                  />
                  <img
                    className="bd-about-shape bd-about-shape-4 exta-shape"
                    src={About2}
                    alt="shape img not found"
                  />
                  <img
                    className="bd-about-shape bd-about-shape-5"
                    src={About5}
                    alt="shape img not found"
                  />
                  <img
                    className="bd-about-shape bd-about-shape-6"
                    src={Choose5}
                    alt="shape img not found"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-8">
                <div className="bd-about-text mb-30 z-index">
                  <div className="bd-section-title-wrapper">
                    <h5 className="bd-section-subtitle mb-15">We aim to</h5>
                    <h2
                      className="bd-section-title mb-10 md-pr-50"
                      style={{ fontSize: "52px" }}
                    >
                      Believe in Inspiring{" "}
                      <span style={{ color: "#ffbf1e" }}>Artistry</span>
                    </h2>
                    <p
                      className=""
                      style={{
                        textAlign: "justify",
                        fontSize: "17px",
                        lineHeight: "27px",
                      }}
                    >
                      We are a business automation company in Bristol, UK that
                      specializes in{" "}
                      <Link
                        to="/business-software-audit"
                        style={{ fontWeight: "500" }}
                      >
                        business software audit
                      </Link>{" "}
                      and{" "}
                      <Link to="/bespoke-systems" style={{ fontWeight: "500" }}>
                        bespoke software
                      </Link>
                      . We are experts at creating custom solutions that fit
                      your needs, and our team is passionate about providing the
                      very best customer service. We work with a variety of
                      clients, from small businesses to large organizations, and
                      we have experience working with a wide range of industries
                      for building an ecommerce website, mobile applications,
                      web applications, software applications, etc. Our goal is
                      to provide you with the best possible service, and we take
                      pride in our ability to make your business run better with
                      our services like business automation, web development
                      services, digital marketing services, etc.
                    </p>
                    <p className="mb-20" style={{ fontSize: "17px" }}>
                      Therefore, to help your business run more efficiently and
                      effectively, automate it, audit it, and utilize the best
                      bespoke system services for it.
                    </p>
                    <div className="mt-20">
                      <span className="bd-about-text-btn">
                        <Link
                          to={"/about-us"}
                          className="theme-btn mr-15 three-button-respon"
                          style={{ paddingRight: "25px", paddingLeft: "25px" }}
                        >
                          Know more
                        </Link>
                      </span>
                      <span className="bd-about-text-btn">
                        <a
                          href={pdf1}
                          target="_blank"
                          download
                          className="theme-btn mr-15 three-button-respon"
                          style={{
                            paddingRight: "25px",
                            paddingLeft: "25px",
                            backgroundColor: "rgb(195, 41, 72)",
                          }}
                        >
                          <i style={{ lineHeight: "4", fontStyle: "normal" }}>
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{ fontSize: "20px", paddingRight: "10px" }}
                            />
                            Company Profile
                          </i>
                        </a>
                      </span>
                      <span className="bd-about-text-btn">
                        <button
                          className="theme-btn mr-15 "
                          data-bs-toggle="modal"
                          data-bs-target="#portfolioModal"
                          style={{
                            paddingRight: "25px",
                            paddingLeft: "25px",
                            backgroundColor: "rgb(195, 41, 72)",
                          }}
                        >
                          <i style={{ lineHeight: "4", fontStyle: "normal" }}>
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{ fontSize: "20px", paddingRight: "10px" }}
                            />
                            Our Portfolio
                          </i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* modal popup */}
          <div
            className="modal fade"
            id="portfolioModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content" style={{ width: "120%" }}>
                <div className="modal-header" style={{ border: "none" }}>
                  <h5 className="modal-title" id="exampleModalLabel1">
                    Fill out the Details!
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ marginLeft: "50px" }}
                  ></button>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingRight: "25px" }}>
                    {isLoading && <Spinner />}
                    <form onSubmit={onHandleSubmit}>
                      <div className="row" style={{ paddingTop: "23px" }}>
                        <div className="bd-contact-field mb-20">
                          <input
                            className="blog-form"
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            name="user_name"
                            required
                          />
                        </div>
                        <div className="bd-contact-field mb-20">
                          <input
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            name="user_email"
                            required
                          />
                        </div>
                        <div className="bd-contact-field mb-20 ">
                          <input
                            type="text"
                            placeholder="Contact No"
                            maxLength={10}
                            onKeyPress={(event) => {
                              if (!/[0-9.]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => setMobile(e.target.value)}
                            name="user_contact"
                            required
                          />
                        </div>
                        <div className="col-12 mb-20">
                          <div className="bd-contact-field">
                            <button type="submit" className="theme-btn">
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="hello">
                    <img
                      src="https://img.zithas.co.uk/portfolio-side_y0lpqc.avif"
                      alt="img not found"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
