import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/Blog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCalendar,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import "../assets/css/custom-animation.css";
import "../assets/css/animate.min.css";
import { animateScroll as scroll } from "react-scroll";
import axios from "axios";
import moment from "moment";

const Blog = () => {
  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://mars.zithas.com/api/zithasukblog")
      .then((res) => {
        setPosts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <section className="bd-blog-area pt-80 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-section-title-wrapper text-center mb-50">
                <p className="bd-section-subtitle mb-15">Creative Blog</p>
                <h2 className="bd-section-title mb-25">News insight</h2>
                <p>
                  Our Blog is full of examples of technologies we're working on
                  and their benefits.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {posts.slice(0, 3).map((blogContent) => {
              const publishDate = moment(blogContent.publish_date).format(
                "Do MMM YYYY"
              );
              return (
                <div className="col-lg-4 col-md-6">
                  <div className="bd-blog mb-30">
                    <div className="bd-blog-img">
                      <Link
                        to={`/blog/${blogContent.permalink}`}
                        onClick={toggleContact}
                      >
                        <img
                          src={blogContent.main_image}
                          alt="blog image not found"
                        />
                      </Link>
                    </div>
                    <div className="bd-blog-text">
                      <div className="bd-blog-meta mb-15">
                        <ul>
                          <li>
                            <Link
                              to={`/blog/${blogContent.permalink}`}
                              style={{ textTransform: "none" }}
                              onClick={toggleContact}
                            >
                              <i>
                                <FontAwesomeIcon icon={faCalendar} />
                              </i>
                              {publishDate}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/blog/${blogContent.permalink}`}
                              onClick={toggleContact}
                            >
                              <i>
                                <FontAwesomeIcon icon={faMessage} />
                              </i>
                              0 Comments
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h4 className="bd-blog-title mb-40">
                        <Link
                          to={`/blog/${blogContent.permalink}`}
                          onClick={toggleContact}
                        >
                          {blogContent.title}
                        </Link>
                      </h4>
                      <div className="bd-blog-author">
                        <div className="bd-blog-author-info">
                          <img
                            src="https://img.zithas.co.uk/eva-blog_nj5r1k.webp"
                            alt="author image not found"
                          />
                          <p className="bd-blog-author-info-title">
                            Eva Fernandez
                          </p>
                        </div>
                        <div className="bd-blog-author-link">
                          <Link
                            to={`/blog/${blogContent.permalink}`}
                            onClick={toggleContact}
                          >
                            Read More <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
