import React, { useState, useEffect } from "react";
import "../../Styles/Redirecting Styles/WebDevelopment.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Spinner from "../../Components/seperate Components/Spinner";

const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [industry, setIndustry] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [service, setService] = useState("");
  const [spinner, setSpinner] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(
    localStorage.getItem("formSubmitted") ? true : false
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(true);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isClick, setClick] = useState(false);
  const closePopup = () => {
    setClick(false);
  };

  // const submitForm = (e) => {
  //   e.preventDefault();

  //   const data = { name, email, phone, message };
  //   localStorage.setItem("formSubmitted", JSON.stringify(data));
  //   setIsSubmitted(true);
  //   setTimeout(() => {
  //     setClick(false);
  //   }, 5000);
  // };

  const onHandleSubmit = async (e) => {
    let Location = 2;

    e.preventDefault();
    setLoading(true);
    setSpinner(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("contact", contact);
    formData.append("message", message);
    formData.append("industry", industry);
    formData.append("service", service);
    formData.append("location", Location);

    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/add_client",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        setLoading(false);
        setSpinner(false);
        setIsSubmitted(true);
        setTimeout(() => {
          setClick(false);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button
        className={`scroll-to-top-button ${isVisible ? "visible" : ""}`}
        onClick={() => {
          setClick(!isClick);
        }}
      >
        Need Help ?
      </button>
      {isClick == true ? (
        <div class="message-form shadow" style={{ zIndex: "99999" }}>
          {isSubmitted ? (
            <div className="text-center p-2">
              <i className="fas fa-check fs-2 bg-success p-3 text-white rounded-circle shadow "></i>
              <h4 className="text-center mt-4">
                Your message has been sent Successfully
              </h4>
            </div>
          ) : (
            <div>
              <div className="modal-header" style={{ border: "none" }}>
                <h5 className="modal-title" id="exampleModalLabel">
                  Fill out the Details!
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  style={{ marginLeft: "50px" }}
                  onClick={closePopup}
                ></button>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }} className="pe-4 ps-3">
                  {spinner && <Spinner />}
                  <form onSubmit={onHandleSubmit}>
                    <div className="row" style={{ paddingTop: "23px" }}>
                      <div className="bd-contact-field mb-20">
                        <input
                          className="blog-form form-tags"
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="bd-contact-field mb-20">
                        <input
                          type="email"
                          className="form-tags"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="bd-contact-field mb-20 ">
                        <input
                          type="text"
                          className="form-tags"
                          placeholder="Contact No"
                          name="contact"
                          value={contact}
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key))
                              event.preventDefault();
                          }}
                          onChange={(e) => setContact(e.target.value)}
                          required
                        />
                      </div>
                      <div className="bd-contact-field mb-20 ">
                        <select
                          className="form-select form-select-sm"
                          aria-label=".form-select-sm example"
                          name="industry"
                          value={industry}
                          onChange={(e) => setIndustry(e.target.value)}
                          style={{
                            width: "100%",
                            height: "40px",
                            border: "none",
                            borderRadius: "10px",
                            color: "var(--clr-body-text)",
                            fontWeight: "400",
                            fontSize: "16px",
                            paddingLeft: "20px",
                          }}
                          required
                        >
                          <option
                            style={{
                              color: "var(--clr-body-text)",
                              fontSize: "16px",
                            }}
                          >
                            Select the Industries
                          </option>
                          <option value="Commodity Chemicals">
                            Commodity Chemicals
                          </option>
                          <option value="Specialty Chemicals">
                            Specialty Chemicals
                          </option>
                          <option value="Specialty Chemicals">
                            Specialty Chemicals
                          </option>
                          <option value="Dyes And Pigments">
                            Dyes And Pigments
                          </option>
                          <option value="Explosives">Explosives</option>
                          <option value="Petrochemicals">Petrochemicals</option>
                          <option value="Printing Inks">Printing Inks</option>
                          <option value="Trading - Chemicals">
                            Trading - Chemicals
                          </option>
                          <option value="Industrial Gases">
                            Industrial Gases
                          </option>
                          <option value="Fertilizers">Fertilizers</option>
                          <option value="Pesticides & Agrochemicals">
                            Pesticides & Agrochemicals
                          </option>
                          <option value="Cement & Cement Products">
                            Cement & Cement Products
                          </option>
                          <option value="Other Construction Materials">
                            Other Construction Materials
                          </option>
                          <option value="Ferro & Silica Manganese">
                            Ferro & Silica Manganese
                          </option>
                          <option value="Pig Iron">Pig Iron</option>
                          <option value="Sponge Iron">Sponge Iron</option>
                          <option value="Iron & Steel">Iron & Steel</option>
                          <option value="Aluminium">Aluminium</option>
                          <option value="Copper">Copper</option>
                          <option value="Zinc">Zinc</option>
                          <option value="Precious Metals">
                            Precious Metals
                          </option>
                          <option value="Diversified Metals">
                            Diversified Metals
                          </option>
                          <option value="Industrial Minerals">
                            Industrial Minerals
                          </option>
                          <option value="Trading - Metals">
                            Trading - Metals
                          </option>
                          <option value="Trading - Minerals">
                            Trading - Minerals
                          </option>
                          <option value="Paper & Paper Products">
                            Paper & Paper Products
                          </option>
                          <option value="Forest Products">
                            Forest Products
                          </option>
                          <option value="Jute & Jute Products">
                            Jute & Jute Products
                          </option>
                          <option value="Passenger Cars & Utility Vehicles">
                            Passenger Cars & Utility Vehicles
                          </option>
                          <option value="2/3 Wheelers">2/3 Wheelers</option>
                          <option value="Auto Dealer">Auto Dealer</option>
                          <option value="Auto Components & Equipments">
                            Auto Components & Equipments
                          </option>
                          <option value="Tyres & Rubber Products">
                            Tyres & Rubber Products
                          </option>
                          <option value="Trading - Auto Components">
                            Trading - Auto Components
                          </option>
                          <option value="Cycles">Cycles</option>
                          <option value="Consumer Electronics">
                            Consumer Electronics
                          </option>
                          <option value="Furniture, Home Furnishing">
                            Furniture, Home Furnishing
                          </option>
                          <option value="Ceramics">Ceramics</option>
                          <option value="Granites & Marbles">
                            Granites & Marbles
                          </option>
                          <option value="Gems, Jewellery And Watches">
                            Gems, Jewellery And Watches
                          </option>
                          <option value="Glass - Consumer">
                            Glass - Consumer
                          </option>
                          <option value="Household Appliances">
                            Household Appliances
                          </option>
                          <option value="Houseware">Houseware</option>
                          <option value="Leather And Leather Products">
                            Leather And Leather Products
                          </option>
                          <option value="Leisure Products">
                            Leisure Products
                          </option>
                          <option value="Plastic Products - Consumer">
                            Plastic Products - Consumer
                          </option>
                          <option value="Plywood Boards/ Laminates">
                            Plywood Boards/ Laminates
                          </option>
                          <option value="Sanitary Ware">Sanitary Ware</option>
                          <option value="Paints">Paints</option>
                          <option value="Diversified consumer products">
                            Diversified consumer products
                          </option>
                          <option value="Footwear">Footwear</option>
                          <option value="Garments & Apparels">
                            Garments & Apparels
                          </option>
                          <option value="Other Textile Products">
                            Other Textile Products
                          </option>
                          <option value="Trading - Textile Products">
                            Trading - Textile Products
                          </option>
                          <option value="Advertising & Media Agencies">
                            Advertising & Media Agencies
                          </option>
                          <option value="Electronic Media">
                            Electronic Media
                          </option>
                          <option value="Web based media and service">
                            Web based media and service
                          </option>
                          <option value="Print Media">Print Media</option>
                          <option value="Film Production, Distribution & Exhibition">
                            Film Production, Distribution & Exhibition
                          </option>
                          <option value="Digital Entertainment">
                            Digital Entertainment
                          </option>
                          <option value="Media & Entertainment">
                            Media & Entertainment
                          </option>
                          <option value="TV Broadcasting & Software Production">
                            TV Broadcasting & Software Production
                          </option>
                          <option value="Printing & Publication">
                            Printing & Publication
                          </option>
                          <option value="Residential, Commercial Projects">
                            Residential, Commercial Projects
                          </option>
                          <option value="Real Estate related services">
                            Real Estate related services
                          </option>
                          <option value="Real Estate Investment Trusts (REITs)">
                            Real Estate Investment Trusts (REITs)
                          </option>
                          <option value="Hotels & Resorts">
                            Hotels & Resorts
                          </option>
                          <option value="Restaurants">Restaurants</option>
                          <option value="Amusement Parks/ Other Recreation">
                            Amusement Parks/ Other Recreation
                          </option>
                          <option value="Wellness">Wellness</option>
                          <option value="Tour, Travel Related Services">
                            Tour, Travel Related Services
                          </option>
                          <option value="Education">Education</option>
                          <option value="E-Learning">E-Learning</option>
                          <option value="Food Storage Facilities">
                            Food Storage Facilities
                          </option>
                          <option value="Other Consumer Services">
                            Other Consumer Services
                          </option>
                          <option value="Speciality Retail">
                            Speciality Retail
                          </option>
                          <option value="Pharmacy Retail">
                            Pharmacy Retail
                          </option>
                          <option value="Diversified Retail">
                            Diversified Retail
                          </option>
                          <option value="E-Retail/ E- Commerce">
                            E-Retail/ E- Commerce
                          </option>
                          <option value="Internet & Catalogue Retail">
                            Internet & Catalogue Retail
                          </option>
                          <option value="Distributors">Distributors</option>
                          <option value="Gas Transmission/Marketing">
                            Gas Transmission/Marketing
                          </option>
                          <option value="LPG/CNG/PNG/LN G Supplier">
                            LPG/CNG/PNG/LN G Supplier
                          </option>
                          <option value="Trading - Gas">Trading - Gas</option>
                          <option value="Oil Exploration & Production">
                            Oil Exploration & Production
                          </option>
                          <option value="Offshore Support Solution Drilling">
                            Offshore Support Solution Drilling
                          </option>
                          <option value="Oil Storage & Transportation">
                            Oil Storage & Transportation
                          </option>
                          <option value="Oil Equipment & Services">
                            Oil Equipment & Services
                          </option>
                          <option value="Refineries & Marketing">
                            Refineries & Marketing
                          </option>
                          <option value="Lubricants">Lubricants</option>
                          <option value="Coal">Coal</option>
                          <option value="Trading - Coal">Trading - Coal</option>
                          <option value="Edible Oil">Edible Oil</option>
                          <option value="Sugar">Sugar</option>
                          <option value="Tea & Coffee">Tea & Coffee</option>
                          <option value="Other Agricultural Products">
                            Other Agricultural Products
                          </option>
                          <option value="Breweries & Distilleries">
                            Breweries & Distilleries
                          </option>
                          <option value="Other Beverages">
                            Other Beverages
                          </option>
                          <option value="Cigarettes & Tobacco Products">
                            Cigarettes & Tobacco Products
                          </option>
                          <option value="Animal Feed">Animal Feed</option>
                          <option value="Dairy Products">Dairy Products</option>
                          <option value="Other Food Products">
                            Other Food Products
                          </option>
                          <option value="Packaged Foods">Packaged Foods</option>
                          <option value="Seafood">Seafood</option>
                          <option value="Meat Products including Poultry">
                            Meat Products including Poultry
                          </option>
                          <option value="Personal Care">Personal Care</option>
                          <option value="Household Products">
                            Household Products
                          </option>
                          <option value="Stationary">Stationary</option>
                          <option value="Diversified FMCG">
                            Diversified FMCG
                          </option>
                          <option value="Financial Institution">
                            Financial Institution
                          </option>
                          <option value="Housing Finance Company">
                            Housing Finance Company
                          </option>
                          <option value="Investment Company">
                            Investment Company
                          </option>
                          <option value="Non Banking Financial Company (NBFC)">
                            Non Banking Financial Company (NBFC)
                          </option>
                          <option value="Other Financial Services">
                            Other Financial Services
                          </option>
                          <option value="Holding Company">
                            Holding Company
                          </option>
                          <option value="Microfinance Institutions">
                            Microfinance Institutions
                          </option>
                          <option value="Securitisation">Securitisation</option>
                          <option value="Public Sector Bank">
                            Public Sector Bank
                          </option>
                          <option value="Private Sector Bank">
                            Private Sector Bank
                          </option>
                          <option value="Other Bank">Other Bank</option>
                          <option value="Asset Management Company">
                            Asset Management Company
                          </option>
                          <option value="Depositories, Clearing Houses and Other Intermediaries">
                            Depositories, Clearing Houses and Other
                            Intermediaries
                          </option>
                          <option value="Financial Products Distributor">
                            Financial Products Distributor
                          </option>
                          <option value="Ratings">Ratings</option>
                          <option value="Exchange and Data Platform">
                            Exchange and Data Platform
                          </option>
                          <option value="Stockbroking & Allied">
                            Stockbroking & Allied
                          </option>
                          <option value="Other Capital Market related Services">
                            Other Capital Market related Services
                          </option>
                          <option value="General Insurance">
                            General Insurance
                          </option>
                          <option value="Life Insurance">Life Insurance</option>
                          <option value="Other Insurance Companies">
                            Other Insurance Companies
                          </option>
                          <option value="Insurance Distributors">
                            Insurance Distributors
                          </option>
                          <option value="Financial Technology (Fintech)">
                            Financial Technology (Fintech)
                          </option>
                          <option value="Pharmaceuticals">
                            Pharmaceuticals
                          </option>
                          <option value="Biotechnology">Biotechnology</option>
                          <option value="Medical Equipment & Supplies">
                            Medical Equipment & Supplies
                          </option>
                          <option value="Hospital">Hospital</option>
                          <option value="Healthcare Service Provider">
                            Healthcare Service Provider
                          </option>
                          <option value="Healthcare Research, Analytics & Technology">
                            Healthcare Research, Analytics & Technology
                          </option>
                          <option value="Civil Construction">
                            Civil Construction
                          </option>
                          <option value="Aerospace & Defense">
                            Aerospace & Defense
                          </option>
                          <option value="Tractors">Tractors</option>
                          <option value="Commercial Vehicles">
                            Commercial Vehicles
                          </option>
                          <option value="Construction Vehicles">
                            Construction Vehicles
                          </option>
                          <option value="Dealers– Commercial Vehicles, Tractors,Construction Vehicles">
                            Dealers– Commercial Vehicles, Tractors,Construction
                            Vehicles
                          </option>
                          <option value="Heavy Electrical Equipment">
                            Heavy Electrical Equipment
                          </option>
                          <option value="Other Electrical Equipment">
                            Other Electrical Equipment
                          </option>
                          <option value="Railway Wagons">Railway Wagons</option>
                          <option value="Ship Building & Allied Services">
                            Ship Building & Allied Services
                          </option>
                          <option value="Industrial Products">
                            Industrial Products
                          </option>
                          <option value="Cables - Electricals">
                            Cables - Electricals
                          </option>
                          <option value="Castings & Forgings">
                            Castings & Forgings
                          </option>
                          <option value="Packaging">Packaging</option>
                          <option value="Plastic Products - Industrial">
                            Plastic Products - Industrial
                          </option>
                          <option value="Rubber">Rubber</option>
                          <option value="Other Industrial Products">
                            Other Industrial Products
                          </option>
                          <option value="Glass - Industrial">
                            Glass - Industrial
                          </option>
                          <option value="Aluminium, Copper & Zinc Products">
                            Aluminium, Copper & Zinc Products
                          </option>
                          <option value="Iron & Steel Products">
                            Iron & Steel Products
                          </option>
                          <option value="Abrasives & Bearings">
                            Abrasives & Bearings
                          </option>
                          <option value="Compressors, Pumps & Diesel Engines">
                            Compressors, Pumps & Diesel Engines
                          </option>
                          <option value="Electrodes & Refractories">
                            Electrodes & Refractories
                          </option>
                          <option value="Computers - Software & Consulting">
                            Computers - Software & Consulting
                          </option>
                          <option value="Software Products">
                            Software Products
                          </option>
                          <option value="IT Enabled Services">
                            IT Enabled Services
                          </option>
                          <option value="Computers Hardware & Equipments">
                            Computers Hardware & Equipments
                          </option>
                          <option value="Dredging">Dredging</option>
                          <option value="Airline">Airline</option>
                          <option value="Logistics Solution Provider">
                            Logistics Solution Provider
                          </option>
                          <option value="Railways">Railways</option>
                          <option value="Road Transport">Road Transport</option>
                          <option value="Shipping">Shipping</option>
                          <option value="Transport Related Services">
                            Transport Related Services
                          </option>
                          <option value="Airport & Airport services">
                            Airport & Airport services
                          </option>
                          <option value="Port & Port services">
                            Port & Port services
                          </option>
                          <option value="Road Assets–Toll, Annuity, Hybrid- Annuity">
                            Road Assets–Toll, Annuity, Hybrid- Annuity
                          </option>
                          <option value="Trading & Distributors">
                            Trading & Distributors
                          </option>
                          <option value="Consulting Services">
                            Consulting Services
                          </option>
                          <option value="Data Processing Services">
                            Data Processing Services
                          </option>
                          <option value="Diversified Commercial Services">
                            Diversified Commercial Services
                          </option>
                          <option value="Business Process Outsourcing (BPO)/ Knowledge Process Outsourcing (KPO)">
                            Business Process Outsourcing (BPO)/ Knowledge
                            Process Outsourcing (KPO)
                          </option>
                          <option value="Urban Local Bodies">
                            Urban Local Bodies
                          </option>
                          <option value="Development Authority">
                            Development Authority
                          </option>
                          <option value="Telecom - Cellular & Fixed line">
                            Telecom - Cellular & Fixed line
                          </option>
                          <option value="services">services</option>
                          <option value="Telecom - Infrastructure">
                            Telecom - Infrastructure
                          </option>
                          <option value="Other Telecom Services">
                            Other Telecom Services
                          </option>
                          <option value="Telecom - Equipment & Accessories">
                            Telecom - Equipment & Accessories
                          </option>
                          <option value="Power Generation">
                            Power Generation
                          </option>
                          <option value="Integrated Power Utilities">
                            Integrated Power Utilities
                          </option>
                          <option value="Power Trading">Power Trading</option>
                          <option value="Power - Transmission">
                            Power - Transmission
                          </option>
                          <option value="Power Distribution">
                            Power Distribution
                          </option>
                          <option value="Water Supply & Management">
                            Water Supply & Management
                          </option>
                          <option value="Waste Management">
                            Waste Management
                          </option>
                          <option value="Emergency Services">
                            Emergency Services
                          </option>
                          <option value="Multi Utilities">
                            Multi Utilities
                          </option>
                          <option value="Other Utilities">
                            Other Utilities
                          </option>
                          <option value="Diversified">Diversified</option>
                        </select>
                      </div>
                      <div className="bd-contact-field mb-20 ">
                        <select
                          className="form-select form-select-sm"
                          aria-label=".form-select-sm example"
                          name="service"
                          value={service}
                          onChange={(e) => setService(e.target.value)}
                          style={{
                            width: "100%",
                            height: "40px",
                            border: "none",
                            borderRadius: "10px",
                            color: "var(--clr-body-text)",
                            fontWeight: "400",
                            fontSize: "16px",
                            paddingLeft: "20px",
                          }}
                          required
                        >
                          <option
                            style={{
                              color: "var(--clr-body-text)",
                              fontSize: "16px",
                            }}
                          >
                            Select the Services
                          </option>
                          <option value="Business Audit">
                            {" "}
                            Business Audit{" "}
                          </option>
                          <option value="Bespoke System">Bespoke System</option>
                          <option value="Business Automation">
                            Business Automation
                          </option>
                          <option value="Software Development">
                            Software Development
                          </option>
                          <option value="Mobile Apps Development">
                            Mobile Apps Development
                          </option>
                          <option value="Dedicated Resource">
                            Dedicated Resource
                          </option>
                          <option value="Digital Marketing">
                            Digital Marketing
                          </option>
                          <option value="Hosting Services">
                            Hosting Services
                          </option>
                          <option value="MICRO Services">MICRO Services</option>
                          <option value="Maintenance Packages">
                            Maintenance Packages
                          </option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="bd-contact-field mb-20 ">
                        <textarea
                          style={{ height: "90px" }}
                          // className="form-tags"
                          placeholder="Message"
                          rows="2"
                          name="message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12 mb-20 text-center">
                        <div className="bd-contact-field">
                          <button
                            type="submit"
                            className="theme-btn submit-chat"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default ScrollTop;
