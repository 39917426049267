import React, { useEffect, useState } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../../Components/Footer";
import "../../Styles/Redirecting Styles/NavbarStyles/Bespoke.css";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { animateScroll as scroll } from "react-scroll";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const BespokeSystem = () => {
  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Helmet>
        <title>Bespoke Software Development Company in England,UK</title>
        <meta
          name="description"
          content="Discover bespoke software solutions for businesses in England,UK. Tailored system developer in Bristol, London, Wales, Cardiff, and within the UK."
        />
        <meta
          name="keywords"
          content="
        top custom software development companies,
        custom software solutions bristol,
        best custom software solutions in uk,
        best custom software solutions,
        custom software solutions england, 
        bespoke system england, 
        bespoke software development,
        bespoke software development uk,
        bespoke software company uk,
        bespoke system for business,
        bespoke system bristol,
        bespoke system london,
        bespoke system wales,
        bespoke system cardiff,
        Custom Made Software
        "
        />
      </Helmet>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg1 pt-220 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="bd-page-title text-center z-index"
                style={{ bottom: "45px" }}
              >
                <h1
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Bespoke System
                </h1>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      Say Goodbye to Generic Tech, Hello to a Bespoke System
                      Built Just for You.
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-about-area">
        <div className="bd-custom-container">
          <div className="bd-about-four-bg pt-80 pb-80 fix">
            <div className="bd-about-shape-box">
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8 first_resp_bespoke">
                    <div className="bd-choose-img text-end mb-30 top-resp z-index">
                      <div className="row align-items-center custom-mar-15">
                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                          <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                            <img
                              src="https://img.zithas.co.uk/bespoke-section-1.1_j08lfy.avif"
                              className="resp-img bordertop-img1"
                              alt="img not found"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                          <div className="bd-choose-img-inner mb-15">
                            <img
                              src="https://img.zithas.co.uk/bespoke-section-1_xkcd9x.avif"
                              className="resp-img bordertop-img2"
                              alt="img not found"
                            />
                          </div>
                          <div className="bd-choose-img-inner mb-15">
                            <img
                              src="https://img.zithas.co.uk/bespoke-section-1.2_dabfk6.avif"
                              className="resp-img bordertop-img3"
                              alt="img not found"
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        className="bd-choose-shape bd-choose-shape-2"
                        src={Shape2}
                        alt="shape img not found"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 second_resp_bespoke">
                    <div className="bd-about-text bd-about-text-four mb-40 z-index">
                      <div className="bd-section-title-wrapper">
                        <h2 className="bd-section-title mb-15 md-pr-50">
                          <span style={{ color: "#ffbf1e" }}>
                            Bespoke system{" "}
                          </span>{" "}
                          for Business Success
                        </h2>
                        <p className="mb-15">
                          Bespoke systems for businesses are key to success.
                          Among the top{" "}
                          <Link
                            to="/software-development"
                            style={{ fontWeight: "500" }}
                          >
                            software development
                          </Link>{" "}
                          companies in Bristol, England, we provide easy custom
                          software development tailored to each individual
                          business's needs. With a bespoke system, businesses
                          can gain a competitive advantage, which can lead to
                          increased efficiency, accuracy, and customer
                          satisfaction.
                        </p>

                        <p>
                          {" "}
                          In addition, we provide one system that offers
                          multiple benefits such as scalability, data security,
                          and integration with existing systems and tools. With
                          our bespoke systems, businesses can gain a competitive
                          advantage, enhance the customer experience, and
                          ultimately drive growth and success. In short, a
                          bespoke system can be the perfect solution for
                          businesses looking to achieve success and growth in
                          today's digital age.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-six-area ">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <h2 className="bd-section-title mb-25 bespoke_section2-tittle">
            Fit-For-Purpose Software Solutions for Business
          </h2>
          <div className="row bespoke_responsive">
            <div className="col-lg-6 first_section_responsive ">
              <div className="bd-about-six-text">
                <div className="bd-section-title-wrapper mb-25">
                  <p>
                    At our bespoke software company, we specialize in creating
                    custom software solutions that are tailored to fit the
                    specific needs of businesses, especially in Bristol, Wales,
                    Cardiff, London, etc. We understand that every business is
                    unique, and that's why we take a personalized approach to
                    software development. Our team of experts crafts each
                    solution individually, making sure that it meets the
                    specific needs of your business. By designing a solution
                    that is crafted for your business, it can also growth and
                    scalability in mind, which means that as your business
                    grows, your software will be able to grow with it. With our
                    custom software, you can expect a strong return on
                    investment, as it can help your business to become more
                    efficient, accurate, and customer-friendly. Our software is
                    designed to save time and be easy to use, giving you a
                    competitive advantage in the marketplace.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 second_section_responsive">
              <div className="bd-about-six-img">
                <div className="bd-about-six-feature">
                  <ul className="mb-45">
                    <li
                      style={{
                        fontSize: "18px",
                        color: "var(--clr-body-text)",
                      }}
                    >
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Individually Crafted Solution
                    </li>
                    <li
                      style={{
                        fontSize: "18px",
                        color: "var(--clr-body-text)",
                      }}
                    >
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Growth & scalability
                    </li>
                    <li
                      style={{
                        fontSize: "18px",
                        color: "var(--clr-body-text)",
                      }}
                    >
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Return On Investment
                    </li>
                    <li
                      style={{
                        fontSize: "18px",
                        color: "var(--clr-body-text)",
                      }}
                    >
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Time-saving
                    </li>
                    <li
                      style={{
                        fontSize: "18px",
                        color: "var(--clr-body-text)",
                      }}
                    >
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Competitive advantage
                    </li>
                    <li
                      style={{
                        fontSize: "18px",
                        color: "var(--clr-body-text)",
                      }}
                    >
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      No integration issues
                    </li>
                    <li
                      style={{
                        fontSize: "18px",
                        color: "var(--clr-body-text)",
                      }}
                    >
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Easier maintenance
                    </li>
                    <li
                      style={{
                        fontSize: "18px",
                        color: "var(--clr-body-text)",
                      }}
                    >
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      More independent
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-choose-area fix">
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="bd-choose-shape pt-70">
            <div className="row four_resp_section">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 four_point_section1">
                <div className="bd-choose-img z-index pt-70">
                  <div className="bd-choose-info-wrapper">
                    <div className="container">
                      <div className="row mb-50">
                        <div className="col cell_resp mr-30">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/vertical-scalability-64_hohe9v.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Scalability and flexibility
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/ownership-64_slxqha.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Complete ownership
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col cell_resp mr-30">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/integration-64_jmglzx.avif"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Fast integration
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col respon_space">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/trophy-64_ohhupt.avif"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Competitive advantage
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 four_point_section2">
                <div className="bd-choose-text z-index">
                  <h2 className="bd-section-title section_title2">
                    Expertly Crafted Software for Optimal Performance
                  </h2>
                  <div className="bd-section-title-wrapper">
                    <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                      Our business offers specialised software that is created
                      to meet your unique needs and specifications. As we are
                      aware that every business is different, we develop
                      solutions that are flexible and scalable, allowing them to
                      expand and change as your company does.
                    </p>
                    <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                      You will have full control of our software, which was
                      created with the workflow and objectives of your company
                      in mind. You may rapidly get up and running after
                      integrating our software solutions into your current
                      systems. You can optimise operations, automate jobs, and
                      offer a better customer experience with our software
                      solutions, giving you a competitive edge over your rivals.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-area">
        <div className="bd-custom-container">
          <div className="bd-about-four-bg  pt-80 pb-70 fix">
            <div className="bd-about-shape-box">
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8 fifth_section_respon">
                    <div className="bd-about-text bd-about-text-four mb-40 z-index">
                      <div className="bd-section-title-wrapper">
                        <h2 className="bd-section-title mb-15 bespoke-lastSection_tittle md-pr-50">
                          Unlock Your Business Potential with our{" "}
                          <span style={{ color: "#ffbf1e" }}>
                            Custom-Made Software
                          </span>
                        </h2>
                        <p className="">
                          With a bespoke software development, you can have
                          software that is unique to your business, which will
                          help you to differentiate yourself from your
                          competitors. Our solutions are designed to streamline
                          processes, automate tasks and improve the customer
                          experience, helping to increase efficiency and
                          accuracy, and ultimately driving more business. With
                          our solutions, your business will have a competitive
                          edge, giving you the opportunity to rise above the
                          competition. Additionally, Our solutions are designed
                          to be scalable, so they can grow with your business,
                          and also easy to maintain, giving you more control
                          over your software. Contact us today to learn more
                          about how our bespoke solutions can help you achieve
                          success.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 pl-30 fifth_section_respon2">
                    <div className="bd-about-img mb-30">
                      <img
                        src="https://img.zithas.co.uk/bespoke-footer_vtfncf.avif"
                        style={{ height: "405px" }}
                        className="xss-w-full rounded-10"
                        alt="about img not found"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for Bespoke System expert ?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great system, Let's get started right now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BespokeSystem;
