import React, { useState } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPhone } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Redirecting Styles/FooterStyle/pricing.css";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { ServiceBanner } from "../../Components/seperate Components/ServiceBanner";
import axios from "axios";
import Spinner from "../../Components/seperate Components/Spinner";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import "../../Styles/Redirecting Styles/FooterStyle/Career.css";

const PricingTable = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [website, setWebsite] = useState("");
  const [type, setTypes] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let res = await fetch("https://mars.zithas.com/api/maintenance", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          mobile: mobile,
          website: website,
          type: type,
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMobile("");
        setWebsite("");
        setLoading(false);
        swal({
          icon: "success",
          text: "Data send Sucessfully",
        });
      } else {
        alert("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Helmet>
        <title>Get The Best Website Maintenance Packages in England,UK</title>
        <meta
          name="description"
          content="Your trusted website maintenance company in the UK. Explore our web maintenance packages in Bristol and London. Keep your site at its best with us."
        />

        <meta
          name="keywords"
          content="
                web maintenance packages uk,
                web maintenance packages bristol,
                website maintenance services,
                web maintenance packages england, 
                website maintenance company,
                website maintenance london,
                website maintenance bristol,
                website maintenance packages
                "
        />
      </Helmet>
      <Header1 />
      <>
        <div
          className="bd-page-title-area bd-page-title-bg_pricing main-tittle-pricing pt-175 pb-155"
          style={{ height: "380px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bd-page-title text-center z-index">
                  <h2 className="breadcrumb-title1 packages-tittle">
                    Maintenance Packages
                  </h2>
                  <div className="breadcrumb-menu">
                    <nav className="breadcrumb-trail breadcrumbs">
                      <p style={{ color: "black" }}>
                        Slow down, the world is waiting. Get your website
                        running like new in no time!
                      </p>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="bd-brand-area-two pb-80 pt-70">
          <div className="container" style={{ maxWidth: "1270px" }}>
            <div className="row fourt_respon_business">
              <div className="col-xl-4 col-lg-6 col-md-6 first-Pricing_respons">
                <div className="col-12">
                  <div className="bd-section-title-wrapper mb-20">
                    <h2 className="bd-section-title mb-25 pl-0 website-main-tittle">
                      <h1 className="website-main-tittle">
                        <span style={{ color: "#ffbf1e" }}>
                          Website Maintenance
                        </span>{" "}
                        Services{" "}
                      </h1>{" "}
                      in Bristol, England at best prices.
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-6 col-md-6 second_Pricing_respons">
                <div className="bd-brand-active-two swiper-container">
                  <div className="swiper-wrapper">
                    <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                      Web Maintenance packages provide a holistic and{" "}
                      <Link
                        to="/software-development"
                        style={{ fontWeight: "500" }}
                      >
                        economical website
                      </Link>{" "}
                      maintenance service that will keep your website up to date
                      with the latest security patches, improve your page speed,
                      and more. It's the perfect technique to maintain a
                      user-friendly, customer-engaging website. Our website
                      maintenance service is tailored specifically for
                      businesses of all sizes, so you can rest assured that your
                      website will stay up to date and running like new.We take
                      the care of your website’s security and performance to
                      ensure that it runs smoothly and without any issues. Our
                      team is always keep up to date with the latest patches and
                      software, so you can rest assured that your website is as
                      safe as possible. Being the best website maintenance
                      company, we also guarantee that your website will always
                      be up-to-date with the latest security threats – all for a
                      price that is affordable for you
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bd-service-area pt-80 pb-80">
          <div className="container" style={{ maxWidth: "1270px" }}>
            <div className="row">
              <div className="col-12">
                <div className="bd-section-title-wrapper mb-50 z-index text-center">
                  <h5 className="bd-section-subtitle mb-15">Price Table</h5>
                  <h2 className="bd-section-title mb-25">
                    Pricing & Packaging
                  </h2>
                  <p>
                    Keep your website running smoothly: Explore our
                    comprehensive website maintenance packages and{" "}
                    <Link to="/hire-a-resource" style={{ fontWeight: "500" }}>
                      dedicated support
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="pricing-table">
                  <div className="col-lg-4 col-md-6 package-price">
                    <div className="bd-pricing-title-wrapper text-center ">
                      <h4
                        className="bd-pricing-subtitle mb-15 pt-55"
                        style={{ fontWeight: "600" }}
                      >
                        Basic Package
                      </h4>
                      <h6
                        className="bd-pricing-price"
                        style={{ fontSize: "44px" }}
                      >
                        £74<span>.99/Month</span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 package-content">
                    <ul
                      className="pl-60 main-pricing-content centering-detail"
                      style={{ color: "black", fontSize: "20px" }}
                    >
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />{" "}
                        Website Content Updates
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Website Security
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Website Monitoring
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        System upgrades
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Website Speed Optimization
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Check all inquiry forms are working
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Support via Email / Skype / Phone
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6 buy-button">
                    <div
                      className="bd-pricing-btn pricing-button mt-80"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <a className="theme-btn">Know More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="pricing-table">
                  <div className="col-lg-4 col-md-6 package-price">
                    <div className="bd-pricing-title-wrapper text-center ">
                      <h4
                        className="bd-pricing-subtitle mb-15 pt-95"
                        style={{ fontWeight: "600" }}
                      >
                        WordPress Package
                      </h4>
                      <h6
                        className="bd-pricing-price"
                        style={{ fontSize: "44px" }}
                      >
                        £99<span>.99/Month</span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 package-content">
                    <ul
                      className="pl-60 centering-detail"
                      style={{ color: "black", fontSize: "20px" }}
                    >
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Weekly Security Scan
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Weekly full site backup and storage at 3rd party
                        location
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Urgent support/restore if website is hacked
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Optimize WordPress Database
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Find and Fix 404 errors and broken links
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Review Security Logs
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Update WordPress Version, Theme and Plugins
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Check device and browser compatibility
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Review website passwords
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Review site SEO and Google Analytics
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i> </i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Monthly and Quarterly WordPress Maintenance Report
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-4 col-md-6 buy-button">
                    <div
                      className="bd-pricing-btn pricing-button pt-60"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <a className="theme-btn">Know More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="pricing-table">
                  <div className="col-lg-4 col-md-6 package-price">
                    <div className="bd-pricing-title-wrapper text-center ">
                      <h4
                        className="bd-pricing-subtitle mb-15 pt-75"
                        style={{ fontWeight: "600" }}
                      >
                        Magento Package
                      </h4>
                      <h6
                        className="bd-pricing-price"
                        style={{ fontSize: "44px" }}
                      >
                        £149<span>.99/Month</span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 package-content">
                    <ul
                      className=" pl-60 centering-detail"
                      style={{ color: "black", fontSize: "20px" }}
                    >
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Extension installation and configuration
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Magento Security Patches
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        CMS content update OR Blogs
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Magento store performance optimization
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Fixing CSS coding
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Trouble Shooting Server Issue and DB Error
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Support Via Email / Skype / Phone
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Monthly and Quarterly Magento Maintenance Report
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6 buy-button">
                    <div
                      className="bd-pricing-btn pricing-button pt-15"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <a className="theme-btn">Know More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="pricing-table">
                  <div className="col-lg-4 col-md-6 package-price">
                    <div className="bd-pricing-title-wrapper text-center ">
                      <h4
                        className="bd-pricing-subtitle mb-15 pt-30"
                        style={{ fontWeight: "600" }}
                      >
                        E-Commerce
                      </h4>
                      <h6
                        className="bd-pricing-price"
                        style={{ fontSize: "44px" }}
                      >
                        £174<span>.99/Month</span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 package-content">
                    <ul
                      className="pl-60 centering-detail"
                      style={{ color: "black", fontSize: "20px" }}
                    >
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Online store speed optimization
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Ecommerce security management and upgrades
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Payment Gateway and Shipping integration
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        API Maintenance
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Checkout page optimization
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6 buy-button">
                    <div
                      className="bd-pricing-btn pricing-button"
                      style={{ width: "80%", marginTop: "50px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <a className="theme-btn">Know More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="pricing-table">
                  <div className="col-lg-4 col-md-6 package-price">
                    <div className="bd-pricing-title-wrapper text-center ">
                      <h4
                        className="bd-pricing-subtitle pt-15"
                        style={{ fontWeight: "600" }}
                      >
                        Custom Package
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 package-content">
                    <ul
                      className="pl-60 centering-detail"
                      style={{ color: "black", fontSize: "20px" }}
                    >
                      <li style={{ listStyle: "none" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        We will evaluate your system & then give you quote
                      </li>
                      <li style={{ listStyle: "none", paddingTop: "10px" }}>
                        <i></i>
                        <FontAwesomeIcon
                          style={{
                            color: "var(--clr-body-heading)",
                            paddingRight: "15px",
                          }}
                          icon={faCheck}
                        />
                        Please contact us for the same{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6 buy-button">
                    <div
                      className="bd-pricing-btn pt-10"
                      style={{ width: "80%" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <a className="theme-btn">Know More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Popup */}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel4"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content" style={{ width: "120%" }}>
                <div className="modal-header" style={{ border: "none" }}>
                  <h5 className="modal-title" id="exampleModalLabel4">
                    Fill out the Details!
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ marginLeft: "50px" }}
                  ></button>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingRight: "25px" }}>
                    {isLoading && <Spinner />}
                    <form onSubmit={handleSubmit}>
                      <div className="row" style={{ paddingTop: "23px" }}>
                        <div className="bd-contact-field mb-20">
                          <input
                            className="blog-form"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={{ height: "45px" }}
                            placeholder="Name"
                            name="user_name"
                            required
                          />
                        </div>
                        <div className="bd-contact-field mb-20">
                          <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="user_email"
                            style={{ height: "45px" }}
                            required
                          />
                        </div>
                        <div className="bd-contact-field mb-20 ">
                          <input
                            type="text"
                            placeholder="Contact No"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            name="user_contact"
                            style={{ height: "45px" }}
                            required
                          />
                        </div>
                        <div className="bd-contact-field mb-20 ">
                          <input
                            type="text"
                            placeholder="Website Link"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            name="user_contact"
                            style={{ height: "45px" }}
                            required
                          />
                        </div>
                        <div className="bd-contact-field mb-20 ">
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            style={{
                              width: "100%",
                              height: "60px",
                              backgroundColor: "var(--clr-bg-gray)",
                              border: "none",
                              borderRadius: "10px",
                              color: "var(--clr-body-text)",
                              fontWeight: "400",
                              fontSize: "16px",
                              paddingLeft: "20px",
                              height: "45px",
                            }}
                            value={type}
                            onChange={(e) => setTypes(e.target.value)}
                            required
                          >
                            <option
                              style={{
                                color: "var(--clr-body-text)",
                                fontSize: "16px",
                              }}
                            >
                              Select the Packages
                            </option>
                            <option value="Basic Package">
                              {" "}
                              Basic Package
                            </option>
                            <option value="WordPress Package">
                              WordPress Package
                            </option>
                            <option value="Magento Packagee">
                              Magento Package
                            </option>
                            <option value="E-Commerce">E-Commerce</option>
                            <option value="Custom Package">
                              Custom Package
                            </option>
                          </select>
                        </div>
                        <div className="col-12 mb-20">
                          <div className="bd-contact-field">
                            <button type="submit" className="theme-btn">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="hello pt-25">
                    <img
                      src="https://img.zithas.co.uk/portfolio-side_y0lpqc.avif"
                      alt="img not found"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bd-video-area pt-135 pb-130"
          style={{ backgroundColor: "#5c0385" }}
        >
          <div className="container" style={{ maxWidth: "1270px" }}>
            <div className="row responsive-banner">
              <div className="col-lg-12 first_section">
                <div className="bd-video-text">
                  <h2 className="bd-video-title first-para-resp">
                    Looking for Web Maintenance expert ?
                  </h2>
                  <p className="contact_section second-para-resp">
                    You're at the right place. The right time
                  </p>
                  <p className="contact_subtext">
                    Surprisingly great Choice, Let's get started right now !
                  </p>
                </div>
              </div>
              <div className="col-lg-12 second_section">
                <div className="contact-stuff pt-30">
                  <div className="contact_icon icon_respon pt-15 pl-50">
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ fontSize: "30px" }}
                    />
                  </div>
                  <div className="contact_txt ">
                    <p className="contact-sub-text">CALL US NOW</p>
                    <a
                      href="tel:(0) 776-742-6142"
                      style={{ fontSize: "17px" }}
                      className="mbb-5 pb-20"
                    >
                      (0) 776-742-6142
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </div>
  );
};

export default PricingTable;
