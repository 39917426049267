import React from "react";
import "../Styles/Service.css";
import "../assets/css/flaticon.css";
import "../Styles/Theme.css";
import "../assets/css/custom-animation.css";
import { Link } from "react-router-dom";

const Service = () => {
  const img = "https://img.zithas.co.uk/automated-process-img-icon.png";
  return (
    <div>
      <section className="bd-service-area main-top-service pt-80 pb-50 gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 pt-20">
              <div className="bd-section-title-wrapper mb-50">
                <p className="bd-section-subtitle mb-15 subtitle-without-border">
                  Our Services
                </p>
                <h2 className="bd-section-title">
                  Shaping Business World of{" "}
                  <span style={{ color: "#ffbf1e" }}>Tomorrow</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src={
                        "https://img.zithas.co.uk/automated-process-64_f0p7gm.avif"
                      }
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/mobile-app-development"}>App Development</Link>
                </h4>
                <p>
                  Web and mobile application development carry out the
                  development of software for web browsers and mobile devices
                  respectively.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/business-automation-icon_m4xog2.avif"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/"}>Booking System</Link>
                </h4>
                <p>
                  Keep track of your camping reservations with this easy to use
                  bookings system.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/software-development-icon_g7ek9o.avif"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/business-software-audit"}>
                    Business software Audit
                  </Link>
                </h4>
                <p>
                  The powerful tool can help you identify any gaps in your
                  business, as well as identify new opportunities and potential
                  solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/mobile-apps-icon_q5su8e.avif"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/bespoke-systems"}>Bespoke software company</Link>
                </h4>
                <p>
                  Bespoke system can create custom solutions that are tailored
                  to your specific needs with its unique design and personalised
                  features.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="bd-service mb-30">
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/dedicate-resource-icon_znxuyz.webp"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  <Link to={"/business-automation"}>Business Automation</Link>
                </h4>
                <p>
                  Business automation combines technology and business strategy
                  to make organisations run more efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Service;
