import React from "react";
import "../Styles/Testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonial_img1 from "../assets/All Images/w-hanson.png";
import carla_testimonial from "../assets/All Images/carla-img.png";
import c2k_img from "../assets/All Images/c2k_img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import youtubeImg from "../../src/assets/All Images/youtube-img.png";
import { Link } from "react-router-dom";

const Testimonial = () => {
  const settings12 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <section className="bd-testimonial-area gray-bg pt-100 pb-50">
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="row media_test ">
            <div className="col-xl-8 media_testimonial">
              <div className="bd-testimonial swiper-container testimonial-text mb-50">
                <div className="swiper-wrapper">
                  <Slider {...settings12}>
                    <div className="swiper-slide">
                      <div className="bd-testimonial-item text-center">
                        <div className="bd-testimonial-icon mb-45">
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            style={{
                              fontSize: "50px",
                              color: "var(--clr-theme-1)",
                            }}
                          />
                        </div>
                        <p className="mb-20">
                          Zithas has been a valuable partner to our business,
                          providing us with a wide range of solutions to stay
                          ahead of the competition. Their team is highly
                          professional and knowledgeable, and we highly
                          recommend them to anyone looking for reliable and
                          effective IT services.
                        </p>
                        <div
                          className="home-testimonial-img"
                          style={{ alignItems: "center" }}
                        >
                          <div className="bd-testimonial-img first-test1">
                            <img
                              src={Testimonial_img1}
                              alt="img not found"
                              style={{ width: "132px" }}
                            />
                          </div>
                          <div
                            className="first-test2"
                            style={{ marginRight: "0px" }}
                          >
                            <h3 className="bd-testimonial-title">
                              Simon Gorringe
                            </h3>
                            <span className="testimonial-name ">
                              Managing Director , W.Hanson Group
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="bd-testimonial-item text-center">
                        <div className="bd-testimonial-icon mb-45">
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            style={{
                              fontSize: "50px",
                              color: "var(--clr-theme-1)",
                            }}
                          />
                        </div>
                        <p className="mb-20">
                          Great Developers! Have found the developers to be very
                          responsive. They overcame many technical challenges
                          that were initially outside of their comfort zone,
                          using a variety of programming languages such as C#
                          and Core 5 net. Happy to recommend them
                        </p>
                        <div
                          className="home-testimonial-img"
                          style={{ alignItems: "center" }}
                        >
                          <div
                            className="bd-testimonial-img second-test1"
                            style={{ width: "34%" }}
                          >
                            <img
                              src={c2k_img}
                              alt="img not found"
                              style={{ marginLeft: "20px" }}
                            />
                          </div>
                          <div
                            className="first-test2 mr-0 "
                            style={{ marginRight: "0px" }}
                          >
                            <h3 className="bd-testimonial-title">
                              {" "}
                              Gash Bhullar
                            </h3>
                            <span className="testimonial-name pb-20">
                              Founder of Alfred Immigration Services
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="bd-testimonial-item text-center">
                        <div className="bd-testimonial-icon mb-45">
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            style={{
                              fontSize: "50px",
                              color: "var(--clr-theme-1)",
                            }}
                          />
                        </div>
                        <p className="mb-20">
                          Working with the team at Zithas was so easy and
                          efficient. I was kept up-to-date with the status of my
                          website project regularly and regular status calls
                          were always arranged to discuss any questions that
                          arose. I am happy to have found them.
                        </p>
                        <div
                          className="home-testimonial-img"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="bd-testimonial-img third-test1">
                            <img
                              src={carla_testimonial}
                              alt="img not found"
                              style={{ width: "120px", marginLeft: "0px" }}
                            />
                          </div>
                          <div className="third-test2">
                            <h3 className="bd-testimonial-title">
                              Carla Caswell
                            </h3>
                            <span className="testimonial-name pb-20">
                              Perfectionism Coach & Marketeer - Carla Caswell{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="bd-testimonial-item text-center">
                        <div className="bd-testimonial-icon mb-45">
                          <FontAwesomeIcon
                            icon={faQuoteLeft}
                            style={{
                              fontSize: "50px",
                              color: "var(--clr-theme-1)",
                            }}
                          />
                        </div>
                        <p className="mb-20">
                          Thank you so much Zithas. The functionality is
                          fantastic!!I love to work on new CRM developed and it
                          looks so good as a smaller platform. I am very, very
                          happy with the work you guys have done. Thanks again -
                          and pass my thanks to your team too :)
                        </p>
                        <div className="home-testimonial-img">
                          <div className="bd-testimonial-img sixth-test1">
                            <img
                              src="https://img.zithas.co.uk/tvm_gqehce.webp"
                              alt="img not found"
                              style={{ width: "150px", marginLeft: "20px" }}
                            />
                          </div>
                          <div className="sixth-test2">
                            <h3 className="bd-testimonial-title">
                              Bhumit Sangharajaka
                            </h3>
                            <span className="testimonial-name pb-20">
                              Head Manager, The Visa Managers
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-xl-8 pl-20 second_width_test">
              <div className="testimonial_video pt-20 pl-40">
                <Link to="/testimonial-video">
                  <img src={youtubeImg} alt="no Img" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
