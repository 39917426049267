import React, { useState } from "react";
import "../../Styles/Redirecting Styles/WebDevelopment.css";
import Footer from "../../Components/Footer";
import { faPhone, faPlay } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header1 from "../../Components/seperate Components/Header1";
import "../../Styles/Redirecting Styles/NavbarStyles/Business.css";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import ModalVideo from "react-modal-video";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const WebDevelopment = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Helmet>
        <title>
          Business Automation Service Provider Company England,UK | Zithas
          Technologies
        </title>
        <meta
          name="description"
          content="Among the top business automation companies, We at Zithas offer the best business automation service in Wales, Bristol, Cardiff, London and England. "
        />
        <meta
          name="keywords"
          content="
        business automation service uk,
        business automation service bristol,
        business automation service london,
        business automation service wales,
        business automation service cardiff,
        business automation service england, 
        best business automation company
        "
        />
      </Helmet>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg2 pt-220 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="bd-page-title text-center z-index"
                style={{ bottom: "45px" }}
              >
                <h1
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Business Automation
                </h1>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      Say goodbye to tedious manual tasks and hello to increased
                      productivity with our business automation services
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-choose-area fix pt-80 pb-90">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="bd-choose-shape">
            <div className="row">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 pr-20">
                <div className="bd-choose-text z-index">
                  <div className="bd-section-title-wrapper ">
                    <h2 className="bd-section-title mb-15 first_section-title">
                      Must-Have Services for Growth
                    </h2>
                  </div>
                  <p
                    className="mb-20 first_section_para1"
                    style={{ fontSize: "18px", lineHeight: "1.75" }}
                  >
                    Our{" "}
                    <h1
                      className=""
                      style={{
                        fontSize: "18px",
                        lineHeight: "1.75",
                        display: "inline",
                        color: "#5e5858",
                        fontWeight: "400",
                      }}
                    >
                      business automation service
                    </h1>
                    , located in Bristol, offers powerful tools for streamlining
                    processes and improving efficiency in the surrounding area
                    of Bristol like in London, Wales, Cardiff, etc. By
                    automating repetitive tasks like data entry, scheduling, and
                    decision-making, businesses can save time and resources,
                    improving overall performance.
                  </p>
                  <p
                    className="mb-20 first_section_para1"
                    style={{
                      width: "95%",
                      fontSize: "18px",
                      lineHeight: "1.75",
                    }}
                  >
                    One of the key benefits of our service, based in Bristol, is
                    its ability to integrate with other tools and systems. This
                    means that businesses can easily connect their existing
                    systems and{" "}
                    <Link
                      to="/software-development"
                      style={{ fontWeight: "500" }}
                    >
                      applications
                    </Link>{" "}
                    to our service, allowing for seamless automation across
                    multiple platforms. With our Bristol-based service,
                    businesses can improve productivity and gain a competitive
                    edge by automating critical tasks and processes
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                <div className="bd-choose-img text-end top-resp z-index">
                  <div className="row align-items-center  custom-mar-15">
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                        <img
                          src="https://img.zithas.co.uk/business-automation-section1.1_xypsrx.avif"
                          className="resp-img bordertop-img1"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15">
                        <img
                          src="https://img.zithas.co.uk/business-automation-section1_pk3evd.avif"
                          className="resp-img bordertop-img2"
                          alt="img not found"
                        />
                      </div>
                      <div className="bd-choose-img-inner mb-15">
                        <img
                          src="https://img.zithas.co.uk/business-automation-section1.2_fsxkin.avif"
                          className="resp-img bordertop-img3"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="bd-choose-shape bd-choose-shape-2"
                    src={Shape2}
                    alt="shape img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-area-two pb-15">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-7 second_section_Business1">
              <div className="bd-about-img-two">
                <img
                  src="https://img.zithas.co.uk/business-automation-second-section_jybtml.avif"
                  className=""
                  alt="img not found"
                  style={{ width: "90%", height: "420px" }}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-8 second_section_Business2">
              <div className="bd-about-text-two">
                <div className="bd-section-title-wrapper mb-25">
                  <h2 className="bd-section-title mb-15 second_sec_tittle">
                    Revolutionize Your Business with Automation
                  </h2>
                  <p>
                    Business automation is rapidly becoming a critical tool for
                    leaders looking to improve efficiency and stay competitive
                    in today's fast-paced business environment. Automation
                    technology enables businesses to streamline their processes,
                    improve efficiency and reduce operational costs by
                    automating repetitive tasks such as data entry, scheduling
                    and even simple decision making. Furthermore, automation
                    tools allow businesses to make more informed decisions by
                    processing and analyzing large amounts of data quickly and
                    effectively, and with the integration of AI, it's even more
                    advanced capabilities such as predictive analytics and
                    decision making.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-service-area pt-80 ">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <div className="col-12">
              <div className="bd-section-title-wrapper text-center mb-25">
                <h2
                  className="bd-section-title mb-10 third_sec_tittle"
                  style={{ textAlign: "left" }}
                >
                  <span style={{ color: "#ffbf1e" }}>
                    Business Automation :{" "}
                  </span>
                  The secret weapon for Productivity
                </h2>

                <h5 className="text-start py-2">Types of Automation</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <h4 className="bd-service-title mb-20">Workflow Automation.</h4>
                <p>
                  Stay on top of your business operations by using workflow
                  management for managing on-call staff rotations, deadlines,
                  work schedules, and service requests.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <h4 className="bd-service-title mb-20">Task Automation.</h4>
                <p>
                  Task automation uses technology to automate repetitive,
                  time-consuming tasks, improving efficiency, reducing errors,
                  freeing up resources, and providing additional benefits.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <h4 className="bd-service-title mb-20">Process Automation.</h4>
                <p>
                  Revolutionize your business and enjoy the benefits of
                  automation of business processes for effective product or
                  service delivery
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Fourth Section */}
      <section className="bd-brand-area-two pb-80 pt-70">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row fourt_respon_business">
            <div className="col-xl-4 col-lg-6 col-md-6 first_part_respons">
              <div className="col-12">
                <div className="bd-section-title-wrapper mb-20">
                  <h2 className="bd-section-title mb-25 pl-0 fourth_sec_tittle">
                    Balancing{" "}
                    <span style={{ color: "#ffbf1e" }}> Automation </span> with
                    Human intelligence
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-6 col-md-6 second_part_respons">
              <div className="bd-brand-active-two swiper-container">
                <div className="swiper-wrapper">
                  <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                    Implementing business automation can come with a set of
                    challenges and pitfalls. One of the common pitfalls is
                    underestimating the complexity of the process and the
                    resources required for a successful implementation, which
                    can lead to delays and increased costs. Another pitfall is
                    lack of proper planning and testing which may result in
                    errors, system crashes, and lost productivity. Additionally,
                    not considering the impact on employees and their roles and
                    not having an integration strategy can also be a pitfall
                    which can lead to resistance and decreased efficiency. It's
                    crucial to carefully plan and evaluate automation projects
                    to avoid these pitfalls and ensure a successful
                    implementation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-six-area ">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row fifth_section_responsive">
            <div className="col-lg-6 Business_fifth_flex1">
              <div className="bd-about-six-text">
                <div className="bd-section-title-wrapper mb-25">
                  <h2 className="bd-section-title mb-25 sixth_sec_tittle">
                    Raise the Bar for Your Business in Bristol with Automation
                  </h2>
                  <div className="bd-about-six-feature">
                    <p className="" style={{ width: "95%" }}>
                      Business automation is a powerful tool with potential to
                      revolutionize operations, but the implementation process
                      can be risky. The automation trap is a common pitfall
                      where processes are automated without considering the
                      overall strategic goals of the organization. This leads to
                      inefficiencies and lack of significant improvement in
                      business outcomes.
                    </p>
                    <p>
                      The black box mentality is another pitfall where the
                      automation solution is seen as a magic solution without
                      fully understanding the underlying issues. This can lead
                      to over-automation and implementation of ineffective
                      solutions. Not having an end-to-end automation strategy is
                      another pitfall where automation is only applied to a
                      specific part of the process, leading to a lack of
                      integration and collaboration between automated systems,
                      resulting in increased complexity and reduced efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 Business_fifth_flex2">
              <div className="bd-about-six-img">
                <div className="bd-about-six-feature">
                  <div className="bd-slider-six-img">
                    <img
                      src="https://img.zithas.co.uk/business-audit-final-section_oilym3.webp"
                      className="rounded-10"
                      alt="img not found"
                      style={{ width: "100%", height: "505px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-choose-area fix">
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="bd-choose-shape pt-80 pb-80 ">
            <div className="row Business_resp_section">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 sixth_sec1_resp">
                <div className="bd-choose-img z-index">
                  <div className="bd-choose-info-wrapper">
                    <div className="container">
                      <div className="row mb-50">
                        <div className="col business-resp mr-50">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/productive-64_gtkgtp.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Error-free work
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/customer-64_sfnng6.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Better Resource Allocation
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row ">
                        <div className="col mr-30">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/tracking-32_f5cmxy.webp"
                                  alt="img not found"
                                  style={{ width: "62px" }}
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Better Data Analysis
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/aim-64_bwmltp.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Enhanced Accuracy
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 sixth_sec2_resp">
                <div className="bd-choose-text z-index">
                  <h2 className="bd-section-title business-last-tittle">
                    Promote Development Efforts
                  </h2>
                  <div className="bd-section-title-wrapper">
                    <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                      Since we are the best business automation company in
                      Bristol, England, we are aware of the value of business
                      automation and the part it plays in keeping organisations
                      competitive. Our mission is to assist businesses in
                      developing systems that are automated, efficient, and
                      aligned with their objectives and strategies. We provide a
                      variety of services to help businesses automate their
                      processes, with a focus on a human-centered approach that
                      prioritises employee needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for Business Automation Expert?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great Automation, Let's get started right now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default WebDevelopment;
