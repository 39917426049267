import React from "react";
import NotfoundImg from "../../assets/All Images/pageNotFound.png";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header1 from "./Header1";

const PageNotFound = () => {
  return (
    <div>
      <Header1 />
      <section className="bd-about-area">
        <div className="bd-custom-container">
          <div className="bd-about-four-bg pb-80 pt-80 fix">
            <div className="bd-about-shape-box">
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row justify-content-center mt-40">
                  <div className="col-xl-6 col-lg-8 pt-130">
                    <h1>Ohps! Page not Found</h1>
                    <h1> Not finding the page you are looking for?</h1>
                    <h1>
                      Please find in{" "}
                      <Link
                        to={"/sitemap"}
                        style={{ color: "rgb(255, 191, 30)" }}
                      >
                        {" "}
                        Sitemap!
                      </Link>
                    </h1>
                    <h1>Thank You and See you there!</h1>
                  </div>
                  <div
                    className="col-xl-6 col-lg-8 pl-30 "
                    style={{ textAlign: "right", backgroundPosition: "center" }}
                  >
                    <img src={NotfoundImg} alt="img not found" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PageNotFound;
