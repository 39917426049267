import React, { useState, useEffect } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faDownload,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Components/Footer";
import Spinner from "../../Components/seperate Components/Spinner";
import swal from "sweetalert";
import seoPdf from "../../assets/slider/Zithas-SEO-Pricing-Packages.pdf";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const SeoManagement = () => {
  const [pricing, setPricing] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [website, setWebsite] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [type, setTypes] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPopUp(true);
    try {
      let res = await fetch("https://mars.zithas.com/api/seo", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          mobile: mobile,
          website: website,
          type: type,
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMobile("");
        setWebsite("");
        setTypes("");
        setLoading(false);
        swal({
          icon: "success",
          text: "Data send Sucessfully",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const changeBackground = () => {
    if (window.scrollY < 1000) {
      setPricing(true);
    } else {
      setPricing(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div>
      <Helmet>
        <title>
          Affordable SEO Management Services and Packages in England, UK
        </title>
        <meta
          name="description"
          content="Are you looking for SEO services in London, England? Zithas is the leading SEO company and provides SEO services in Bristol, Cardiff, Wales, London, and UK. "
        />

        <meta
          name="keywords"
          content="
                seo services in uk,
                seo services england, 
                seo company uk,
                best seo company uk,
                seo company in london,
                seo company in bristol,
                affordable seo package,
                seo packages,
                seo packages uk,
                seo packages london,
                seo services london,
                seo packages bristol,
                seo management packages,
                seo services england, 
                seo packages england, 
                seo management packages bristol"
        />
      </Helmet>
      <Header1 />
      {/* Banner section */}
      <div
        className="bd-page-title-area bd-page-title-bg_pricing pt-175 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2 className="breadcrumb-title1 packages-tittle">
                  SEO Management Packages
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs"></nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bd-brand-area-two pb-80 pt-70">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div
            className="row fourt_respon_business"
            style={{ alignItems: "center" }}
          >
            <div className="col-xl-4 col-lg-6 col-md-6 first-seo_respons">
              <div className="col-12">
                <div className="bd-section-title-wrapper mb-20">
                  <h2 className="bd-section-title mb-25 pl-0 website-main-tittle">
                    <h1 style={{ color: "#ffbf1e" }}>SEO Service</h1> - let your
                    business soar
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-6 col-md-6 second_seo_respons">
              <div className="bd-brand-active-two swiper-container">
                <div className="swiper-wrapper">
                  <p style={{ fontSize: "18px", lineHeight: "1.75" }}>
                    SEO Maintenance is the ultimate solution for businesses that
                    are struggling to maintain their current high search engine
                    ranking. Are you in search of the top SEO company in the UK,
                    specifically England? We specialize in delivering{" "}
                    <h1
                      className=""
                      style={{
                        fontSize: "18px",
                        lineHeight: "1.75",
                        display: "inline",
                        color: "#5e5858",
                        fontWeight: "400",
                      }}
                    >
                      SEO services
                    </h1>{" "}
                    across London, Bristol, Wales, Cardiff, and the entire UK.
                    Our team is experienced in identifying and fixing any issues
                    that may be holding them back from achieving a higher
                    ranking. Rest assured as we offer comprehensive and
                    cost-effective SEO packages throughout the UK, including
                    London, Cardiff, Bristol, and Wales, providing you with
                    peace of mind, you can rest assured that your business will
                    be able to reap the rewards of a high ranking in the near
                    future. We collaborate with clients to identify and resolve
                    any issues that are causing their website or business to
                    fall behind the competition.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-pricing-area  pt-0 pb-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <div className="col-12">
              <div className="bd-section-title-wrapper mb-50 z-index text-center">
                <h5 className="bd-section-subtitle mb-15">Price Table</h5>
                <h2 className="bd-section-title mb-25">Pricing & Packaging</h2>
                <p>
                  Take your business to the next level with our Enterprise Plan
                  and{" "}
                  <Link to="/hire-a-resource" style={{ fontWeight: "500" }}>
                    Dedicated support
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="tab-content responsive_seo" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="row">
                <div className="col-lg-4 col-md-6 seo-responsive-section1">
                  <div className="stickyPricing bd-pricing-seo pt-10">
                    <h4 className="bd-pricing-subtitle mb-25 pt-50 feature-tittle">
                      {" "}
                      Features
                    </h4>
                  </div>
                  <div className="bd-pricing1  mb-30 bd-pricing-active">
                    <ul className="" style={{ color: "white" }}>
                      <li className="response-li"> Keyword Optimized</li>
                      <li className="list-size-responsive">
                        Top 10 Ranking Guarantee
                      </li>
                      <li> Recommended for Targeting</li>
                      <li> Monthly Reporting</li>
                      <li className="response-li"> Support</li>
                    </ul>
                    <p className="section1-responsive">REVIEW & ANALYSIS</p>
                    <ul className="" style={{ color: "white" }}>
                      <li> Website Analysis</li>
                      <li>Competitor Analysis</li>
                      <li> Content Duplicity Check</li>
                    </ul>
                    <p className="section1-responsive">ON-PAGE OPTIMIZATION</p>
                    <ul className="" style={{ color: "white" }}>
                      <li>Optimization of Title, Description & Keywords</li>
                      <li>Optimization of Image Alt Tags</li>
                      <li> Keyword Research & Analysis</li>
                      <li> Website Structure Optimization</li>
                      <li>Internal link structuring & optimization</li>
                      <li>Image & Hyperlink Optimization</li>
                      <li>Page Speed Optimization Analysis</li>
                      <li>Content Optimization</li>
                      <li>Implementation of h1 Tags</li>
                      <li>Image Optimization</li>
                      <li>Google Analytics Installation & Setup</li>
                      <li>Google Webmaster Tools Account Setup</li>
                      <li>Site Map Creation</li>
                      <li>URL Structure Optimization</li>
                      <li>Robot, Tax Creation</li>
                    </ul>
                    <p className="section1-responsive">OFF-PAGE OPTIMIZATION</p>
                    <ul className="" style={{ color: "white" }}>
                      <li>Directory Submission</li>
                      <li>Local Directory Submission</li>
                      <li>Classified Ad Submission</li>
                      <li>Blog Commenting</li>
                      <li>Article Content Writing</li>
                      <li>Article Submission</li>
                      <li>Document Sharing</li>
                      <li>Social Bookmarking</li>
                      <li>Local Business Listing</li>
                      <li>Search Engine Submission</li>
                      <li>Image Posting & Submission</li>
                      <li>RSS Feed Submission</li>
                      <li>YouTube Video Promotion(Client to Provide Video)</li>
                      <li>Free Blog Creation & Promotion</li>
                      <li>Blog Post Content</li>
                      <li>Press Release (Client to Provide Content)</li>
                      <li>Guest Blogging</li>
                    </ul>
                    <p className="section1-responsive">REVIEW & ANALYSIS</p>
                    <ul className="" style={{ color: "white" }}>
                      <li> Facebook Setup & Posting</li>
                      <li>Google Plus Setup & Posting</li>
                      <li>Twitter Setup & Posting</li>
                      <li>Linkedin Setup & Posting</li>
                      <li> Pinterest Setup & Posting</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 seo-responsive-section2">
                  <div className="stickyPricing bd-pricing-seo pt-10">
                    <h4
                      className="bd-pricing-subtitle mb-15"
                      style={{ fontWeight: "600" }}
                    >
                      Silver
                    </h4>
                    <h6
                      className="bd-pricing-price pricing-seo"
                      style={{ fontSize: "36px" }}
                    >
                      £ <br /> 149
                      <span className="month-tip" style={{ fontSize: "24px" }}>
                        .99/Month
                      </span>
                    </h6>
                  </div>
                  <div
                    className="bd-pricing1  mb-30 "
                    style={{ backgroundColor: "var(--clr-bg-gray)" }}
                  >
                    <ul
                      className=""
                      style={{ color: "black", textAlign: "center" }}
                    >
                      <li>
                        <i></i> 10 Keywords
                      </li>
                      <li className="response-li">
                        <i></i> 2{" "}
                      </li>
                      <li className="response-li">
                        <i></i> Local
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> Email & Phone
                      </li>
                    </ul>
                    <p className="section-responsive">REVIEW & ANALYSIS</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <p className="section-responsive">ON-PAGE OPTIMIZATION</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li className="main-height ">
                        <i></i>Upto 10 Pages
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li ">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li ">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <p className="section-responsive">OFF-PAGE OPTIMIZATION</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li>
                        <i></i>30
                      </li>
                      <li className="response-li">
                        <i></i>10
                      </li>
                      <li className="response-li">
                        <i></i>10
                      </li>
                      <li>
                        <i></i>10
                      </li>
                      <li className="response-li">
                        <i></i>2
                      </li>
                      <li>
                        <i></i>10
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i>20
                      </li>
                      <li>
                        <i></i>10
                      </li>
                      <li className="response-li">
                        <i></i>5
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main2-height responsive-three-li">
                        <i></i>1
                      </li>
                      <li className="main-height response-li">
                        <i></i>2
                      </li>
                      <li>
                        <i></i>2
                      </li>
                      <li className="main-height response-li">
                        <i></i>1
                      </li>
                      <li>
                        <i></i>No
                      </li>
                    </ul>
                    <p className="section-responsive">REVIEW & ANALYSIS</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <div className="bd-pricing-btn">
                      <button
                        data-id="silver"
                        className="theme-btn onModal"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Select Plan
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 seo-responsive-section3">
                  <div className="stickyPricing bd-pricing-seo ">
                    <h4
                      className="bd-pricing-subtitle mb-15"
                      style={{ fontWeight: "600" }}
                    >
                      Gold
                    </h4>
                    <h6
                      className="bd-pricing-price pricing-seo"
                      style={{ fontSize: "36px" }}
                    >
                      £ <br /> 224
                      <span style={{ fontSize: "24px" }}>.99/Month</span>
                    </h6>
                  </div>
                  <div
                    className="bd-pricing1 mb-30 "
                    style={{ backgroundColor: "var(--clr-bg-gray)" }}
                  >
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li>
                        <i></i> 20 Keywords
                      </li>
                      <li className="response-li">
                        <i></i> 4{" "}
                      </li>
                      <li>
                        <i></i> Local + Global
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> Email & Phone
                      </li>
                    </ul>
                    <p className="section-responsive">REVIEW & ANALYSIS</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <p className="section-responsive">ON-PAGE OPTIMIZATION</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li className="main-height">
                        <i></i>Upto 20 Pages{" "}
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li ">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height ">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li ">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li ">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <p className="section-responsive">OFF-PAGE OPTIMIZATION</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li>
                        <i></i>60
                      </li>
                      <li className="response-li">
                        <i></i>15
                      </li>
                      <li className="response-li">
                        <i></i>15
                      </li>
                      <li>
                        <i></i>15
                      </li>
                      <li className="response-li">
                        <i></i>4
                      </li>
                      <li>
                        <i></i>20
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i>30
                      </li>
                      <li>
                        <i></i>15
                      </li>
                      <li className="response-li">
                        <i></i>10
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main2-height responsive-three-li">
                        <i></i>2
                      </li>
                      <li className="main-height response-li">
                        <i></i>3
                      </li>
                      <li>
                        <i></i>3
                      </li>
                      <li className="main-height response-li">
                        <i></i>2
                      </li>
                      <li>
                        <i></i>1
                      </li>
                    </ul>
                    <p className="section-responsive">REVIEW & ANALYSIS</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <div className="bd-pricing-btn">
                      <button
                        className="theme-btn onModal"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Select Plan
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 seo-responsive-section4">
                  <div className="stickyPricing bd-pricing-seo">
                    <h4
                      className="bd-pricing-subtitle mb-15"
                      style={{ fontWeight: "600" }}
                    >
                      Platinum
                    </h4>
                    <h6
                      className="bd-pricing-price pricing-seo"
                      style={{ fontSize: "36px" }}
                    >
                      £ <br /> 374
                      <span style={{ fontSize: "24px" }}>.99/Month</span>
                    </h6>
                  </div>
                  <div
                    className="bd-pricing1 mb-30"
                    style={{ backgroundColor: "var(--clr-bg-gray)" }}
                  >
                    <ul
                      className=""
                      style={{ color: "black", textAlign: "center" }}
                    >
                      <li>
                        <i></i> 40 Keywords
                      </li>
                      <li className="response-li">
                        <i></i> 5{" "}
                      </li>
                      <li className="response-li">
                        <i></i> Global
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> Email & Phone
                      </li>
                    </ul>
                    <p className="section-responsive">REVIEW & ANALYSIS</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <p className="section-responsive">ON-PAGE OPTIMIZATION</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li className="main-height">
                        <i></i>Upto 40 Pages
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li ">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main-height response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <p className="section-responsive">OFF-PAGE OPTIMIZATION</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li>
                        <i></i>100
                      </li>
                      <li className="response-li">
                        <i></i>20
                      </li>
                      <li className="response-li">
                        <i></i>20
                      </li>
                      <li>
                        <i></i>20
                      </li>
                      <li className="response-li">
                        <i></i>6
                      </li>
                      <li>
                        <i></i>30
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i>50
                      </li>
                      <li>
                        <i></i>20
                      </li>
                      <li className="response-li">
                        <i></i>15
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li>
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="main2-height responsive-three-li">
                        <i></i>3
                      </li>
                      <li className="main-height response-li">
                        <i></i>4
                      </li>
                      <li>
                        <i></i>4
                      </li>
                      <li className="main-height response-li">
                        <i></i>3
                      </li>
                      <li>
                        <i></i>2
                      </li>
                    </ul>
                    <p className="section-responsive">REVIEW & ANALYSIS</p>
                    <ul
                      className=""
                      style={{ textAlign: "center", color: "black" }}
                    >
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                      <li className="response-li">
                        <i></i> <FontAwesomeIcon icon={faCheck} />
                      </li>
                    </ul>
                    <div className="bd-pricing-btn">
                      <button
                        className="theme-btn onModal"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{ backgroundColor: "var(--clr-theme-1)" }}
                      >
                        Select Plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Popup */}

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel5"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content size-pop">
              <div className="modal-header" style={{ border: "none" }}>
                <h5 className="modal-title" id="exampleModalLabel5">
                  Fill out the Details!
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ marginLeft: "50px" }}
                ></button>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", paddingRight: "25px" }}>
                  {isLoading && <Spinner />}
                  <form onSubmit={handleSubmit}>
                    <div className="row" style={{ paddingTop: "23px" }}>
                      <div className="bd-contact-field mb-20">
                        <input
                          className="blog-form"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          style={{ height: "45px" }}
                          placeholder="Name"
                          name="user_name"
                          required
                        />
                      </div>
                      <div className="bd-contact-field mb-20">
                        <input
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          name="user_email"
                          style={{ height: "45px" }}
                          required
                        />
                      </div>
                      <div className="bd-contact-field mb-20 ">
                        <input
                          type="text"
                          placeholder="Contact No"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          name="user_contact"
                          style={{ height: "45px" }}
                          required
                        />
                      </div>
                      <div className="bd-contact-field mb-20 ">
                        <input
                          type="website"
                          placeholder="Website Link"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                          name="user_contact"
                          style={{ height: "45px" }}
                          required
                        />
                      </div>
                      <div className="bd-contact-field mb-20 ">
                        <select
                          className="form-select form-select-sm"
                          aria-label=".form-select-sm example"
                          style={{
                            width: "100%",
                            height: "60px",
                            backgroundColor: "var(--clr-bg-gray)",
                            border: "none",
                            borderRadius: "10px",
                            color: "var(--clr-body-text)",
                            fontWeight: "400",
                            fontSize: "16px",
                            paddingLeft: "20px",
                            height: "45px",
                          }}
                          value={type}
                          onChange={(e) => setTypes(e.target.value)}
                          required
                        >
                          <option
                            style={{
                              color: "var(--clr-body-text)",
                              fontSize: "16px",
                            }}
                          >
                            Select the Packages
                          </option>
                          <option value="Silver Package">
                            {" "}
                            Silver Package{" "}
                          </option>
                          <option value="Gold Package">Gold Package</option>
                          <option value="Platinum Package">
                            Platinum Package
                          </option>
                        </select>
                      </div>

                      <div className="col-12 mb-20">
                        <div className="bd-contact-field">
                          <button type="submit" className="theme-btn">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="form-img pt-25">
                  <img
                    src="https://img.zithas.co.uk/portfolio-side_y0lpqc.avif"
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* mobile responsive */}
        <div className="row mobile-reponsive-seo">
          <div className="col-12 responsive-side">
            <div className="bd-section-title-wrapper mb-50 z-index text-center">
              <h6 className="mb-25" style={{ fontSize: "18px" }}>
                Download Package to View More
              </h6>
              <span className="bd-about-text-btn">
                <a
                  href={seoPdf}
                  download
                  className="theme-btn mr-15 three-button-respon"
                  style={{ paddingRight: "25px", paddingLeft: "25px" }}
                >
                  <i style={{ lineHeight: "4", fontStyle: "normal" }}>
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ fontSize: "20px", paddingRight: "10px" }}
                    />
                    Download Packages
                  </i>
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="row mobile-reponsive-seo">
          <div
            className="bd-service mb-30"
            style={{ backgroundColor: "var(--clr-bg-gray)" }}
          >
            <div className="pricing-table">
              <div className="col-lg-4 col-md-6 package-price">
                <div className="bd-pricing-title-wrapper text-center ">
                  <h4
                    className="bd-pricing-subtitle mb-15"
                    style={{ fontWeight: "600" }}
                  >
                    Silver
                  </h4>
                  <h6 className="bd-pricing-price" style={{ fontSize: "44px" }}>
                    £149<span>.99/Month</span>
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 buy-button">
                <div
                  className="bd-pricing-btn pricing-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <a className="theme-btn">Buy Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mobile-reponsive-seo">
          <div
            className="bd-service mb-30"
            style={{ backgroundColor: "var(--clr-bg-gray)" }}
          >
            <div className="pricing-table">
              <div className="col-lg-4 col-md-6 package-price">
                <div className="bd-pricing-title-wrapper text-center ">
                  <h4
                    className="bd-pricing-subtitle mb-15"
                    style={{ fontWeight: "600" }}
                  >
                    Gold
                  </h4>
                  <h6 className="bd-pricing-price" style={{ fontSize: "44px" }}>
                    £224<span>.99/Month</span>
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 buy-button">
                <div
                  className="bd-pricing-btn pricing-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <a className="theme-btn">Buy Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mobile-reponsive-seo">
          <div
            className="bd-service mb-30"
            style={{ backgroundColor: "var(--clr-bg-gray)" }}
          >
            <div className="pricing-table">
              <div className="col-lg-4 col-md-6 package-price">
                <div className="bd-pricing-title-wrapper text-center">
                  <h4
                    className="bd-pricing-subtitle mb-15"
                    style={{ fontWeight: "600" }}
                  >
                    Platinum
                  </h4>
                  <h6 className="bd-pricing-price" style={{ fontSize: "44px" }}>
                    £374<span>.99/Month</span>
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 buy-button">
                <div
                  className="bd-pricing-btn pricing-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <a className="theme-btn">Buy Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for SEO expert ?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great SEO Packages, Let's get started right now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SeoManagement;
