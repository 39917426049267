import React, { useState, useEffect } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../Styles/Redirecting Styles/FooterStyle/AboutCompany.css";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import pdf1 from "../../assets/company-pdf/Company Profile.pdf";
import axios from "axios";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  var setting1 = {
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/portfolio",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        axios({
          url: "https://mars.zithas.com/api/getpdf", //your url
          method: "GET",
          responseType: "blob", // important
        }).then((response) => {
          alert("Plaese click ok button to Download Company Portfolio");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "company-Portfolio.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [team, setTeam] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get("https://mars.zithas.com/api/team_uk");
        setTeam(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          The Best Website Design Company in England, UK | Zithas Technologies
        </title>
        <meta
          name="description"
          content="Zithas is a leading IT service provider in England, UK. We offer the finest services to our clients that include best website design, software development, etc."
        />
        <meta
          name="keywords"
          content="
                best website design company,
                software development company uk,
                best dedicated resources
                "
        />
      </Helmet>
      <Header1 />
      {/* Banner section */}
      <div
        className="bd-page-title-area bd-page-title-bg_about pt-175 pb-155 "
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  About Us
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      Zithas team is a diverse network of consultants and
                      industry professionals with a global mindset and a
                      culture.
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-about-area-two second-part-resp pt-80 pb-100 mb-40">
        <div className="container about-second-resp">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 responsive-section1">
              <div className="bd-about-text-two bd-about-text-five ">
                <div className="bd-section-title-wrapper mb-25">
                  <h2
                    className="bd-section-title mb-15"
                    style={{ fontSize: "44px" }}
                  >
                    The Evolution of Zithas
                  </h2>
                  <p
                    className="about-us para-about"
                    style={{ fontSize: "18px" }}
                  >
                    Welcome to Zithas, your go-to IT service provider in the UK.
                    We started our journey in Bristol, England and quickly
                    established ourselves as a leader in the industry. With the
                    success of our initial projects, we expanded our reach to
                    other islands such as Scotland, Wales, and Northern Ireland.
                  </p>
                  <h6
                    className="bd-section-title mb-15"
                    style={{ fontSize: "20px", lineHeight: "1.45" }}
                  >
                    One opportunity that you give us, we will ensure it marks a
                    positive difference in your business
                  </h6>
                  <p
                    className="about-us para-about"
                    style={{ fontSize: "18px" }}
                  >
                    The aim of Zithas does not simply direct towards providing
                    good services to its clients, but at the same time we make
                    sure that the clients have an entirely new experience with
                    us that can help their business grow and reach its pinnacle.
                  </p>
                  <p
                    className="about-us para-about"
                    style={{ fontSize: "18px" }}
                  >
                    With completely result driven strategies and actions, we try
                    to bring out the best of your business in the market in
                    order to expand its reach, visibility, recognition and
                    quality.
                  </p>
                  <div className="bd-about-text-five-facts">
                    <ul>
                      <li>
                        <div
                          className="bd-about-text-five-fact"
                          data-animation="fadeInUp"
                          data-delay="1.1s"
                        >
                          <h4 className="bd-slider-fact-title text-heading">
                            150+
                          </h4>
                          <span className="bd-slider-fact-subtitle">
                            Happy Clients
                          </span>
                        </div>
                      </li>
                      <li>
                        <div
                          className="bd-about-text-five-fact"
                          data-animation="fadeInUp"
                          data-delay="1.3s"
                        >
                          <h4 className="bd-slider-fact-title text-heading">
                            200+
                          </h4>
                          <span className="bd-slider-fact-subtitle">
                            Project Cmpleted
                          </span>
                        </div>
                      </li>
                      <li>
                        <div
                          className="bd-about-text-five-fact"
                          data-animation="fadeInUp"
                          data-delay="1.5s"
                        >
                          <h4 className="bd-slider-fact-title text-heading">
                            300+
                          </h4>
                          <span className="bd-slider-fact-subtitle">
                            Team Members
                          </span>
                        </div>
                      </li>
                      <li>
                        <div
                          className="bd-about-text-five-fact"
                          data-animation="fadeInUp"
                          data-delay="1.5s"
                        >
                          <h4 className="bd-slider-fact-title text-heading">
                            $50K
                          </h4>
                          <span className="bd-slider-fact-subtitle">
                            Highest Project Value
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-8  second-part responsive-section2">
              <div className="about-section1">
                <div className="about-avatar ml-50">
                  <img
                    src="https://img.zithas.co.uk/first-year_mkmebc.webp"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
                <div className="vertical-line">
                  <div className="about-dot"></div>
                  <div className="popover__content" style={{ height: "55px" }}>
                    <h5
                      className="about-text1 para-text mt-15 ml-10"
                      style={{ fontSize: "21px" }}
                    >
                      Office in UK & Clients in Canada
                    </h5>
                  </div>
                </div>
              </div>

              <div className="about-section3">
                <div className="about-avatar">
                  <img
                    src="https://img.zithas.co.uk/second-year_gvnra8.webp"
                    className="img-fluid section-img2"
                    alt="img"
                  />
                </div>
              </div>

              <div className="about-section4">
                <div className="about-dot2">
                  <div className="popover__content2" style={{ height: "55px" }}>
                    <h5
                      className="about-text ml-10 mt-15 para-text"
                      style={{ fontSize: "21px" }}
                    >
                      Clients in New zealand & UAE
                    </h5>
                  </div>
                </div>
                <div className="about-section5">
                  <div className="about-avatar ml-50">
                    <img
                      src="https://img.zithas.co.uk/third-year_svrzle.webp"
                      className="img-fluid last-section section-img3 responsive-section-img"
                      alt="img"
                    />
                  </div>
                  <div className="about-dot3">
                    <div
                      className="popover__content2"
                      style={{ height: "55px" }}
                    >
                      <h5
                        className="about-text ml-10 mt-15 para-text"
                        style={{ fontSize: "21px" }}
                      >
                        Clients in USA & Australia{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="about-avatar ml-50">
                    <img
                      src="https://img.zithas.co.uk/list-goes-on_c2guap.webp"
                      className="img-fluid section-img4 responsive-section-img2"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-60 pb-50"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner5 ">
            <div className="col-lg-12 first_section_about">
              <div className="bd-video-text">
                <p className="contact_section second-para-resp">
                  Connect Inspiration to{" "}
                  <span style={{ color: "#ffbf1e" }}>Excellence</span>
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section_about">
              <div className="contact-stuff_about pt-05">
                <div className="contact_icon_about icon_respon_about mr-30">
                  <div className="bd-about-text-btn ">
                    <a
                      href={pdf1}
                      download
                      className="theme-btn mr-15 three-button-respon"
                      style={{
                        paddingRight: "25px",
                        paddingLeft: "25px",
                        backgroundColor: "rgb(255, 191, 30)",
                      }}
                    >
                      <i
                        style={{
                          lineHeight: "4",
                          fontStyle: "normal",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ fontSize: "20px", paddingRight: "10px" }}
                        />
                        Company Profile
                      </i>
                    </a>
                  </div>
                </div>
                <div className="contact_icon_about icon_respon_about">
                  <div className="bd-about-text-btn">
                    <button
                      className="theme-btn mr-15 "
                      data-bs-toggle="modal"
                      data-bs-target="#portfolioModal2"
                      style={{
                        paddingRight: "25px",
                        paddingLeft: "25px",
                        backgroundColor: "rgb(255, 191, 30)",
                      }}
                    >
                      <i
                        style={{
                          lineHeight: "4",
                          fontStyle: "normal",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ fontSize: "20px", paddingRight: "10px" }}
                        />
                        Our Portfolio
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="portfolioModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel3"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content" style={{ width: "120%" }}>
                <div className="modal-header" style={{ border: "none" }}>
                  <h5 className="modal-title" id="exampleModalLabel3">
                    Fill out the Details!
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ marginLeft: "50px" }}
                  ></button>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", paddingRight: "25px" }}>
                    {/* {isLoading ? (<Spinner/>) : ()} */}
                    <form onSubmit={onHandleSubmit}>
                      <div className="row" style={{ paddingTop: "23px" }}>
                        <div className="bd-contact-field mb-20">
                          <input
                            className="blog-form"
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            style={{}}
                            placeholder="Name"
                            name="user_name"
                            required
                          />
                        </div>
                        <div className="bd-contact-field mb-20">
                          <input
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            name="user_email"
                            style={{}}
                            required
                          />
                        </div>
                        <div className="bd-contact-field mb-20 ">
                          <input
                            type="text"
                            placeholder="Contact No"
                            maxLength={10}
                            onKeyPress={(event) => {
                              if (!/[0-9.]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => setMobile(e.target.value)}
                            name="user_contact"
                            style={{}}
                            required
                          />
                        </div>
                        <div className="col-12 mb-20">
                          <div className="bd-contact-field">
                            <button type="submit" className="theme-btn">
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="hello">
                    <img
                      src="https://img.zithas.co.uk/portfolio-side_y0lpqc.avif"
                      alt="img not found"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-choose-area fix pt-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="bd-choose-shape pb-100">
            <div className="row">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 third-section1-resp">
                <div className="bd-choose-img z-index mt-70">
                  <div className="bd-choose-info-wrapper">
                    <div className="container">
                      <div className="row mb-50">
                        <div className="col mr-50">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/Reach-business-goal_bjvnvv.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2 className="bd-choose-info-title mb-15">
                                Reach Business Goal
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/Vision-into-reality_b1sly0.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2 className="bd-choose-info-title mb-15">
                                Vision into Reality
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row ml-15">
                      <div className="col mr-30">
                        <div className="animate-hover">
                          <div className="bd-choose-info-icon mb-15">
                            <i>
                              <img
                                src="https://img.zithas.co.uk/Market-awareness_tx7sdj.webp"
                                alt="img not found"
                              />
                            </i>
                          </div>
                          <div className="bd-choose-info-text">
                            <h2 className="bd-choose-info-title mb-15">
                              Market Awareness
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="animate-hover">
                          <div className="bd-choose-info-icon mb-15">
                            <i>
                              <img
                                src="https://img.zithas.co.uk/Highest-Potentials_nngauj.webp"
                                alt="img not found"
                              />
                            </i>
                          </div>
                          <div className="bd-choose-info-text">
                            <h2 className="bd-choose-info-title mb-15">
                              Highest Potentials
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 third-section2-resp">
                <div className="bd-choose-text z-index">
                  <h2 className="bd-section-title third-section-tittle">
                    Fueling Growth: Mission
                  </h2>
                  <div className="bd-section-title-wrapper">
                    <p className="third-para">
                      Zithas is a leading website design company in Bristol,
                      England, UK, committed to providing high-quality,
                      innovative, and reliable IT solutions. Our vision is to be
                      a leading provider in the industry, and our mission is to
                      empower clients to achieve their business goals through
                      effective communication and cutting-edge technology.
                    </p>
                    <h4 className=" third-para ">
                      Assured quality and effective communication are the
                      pillars we abide to!
                    </h4>
                    <p className=" third-para">
                      {" "}
                      We believe that quality and communication are the pillars
                      of our success, and we work closely with clients to
                      understand their unique needs and tailor our services to
                      fit those needs. We have the best dedicated resources to
                      help clients achieve their goals and reach new heights of
                      success.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-skill-area">
        <div className="bd-choose-shape">
          <div
            className="container bd-flip_respon"
            style={{ maxWidth: "1270px" }}
          >
            <div className="row">
              <div className="col-lg-6 fourth-about-section1">
                <div className="bd-section-title-wrapper mb-30">
                  <h3
                    className="bd-section-title mb-10"
                    style={{ fontSize: "46px" }}
                  >
                    Industries Covered
                  </h3>
                  <p>
                    Being the top software development company in England, UK,
                    our software services are designed to meet the unique needs
                    of businesses operating in different industries throughout
                    the United Kingdom. Our team of experienced professionals is
                    committed to providing our clients with the best possible
                    service and support to help them achieve their goals and
                    succeed in their respective markets. We are proud to have a
                    strong presence in the four primary nations of the UK,
                    Wales, Scotland, Northern Ireland and England.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 fourth-about-section2 pl-50">
                <div className="container_new mb-30">
                  <div
                    className="pr-20 main-size-abt"
                    style={{ color: "black", width: "33.33%" }}
                  >
                    <div className="box_flip">
                      <img
                        src="https://img.zithas.co.uk/electric-bike_jdxxw1.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                      <img
                        src="https://img.zithas.co.uk/electric-bike_jdxxw1.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                    </div>
                  </div>
                  <div
                    className="pr-20 main-size-abt"
                    style={{ color: "black", width: "33.33%" }}
                  >
                    <div className="box_flip" style={{}}>
                      <img
                        src="https://img.zithas.co.uk/health-care_wrxlcj.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                      <img
                        src="https://img.zithas.co.uk/health-care_wrxlcj.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                    </div>
                  </div>
                  <div
                    className="pr-20 main-size-abt"
                    style={{ color: "black", width: "33.33%" }}
                  >
                    <div className="box_flip" style={{}}>
                      <img
                        src="https://img.zithas.co.uk/logistics_a3vcz6.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                      <img
                        src="https://img.zithas.co.uk/logistics_a3vcz6.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="container_new2">
                  <div
                    className="pr-20 main-size-abt"
                    style={{ color: "black", width: "33.33%" }}
                  >
                    <div className="box_flip" style={{}}>
                      <img
                        src="https://img.zithas.co.uk/security-tech_i5uxsj.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                      <img
                        src="https://img.zithas.co.uk/security-tech_i5uxsj.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                    </div>
                  </div>
                  <div
                    className="pr-20 main-size-abt"
                    style={{ color: "black", width: "33.33%" }}
                  >
                    <div className="box_flip" style={{}}>
                      <img
                        src="https://img.zithas.co.uk/travel-visa_lb4pqk.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                      <img
                        src="https://img.zithas.co.uk/travel-visa_lb4pqk.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                    </div>
                  </div>
                  <div
                    className="pr-20 main-size-abt"
                    style={{ color: "black", width: "33.33%" }}
                  >
                    <div className="box_flip" style={{}}>
                      <img
                        src="https://img.zithas.co.uk/many-more_uoh2hi.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                      <img
                        src="https://img.zithas.co.uk/many-more_uoh2hi.avif"
                        className="bd-responsive-img_abt"
                        alt="img not found"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-area">
        <div className="bd-custom-container pt-80 pb-80">
          <div className="bd-about-four-bg pb-0 fix">
            <div className="bd-about-shape-box">
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8 fifth-about-section1">
                    <div className="bd-about-img mb-30">
                      <div className="comments-box">
                        <div className="comments-avatar mt-80">
                          <img
                            src="https://img.zithas.co.uk/julius-intro_nigpl1.avif"
                            className="img-fluid"
                            style={{ borderRadius: "50%", width: "500px" }}
                            alt="img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 pl-30 fifth-about-section2">
                    <div className="bd-about-text bd-about-text-four z-index">
                      <div className="bd-section-title-wrapper">
                        <h1 className="bd-slider-five-title wow fadeInUp fifth-about-tittle">
                          Providing the Right business solution to You from
                          Bristol, England
                        </h1>
                        <p className="mb-10 wow fadeInUp" data-wow-delay=".8s">
                          Zithas was born as a result of these two guys wanting
                          to help businesses across United Kingdom perform
                          efficiently. Therefore, they set out to provide
                          top-notch service and delivery to their clients. This
                          is what sets them apart from other competitions in the
                          market. They are committed to providing excellence and
                          will not settle for anything less than that.{" "}
                        </p>
                        <p className="mb-10 wow fadeInUp" data-wow-delay=".8s">
                          Zithas was born as a result of these two guys wanting
                          to help businesses across United Kingdom perform
                          efficiently. Therefore, they set out to provide
                          top-notch service and delivery to their clients. This
                          is what sets them apart from other competitions in the
                          market. They are committed to providing excellence and
                          will not settle for anything less than that.
                        </p>
                        <p className=" wow fadeInUp" data-wow-delay=".8s">
                          For us, Zithas is not just business, it is an
                          opportunity to learn, develop and achieve. It keeps us
                          motivated and inspired to reach the top and be the
                          best!{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-portfolio-area bd-portfolio-spacing  pb-70">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <div className="col-12">
              <div className="bd-section-title-wrapper text-center mb-30">
                <h5 className="bd-section-subtitle mb-15 subtitle-without-border">
                  Visionary Team
                </h5>
                <h2 className="bd-section-title">Pillars of Zithas</h2>
                <p>
                  We're a team of professionals who maintain and lead the
                  company with their immense efforts and great ideas.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-30">
            {team.slice(0, 3).map((img) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <div className="bd-portfolio mb-30">
                    <img src={img.link} alt="Portfolio not found" />
                    <div className="bd-portfolio-text">
                      <h5 className="bd-team-four-title">{img.name}</h5>
                      <span className="pb-15">{img.designation}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="bd-header-btn2 responsive-button ">
            <Link to={"/pillars-of-zithas"}>
              {team.length > 3 ? (
                <button className="get-start">View All</button>
              ) : null}
            </Link>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for business growth?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly achieve great results, Let’s get started right
                  now!
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
