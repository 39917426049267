import React from 'react'
import '../Styles/Brand.css'
import '../assets/css/swiper-bundle.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const BrandArea = () => {

  var setting2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 300,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>

      <div className="bd-brand-area gray-bg ">
        <div className="container" style={{ maxWidth: "2000px", height: "250px", backgroundColor: "var(--clr-theme-1)" }}>
          <div className="bd-brand-active swiper-container">
            <div className="slider" style={{ paddingTop: "75px" }}>
              <Slider {...setting2}>
                <span className="bd-brand-single  swiper-slide">
                  <img  src="https://img.zithas.co.uk/ai-logo_vjlwml.webp"  alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/php-logo_semmfk.webp"  alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/data-analysis-logo_rjt57l.webp"  alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/dotnet-logo_mrympk.webp"  alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/python-logo_e7rj7i.webp"  alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/node-logo_fqkhux.webp"  alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/power-bi-logo_w7f9qo.webp" alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/java-script-logo_czwrzo.webp" alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/aws-logo_d7iggm.webp" alt="brand img not found" />
                </span>
                <span className="bd-brand-single swiper-slide">
                  <img  src="https://img.zithas.co.uk/react-logo_ycdktn.webp" alt="brand img not found" />
                </span>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
