import React from "react";
import "../Styles/Choose.css";
import "../assets/css/flaticon.css";
import "../assets/css/custom-animation.css";
import { Link } from "react-router-dom";

const Choose = () => {
  return (
    <div>
      <section className="bd-choose-area fix">
        <div className="container">
          <div className="bd-choose-shape choose-main-responsive">
            <div className="row">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1">
                <div className="bd-choose-text mb-30 z-index">
                  <div className="bd-section-title-wrapper mb-35">
                    <p className="bd-section-subtitle first-tittle-respon">
                      We Believe In{" "}
                    </p>
                    <h2 className="bd-section-title mb-25">
                      You <span style={{ color: "#ffbf1e" }}>Grow</span> We{" "}
                      <span style={{ color: "#ffbf1e" }}>Grow</span>{" "}
                    </h2>
                  </div>
                  <div className="bd-choose-info-wrapper">
                    <div className="bd-choose-info mb-30">
                      <div className="bd-choose-info-icon">
                        <i>
                          <img
                            className="icon"
                            src="https://img.zithas.co.uk/one-stop-solution_obs0zi.avif"
                            style={{ maxWidth: "none" }}
                            alt="img not found"
                          />
                        </i>
                      </div>
                      <div className="bd-choose-info-text">
                        <h2 className="bd-choose-info-title mb-15">
                          Transform
                        </h2>
                        <p className="m-0">
                          Zithas offers a profitable solution through audits and
                          analysis, utilizing{" "}
                          <Link
                            to="/software-development"
                            style={{ fontWeight: "500" }}
                          >
                            {" "}
                            software development
                          </Link>{" "}
                          expertise.
                        </p>
                      </div>
                    </div>
                    <div className="bd-choose-info mb-30">
                      <div className="bd-choose-info-icon">
                        <i>
                          <img
                            src="https://img.zithas.co.uk/Reliable-team_rsvn4b.avif"
                            alt="img not found"
                            style={{ maxWidth: "none" }}
                          />
                        </i>
                      </div>
                      <div className="bd-choose-info-text ">
                        <h2 className="bd-choose-info-title mb-15">Evolve</h2>
                        <p className="m-0">
                          Tailored development plan addressing business needs
                          for growth and success.
                        </p>
                      </div>
                    </div>
                    <div className="bd-choose-info mb-30">
                      <div className="bd-choose-info-icon">
                        <i>
                          <img
                            src="https://img.zithas.co.uk/Robust-development_wkgocg.avif"
                            style={{ maxWidth: "none" }}
                            alt="img not found"
                          />
                        </i>
                      </div>
                      <div className="bd-choose-info-text ">
                        <h2 className="bd-choose-info-title mb-15">
                          Robust Development Process
                        </h2>
                        <p className="m-0">
                          Effective project management with skilled PMs and
                          developers.
                        </p>
                      </div>
                    </div>
                    <div className="bd-choose-info ">
                      <div className="bd-choose-info-icon">
                        <i>
                          <img
                            src="https://img.zithas.co.uk/workforce-64_kfn3zw.webp"
                            alt="img not found"
                            style={{ maxWidth: "none" }}
                          />
                        </i>
                      </div>
                      <div className="bd-choose-info-text ">
                        <h2 className="bd-choose-info-title mb-15">Support</h2>
                        <p className="m-0">
                          Available experts to improve productivity and ensure
                          project success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                <div className="bd-choose-img text-end mb-30 z-index">
                  <div className="row align-items-center img_choose-responsive custom-mar-15">
                    <img
                      src="https://img.zithas.co.uk/growth-expansion-home_spicrp.webp"
                      alt="img not found"
                    ></img>
                  </div>
                  <div className="bd-choose-video-popup"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Choose;
