import React, { useState, useEffect } from "react";
import Header1 from "../seperate Components/Header1";
import axios from "axios";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { ServiceBanner } from "../seperate Components/ServiceBanner";
import Footer from "../Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const MainCaseStudy = () => {
  const [casestudies, setCaseStudies] = useState([]);
  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          "https://mars.zithas.com/api/casestudies_uk"
        );
        setCaseStudies(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Web and Software Development Case Study | Zithas Technologies
        </title>
        <meta
          name="description"
          content="Let's see how we are helping our clients for achieving their business goals. We help them to automate their business through our best web services."
        />
        <meta
          name="keywords"
          content="web and software development case study"
        />
      </Helmet>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg_career detail-responsive-tittle pt-175 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2 className="breadcrumb-title1 detail-main-tittle">
                  Case Studies
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      How We Help Your Business to get Automised and Succeed!
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-portfolio-area bd-portfolio-spacing pt-100 pb-70">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row mb-30">
            {casestudies
              .slice(0, 100)
              .reverse()
              .map((cases) => {
                return (
                  <div className="col-lg-4 col-md-6">
                    <Link
                      to={`/case-study/${cases.permalink}`}
                      onClick={toggleContact}
                    >
                      <div className="bd-portfolio mb-30">
                        <img src={cases.main_image} alt="Portfolio not found" />
                        <div className="bd-portfolio-text">
                          <span>{cases.service}</span>
                          <h5 className="bd-portfolio-title">
                            {cases.company_name}
                          </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for Business Automation Expert?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great results achieved, Let's get started right
                  now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MainCaseStudy;
