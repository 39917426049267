import React, { useEffect, useState } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { ServiceBanner } from "../../Components/seperate Components/ServiceBanner";
import Footer from "../../Components/Footer";
import axios from "axios";
import Spinner from "../../Components/seperate Components/Spinner";

const CurrentOPening = () => {
  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isLoading, setLoading] = useState(true);
  const [posts, setPosts] = useState({});
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          "https://mars.zithas.com/api/currentopening"
        );
        setPosts(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);
  return (
    <div>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg_career detail-responsive-tittle pt-175 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2 className="breadcrumb-title1 detail-main-tittle">
                  Current Openings
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs"></nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-pricing-area pt-80 pb-80">
        <div className="container" style={{ maxWidth: "1285px" }}>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <div className="row">
                  {posts.map((jobs) => {
                    return (
                      <div className="col-lg-4 col-md-6">
                        <div className="bd-pricing mb-30">
                          <div key={jobs.id}>
                            <div className="bd-pricing-title-wrapper text-center mb-65">
                              <h4 className="bd-pricing-subtitle mb-15">
                                {jobs.position_name}
                              </h4>
                            </div>
                            <ul className="mb-80">
                              <li className="open-text">
                                <i>
                                  <FontAwesomeIcon icon={faAngleRight} />
                                </i>
                                <span
                                  style={{ color: "black", fontWeight: "450" }}
                                >
                                  {" "}
                                  Vacancies{" "}
                                </span>{" "}
                                : {jobs.vacancies}
                              </li>
                              <li className="open-text ">
                                <i>
                                  <FontAwesomeIcon icon={faAngleRight} />
                                </i>
                                <span
                                  className="mb-10"
                                  style={{ color: "black", fontWeight: "450" }}
                                >
                                  Preferred Skills{" "}
                                </span>{" "}
                                <span> :{jobs.preffered_skills}</span>
                              </li>
                            </ul>
                            <div className="bd-pricing-btn">
                              <Link
                                to={`/current-opening/${jobs.permalink}`}
                                onClick={toggleContact}
                                className="theme-btn"
                              >
                                Apply Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ServiceBanner />
      <Footer />
    </div>
  );
};

export default CurrentOPening;
