import React from 'react'
import Footer from '../Footer'
import Header1 from './Header1'
import "../../Styles/seperate Comp Css/PageNotFound.css"
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const SiteMap = () => {
    return (
        <div>
            <Header1 />
            <div className="bd-page-title-area bd-page-title-bg3 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Site Map</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="bd-about-area">
                <div className="bd-custom-container" >
                    <div className="bd-about-four-bg pb-80 fix">
                        <div className="bd-about-shape-box">
                            <div className="container" >
                                <div className="row justify-content-center " >
                                    <div className="col-xl-6 col-lg-8 pt-80" style={{ width: "100%" }} >
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/">Home</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/business-software-audit">Business software Audit</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/bespoke-systems">Bespoke System</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/business-automation">Business Automation</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/software-development">Software Development</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/mobile-app-development">Mobile Apps development</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/hire-a-resource">Dedicated Resource</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/about-us">About Us</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/contact-us">Contact Us</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/career">Career</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/testimonials">Testimonials</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/Maintenance-Packages">Maintenance Packages</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/seo-management-packages">Seo Packages</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/blog">Blog</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/privacy-policy">Privacy policy</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/digital-marketing">Digital Marketing</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/hosting-service">Hosting Services</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/micro-service">MICRO Services</a></p>
                                        <p className="site-link"><i><FontAwesomeIcon icon={faCheck} style={{ paddingRight: "20px", fontSize: "20px" }} /></i><a href="/hire-a-resource">Dedicated Resources</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default SiteMap