import React, { useState } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Components/Footer";
import ModalVideo from "react-modal-video";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Redirecting Styles/FooterStyle/DigitalMarketing.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const DigitalMarketing = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Helmet>
        <title>One of The Top Digital Marketing Companies in England, UK</title>
        <meta
          name="description"
          content="Boost your brand with the top digital marketing agency in the UK. Expert company providing service in Bristol, London, Cardiff, Wales, and within the UK."
        />

        <meta
          name="keywords"
          content="
        digital marketing company,
        digital marketing agency uk,
        digital marketing services in uk,
        digital marketing bristol,
        top digital marketing companies,
        digital marketing england, 
        digital marketing london,
        digital marketing cardiff,
        digital marketing wales,
        digital marketing uk,
        digital marketing agency"
        />
      </Helmet>

      <Header1 />

      {/* Banner section */}
      <div
        className="bd-page-title-area bd-page-title-bg2 pt-220 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="bd-page-title text-center z-index"
                style={{ bottom: "45px" }}
              >
                <h1 className="breadcrumb-title" style={{ fontSize: "50px" }}>
                  Digital Marketing
                </h1>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      Make more sales by reaching a larger audience through
                      digital marketing services from Bristol, England!
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-choose-area fix pb-70 pt-80 ">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="bd-choose-shape">
            <div className="row">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 pr-20">
                <div className="bd-choose-text z-index">
                  <h2
                    className="bd-section-title mb-15"
                    style={{ fontSize: "46px" }}
                  >
                    Attract More Web Traffic
                  </h2>
                  <div className="bd-section-title-wrapper"></div>
                  <p
                    className="mb-20"
                    style={{ width: "95%", fontSize: "18px" }}
                  >
                    Our digital marketing services, offered in Bristol, Wales,
                    Cardiff, England, and London, enable businesses to
                    effortlessly and promptly expand their audience by
                    leveraging the potential of social media platforms. This can
                    include products and services that are not possible to
                    advertise through traditional channels, like online ads or
                    print ads.
                  </p>

                  <p style={{ fontSize: "18px" }}>
                    With the help of digital marketing company, businesses can
                    connect with a wider range of potential customers who might
                    not have already visited them. By using digital marketing,
                    businesses can create an{" "}
                    <Link to="/bespoke-systems" style={{ fontWeight: "500" }}>
                      attractive website
                    </Link>
                    , goodelling potential customers on the benefits of their
                    product or service and how it would benefit their business.
                  </p>
                  <p className="" style={{ fontSize: "18px" }}>
                    A digital marketing agency is the best option to interact
                    with both potential and current customers. By giving
                    businesses helpful hints, sound advice, and interesting and
                    useful content from our office, we can facilitate the
                    development of relationships and promote business growth
                    from Bristol.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                <div className="bd-choose-img text-end  z-index">
                  <div className="row align-items-center media-respon-digital custom-mar-15">
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                        <img
                          src="https://img.zithas.co.uk/digital-marketing-1.1_zdpvvb.avif"
                          className="resp-img bordertop-img1"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15">
                        <img
                          src="https://img.zithas.co.uk/digital-marketing-1_j1ooyh.avif"
                          className="resp-img bordertop-img2"
                          alt="img not found"
                        />
                      </div>
                      <div className="bd-choose-img-inner mb-15">
                        <img
                          src="https://img.zithas.co.uk/digital-marketing-1.2_unjklr.avif"
                          className="resp-img bordertop-img3"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="bd-choose-shape bd-choose-shape-2"
                    src={Shape2}
                    alt="shape img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalVideo
          autoplay
          isOpen={isOpen}
          channel="youtube"
          videoId="L61p2uyiMSo"
          onClose={() => setOpen(false)}
        ></ModalVideo>
        <button
          style={{ display: "none" }}
          className="btn btn  first_pop_video responsive_digital"
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon
            icon={faPlay}
            className="play_bt_vid play_transition"
          />
        </button>
      </section>
      <section className="bd-service-area pb-80 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 pt-20">
              <div className="bd-section-title-wrapper mb-50">
                <h5 className="bd-section-subtitle mb-15 subtitle-without-border pl-5">
                  Hyper-Targeted
                </h5>
                <h2 className="bd-section-title" style={{ fontSize: "46px" }}>
                  <span style={{ color: "#ffbf1e" }}> Digital Marketing </span>{" "}
                  Solutions for Your Business
                </h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/seo-64_v3hhgx.webp"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  Search Engine Optimization (SEO)
                </h4>
                <p>
                  SEO efforts can aid in increasing brand visibility, increasing
                  organic traffic, and establishing a more competitive online
                  presence.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/pay-per-click-64_gpey4h.webp"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">Pay-per-Click (PPC)</h4>
                <p>
                  By monitoring the number of clicks the link receives, you can
                  boost your search engine rating rapidly!
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/social-media-marketing-64_snppok.webp"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">
                  Social Media Marketing
                </h4>
                <p>
                  Our targeting tools can help you reach the right audience
                  through Facebook/Instagram ads aimed at your target
                  demographics.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/content-marketing-64_wiaypo.webp"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">Content Marketing</h4>
                <p>
                  Engage with the audience and provide meaningful responses to
                  their questions! It can also help with customer acquisition
                  and sales closing.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <div className="bd-service-icon mb-20">
                  <i>
                    <img
                      src="https://img.zithas.co.uk/marketing-64_rd3g9z.webp"
                      alt="img not found"
                    />
                  </i>
                </div>
                <h4 className="bd-service-title mb-20">Marketing Analytics</h4>
                <p>
                  Engage with the audience and provide meaningful responses to
                  their questions! It can also help with customer acquisition
                  and sales closing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for Digital Marketing Expert?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great Digital Marketing, Let's get started right
                  now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default DigitalMarketing;
