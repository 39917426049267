import React, { useState, useEffect } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import Footer from "../../Components/Footer";
import { Outlet, useParams } from "react-router-dom";
import axios from "axios";
import CompanyBlogs from "../../Components/seperate Components/CompanyBlogs";
import Pagination from "../../Components/seperate Components/Pagination";
import "../../Styles/Redirecting Styles/FooterStyle/BlogDetails.css";
import { Helmet } from "react-helmet";

const Blog = ({ comments }) => {
  const params = useParams();
  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(9);

  useEffect(() => {
    axios
      .get("https://mars.zithas.com/api/zithasukblog")
      .then((res) => {
        setPosts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);

  // Get Current Post for

  const indexOfLastPage = currentPage * postPerPage;
  const indexOfFirstPage = indexOfLastPage - postPerPage;
  const currentPosts = posts.slice(indexOfFirstPage, indexOfLastPage);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Helmet>
        <title>
          Blog on Software and Mobile App Development | Zithas Technologies
        </title>
        <meta
          name="description"
          content="Stay tuned with us to read our latest blog on all topics like bespoke system, business automation, software and app development, and many more."
        />

        <meta
          name="keywords"
          content="
                software and app development blog,
                business automation,
                bespoke system
                "
        />
      </Helmet>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg_blog pt-175 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Blog
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      Unlocking growth potential: Learn how software
                      development, bespoke systems, and business automation
                      drive business success.
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompanyBlogs
        posts={currentPosts}
        isLoading={isLoading}
        comments={comments}
      />
      <Pagination
        postPerPage={postPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      />
      <Footer />
      <Outlet />
    </div>
  );
};

export default Blog;
