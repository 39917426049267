import React, { useState } from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import Shape2 from "../../assets/brand/choose-shape-2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePen, faPlay, faPhone } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../Components/Footer';
import ModalVideo from 'react-modal-video';
import "../../Styles/Redirecting Styles/FooterStyle/Hosting.css"
import { Helmet } from 'react-helmet';

const HostingServices = () => {

    const [isOpen, setOpen] = useState(false)

    return (
        <div>
            <Helmet>
                <title>Best Hosting Service Provider in England, UK | Zithas Technologies</title>
                <meta name='description' content='As an experienced company with millions of clients, Zithas Technologies provides a high-quality web hosting service at a very reasonable price.' />

                <meta name="keywords" content="
                web hosting services uk,
                hosting services uk,
                hosting services england, 
                best hosting provider uk" />
            </Helmet>
            <Header1 />
            <div className="bd-page-title-area bd-page-title-bg2 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h1 className="breadcrumb-title" style={{ fontSize: '50px' }}>Hosting Services</h1>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>Improve Your Business Hosting Options with Perfectly Fit Hosting Services</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bd-choose-area fix pt-80 pb-80">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="bd-choose-shape">
                        <div className="row respon_host">

                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 pr-20">
                                <div className="bd-choose-img text-end top-resp z-index">
                                    <div className="row align-items-center custom-mar-15">
                                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                            <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                                                <img src="https://img.zithas.co.uk/hosting-section-1.1_ovqb6g.avif" className="resp-img bordertop-img1" alt="img not found" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                            <div className="bd-choose-img-inner mb-15">
                                                <img src="https://img.zithas.co.uk/hosting-section-1_pq2zxp.avif" className="resp-img bordertop-img2" alt="img not found" />
                                            </div>
                                            <div className="bd-choose-img-inner mb-15">
                                                <img src="https://img.zithas.co.uk/hosting-section-1.3_a30qnz.avif" className="resp-img bordertop-img3" alt="img not found" />
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        className="bd-choose-shape bd-choose-shape-2"
                                        src={Shape2}
                                        alt="shape img not found"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                                <div className="bd-choose-text  z-index">
                                    <h2 className="bd-section-title mb-15" style={{ fontSize: "46px" }}>
                                        Best <span style={{ color: "#ffbf1e" }}> Hosting Services </span> for Your Business in Bristol, England
                                    </h2>
                                    <div className="bd-section-title-wrapper">
                                    </div>
                                    <p
                                        className="mb-20"
                                        style={{ width: "95%", fontSize: "18px" }}
                                    >In today's digital age, having a reliable hosting service is essential for any business. That's why we offer a range of hosting services that are tailored to meet the specific needs of your business. Our team of experts are dedicated to providing you with the best possible hosting experience.
                                    </p>
                                    <p className="mb-20" style={{ fontSize: "18px" }}>We offer a variety of hosting options, including shared, VPS, and dedicated hosting. Our shared hosting packages are ideal for small businesses, while our VPS and dedicated hosting options are perfect for larger companies with more demanding requirements. Our hosting services include site tuning, to ensure that your website runs as quickly and efficiently as possible. We also provide security features to protect your website from hackers and viruses. Additionally, we offer backup features to ensure that your website is always safe and secure. With our hosting services, you can rest assured that your website will be up and running like new, without all the hassle.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='L61p2uyiMSo' onClose={() => setOpen(false)}>
                </ModalVideo>
                <button style={{ display: "none" }} className="btn btn touch_pop_vid first_pop_hosting" onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
                </button>
            </section>

            <section className="bd-service-area pb-80 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 pt-20">
                            <div className="bd-section-title-wrapper mb-50">
                                <h2 className="bd-section-title" style={{ fontSize: "46px" }}>Choose the Rightansom <span style={{ color: "#ffbf1e" }}>Hosting Services</span> for your business needs</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://img.zithas.co.uk/download-from-cloud-64_c7fiis.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Cloud Hosting</h4>
                                <p>Separates your servers so that you can focus on the task at hand, without worry of any potential stress affecting either one.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://img.zithas.co.uk/server-shutdown-64_ucrhai.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Dedicated Server</h4>
                                <p>Separates your servers so that you can focus on the task at hand, without worry of any potential stress affecting either one.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://img.zithas.co.uk/vps-64_t7ncnp.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">VPS Hosting</h4>
                                <p>Separates your servers so that you can focus on the task at hand, without worry of any potential stress affecting either one.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://img.zithas.co.uk/web-hosting-64_jhgubp.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Web Hosting</h4>
                                <p>Separates your servers so that you can focus on the task at hand, without worry of any potential stress affecting either one.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://img.zithas.co.uk/mail-64_mp640k.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Email Hosting</h4>
                                <p>Tired of your free web-mail? Try our email service that operates servers to keep you organized and connected!</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://img.zithas.co.uk/security-ssl-64_qifkb1.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">SSL Certificates</h4>
                                <p>Secure your website with an SSL certificate today! This will help you protect it from hackers and boost its SEO ranking.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://img.zithas.co.uk/web-shield-64_a4hhom.avif" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Site Security & BackUp</h4>
                                <p>Protect your website from potential threats with regular site and security backups to safeguard against data breaches and loss</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://img.zithas.co.uk/wordpress-64_sbcrgv.webp"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">WordPress Hosting</h4>
                                <p>Our WordPress hosting platform is SEO-ready, offering optimization tools and resources along with fast and reliable hosting.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA */}
            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for Hosting Service Expert?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great Hosting Service, Let's get started right now !</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(0) 776-742-6142" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(0) 776-742-6142</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default HostingServices

