import React, { useState } from 'react'
import { faMemory, faMicrochip, faSimCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPhone, faPlay } from '@fortawesome/free-solid-svg-icons'
import "../../Styles/Redirecting Styles/NavbarStyles/Software.css";
import Shape2 from "../../assets/brand/choose-shape-2.png";
// import shape6 from "../assets/brand/choose-shape-2.png"
import ModalVideo from 'react-modal-video';
// import 'react-modal-video/scss/modal-video.scss'
import Header1 from "../../Components/seperate Components/Header1";
import Footer from "../../Components/Footer";

import python from "../../assets/logo/python.png";
import magento from "../../assets/logo/magento.png";
import drupal from "../../assets/logo/drupal.png";
import joomla from "../../assets/logo/joomla.png";
import shopify from "../../assets/logo/shopify.png";
import woocommerce from "../../assets/logo/woocommerce.png";
import wordpress from "../../assets/logo/wordpress.png";
import css from "../../assets/logo/css-3.png";
import { Helmet } from 'react-helmet';
const WebsiteDevelopment = () => {

    const [isOpen, setOpen] = useState(false)

    return (
        <div>
            <Helmet>
                <title>Expert Website Design and Development Company in the UK</title>
                <meta name='description' content='Discover the best website design and development company in Bristol, London, England, or Wales. We specialize in building an ecommerce website within the UK.' />
            </Helmet>

            <Header1 />

            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg3 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "50px" }}>
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>
                                    Website Development Expert in UK
                                </h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>
                                            Elevating Your Online Presence Through Innovative Web Design and Development.
                                        </p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* First Section */}
            <section className="bd-about-area">
                <div className="bd-custom-container" >
                    <div className="bd-about-four-bg  pt-80 pb-70 fix">
                        <div className="bd-about-shape-box">
                            <img className="bd-about-shape bd-about-shape-1" src="assets/img/shape/about-shape-3.png" alt="shape img not found" />
                            <img className="bd-about-shape bd-about-shape-2" src="assets/img/shape/about-shape-4.png" alt="shape img not found" />
                            <div className="container" style={{ maxWidth: "1270px" }}>
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-xl-6 col-lg-8 software-first-section1">
                                        <div className="bd-choose-img text-end  main-section-software z-index">
                                            <div className="row align-items-center custom-mar-15">
                                                <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                                    <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                                                        <img
                                                            src="https://img.zithas.co.uk/software-dev-m-l_h6lnnm.avif"
                                                            className="bordertop-img1"
                                                            alt="img not found"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                                                    <div className="bd-choose-img-inner mb-15">
                                                        <img
                                                            src="https://img.zithas.co.uk/software-dev-section-1_dousml.avif"
                                                            className="bordertop-img2"
                                                            alt="img not found"
                                                        />
                                                    </div>
                                                    <div className="bd-choose-img-inner mb-15">
                                                        <img
                                                            src="https://img.zithas.co.uk/software-development-section1.3_vhobmo.avif"
                                                            className="bordertop-img3"
                                                            alt="img not found"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                className="bd-choose-shape bd-choose-shape-2"
                                                src={Shape2}
                                                alt="shape img not found"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8 software-first-section2">
                                        <div className="bd-about-text bd-about-text-four z-index">
                                            <div className="bd-section-title-wrapper">
                                                <h2 className="bd-section-title mb-15 first_section-title md-pr-50">Why do you need a website? </h2>
                                                <p className='mt-4'>
                                                    Why do you need a website in the UK? In a digital age where business knows no borders, having a strong online presence is crucial. Whether you're in bustling Bristol, vibrant London, or anywhere across England and Wales, a website acts as your digital storefront, accessible to potential customers 24/7. Your UK-based business deserves a website that not only connects you with your community but also propels you towards global success.
                                                </p>
                                                {/* <p>
                                                    In the realm of business, software holds a pivotal role, fostering task automation and elevating efficiency levels. The automation of various tasks translates to significant time and resource savings for businesses. Furthermore, for businesses based in Canada, software stands as a cornerstone in enhancing the customer experience and fostering productivity by reducing stress and facilitating sound decision-making.
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ModalVideo autoplay isOpen={isOpen} channel='youtube' videoId='6tZI6yQowmk' onClose={() => setOpen(false)}>
                </ModalVideo>
                <button className="btn btn touch_pop_vid first_pop_video_software" onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={faPlay} className="play_bt_vid play_transition" />
                </button> */}
            </section>

            {/* Second Section */}
            <section className="bd-choose-area fix">
                <div className="container" style={{ maxWidth: "1300px" }}>
                    <div className="bd-choose-shape pb-70" >
                        <div className="row responsive-second-part align-items-center   ">

                            <div className="col-xl-6 col-lg-6 order-2 order-lg-1 second-responsive-software1">
                                {/* <div className="ablog ablog-4 mb-60"> */}

                                <div className="bd-choose-text  z-index">
                                    <h2 className="bd-section-title ml-15 software-responsive-tittle">
                                        We provide high-quality <span style={{ color: "#ffbf1e" }}>Web Design and Development </span> Services </h2>
                                    <div className="bd-section-title-wrapper">

                                        <p className="ml-25 para-software">
                                            Crafting exceptional digital experiences is our specialty. Our team, based in the UK, is dedicated to delivering top-tier web design and development services that stand out. From eye-catching visuals to seamless functionality, we create websites that not only impress but also perform. Elevate your online presence with our expertise; your digital success begins here.
                                        </p>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 second-responsive-software2">
                                <div className="bd-choose-img mb-30 z-index pt-30">
                                    <div className="bd-choose-info-wrapper">
                                        <div className="container">
                                            <div className="row mb-50">
                                                <div className="col mr-50">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://img.zithas.co.uk/transaction-64_gjs6ug.avif" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Distinctive Design  </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://img.zithas.co.uk/unique_lufjti.webp" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Enhanced Pages  </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row ">
                                                <div className="col mr-25">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i>
                                                                <img src="https://img.zithas.co.uk/business-process-64_o09iuu.webp" />
                                                            </i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Easy to Navigate  </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="animate-hover">
                                                        <div className="bd-choose-info-icon mb-15">
                                                            <i><img src="https://img.zithas.co.uk/cost-64_vtijdz.webp" /></i>
                                                        </div>
                                                        <div className="bd-choose-info-text">
                                                            <h2 className="bd-choose-info-title mb-15" style={{ color: "#5e5858" }}>Web Integration  </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Third Section */}
            <section className="bd-video-area pt-105 pb-120" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <p className="contact_section">
                                    Your timing, Your Success.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff">
                                <div className="contact_icon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* sixth Section */}
            <section className="bd-service-area pb-70 pt-70 ">
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row"></div>
                    <div className="row sixth-main-responsive">
                        <h2 className="mb-30 sixth-section-soft">
                            Elevate your dreams with our extensive web development services.
                        </h2>
                        <div className="col-lg-4 col-md-6 sixth-soft-section1">
                            <div
                                className="bd-service mb-30"
                                style={{ backgroundColor: "var(--clr-bg-gray)" }}
                            >
                                <h4 class="bd-service-title mb-20"><a>Custom Web Development</a></h4>
                                <p>
                                    Our skilled in-house team utilizes cutting-edge technologies to craft bespoke web applications that combine aesthetic excellence with robust security and adaptability. Following agile development principles, we ensure flexibility and swift project delivery. Serving Bristol, London, England, and Wales, we're committed to exceeding your expectations.
                                </p>
                                <table style={{ marginLeft: "50px" }}>
                                    <tbody>
                                        <tr style={{ marginBottom: "20px" }}>
                                            <td className="pb-30" style={{ width: "180px" }}>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/filezilla_t4bxqw.png" /> */}
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/php_s2e2xk.png" />
                                            </td>
                                            <td className="pb-30" style={{ width: "180px" }}>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/visual-studio-code_ouivep.png" /> */}
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/nodejs_lab0zs.png" />
                                            </td>
                                            <td className="pb-30">
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/windows-10_kl8cu2.png" /> */}
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/dot-net_rqknrj.png" />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/linux-server_p6azlz.png" />
                                            </td>
                                            <td>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/xcode_hxtmqb.png" /> */}
                                                <img src={python} style={{ width: "64px" }} />
                                            </td>
                                            <td>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616468/website/icons/postman_bshp0x.png" /> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 sixth-soft-section1">
                            <div
                                className="bd-service mb-30"
                                style={{ backgroundColor: "var(--clr-bg-gray)" }}
                            >
                                <h4 class="bd-service-title mb-20"><a>CMS (Content Management System)</a></h4>
                                <p>
                                    We excel in creating bespoke content management systems designed for the web. Through thorough market research and alignment with your specific business requirements, we shape the perfect CMS framework to drive your business to new heights.
                                </p>
                                <table style={{ marginLeft: "50px" }}>
                                    <tbody>
                                        <tr style={{ marginBottom: "20px" }}>
                                            <td className="pb-30" style={{ width: "180px" }}>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/dot-net_rqknrj.png" /> */}
                                                <img src={wordpress} style={{ width: "64px" }} />
                                            </td>
                                            <td className="pb-30" style={{ width: "180px" }}>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/angularjs_luxe11.png" /> */}
                                                <img src={joomla} style={{ width: "64px" }} />
                                            </td>
                                            <td className="pb-30">
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/nodejs_lab0zs.png" /> */}
                                                <img src={drupal} style={{ width: "64px" }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/flutter_p8yphu.png" /> */}
                                                <img src={css} style={{ width: "64px" }} />
                                            </td>
                                            <td>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/php_s2e2xk.png" /> */}
                                            </td>
                                            <td>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/react_hmd8zn.png" /> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 sixth-soft-section1">
                            <div
                                className="bd-service mb-30"
                                style={{ backgroundColor: "var(--clr-bg-gray)" }}
                            >
                                <h4 class="bd-service-title mb-20"><a>Ecommerce</a></h4>
                                <p>
                                    We're experts at designing unique eCommerce websites that distinguish you in a competitive market. Through eCommerce, you can streamline sales, provide tailored shopping experiences, and enhance your profitability.
                                </p>
                                <table style={{ marginLeft: "50px" }}>
                                    <tbody>
                                        <tr style={{ marginBottom: "20px" }}>
                                            <td className="pb-30" style={{ width: "180px" }}>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/dot-net_rqknrj.png" /> */}
                                                <img src={magento} style={{ width: "64px" }} />
                                            </td>
                                            <td className="pb-30" style={{ width: "180px" }}>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/angularjs_luxe11.png" /> */}
                                                <img src={woocommerce} style={{ width: "64px" }} />
                                            </td>
                                            <td className="pb-30">
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/nodejs_lab0zs.png" /> */}
                                                <img src={shopify} style={{ width: "64px" }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/flutter_p8yphu.png" /> */}
                                            </td>
                                            <td>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/php_s2e2xk.png" /> */}
                                            </td>
                                            <td>
                                                {/* <img src="https://res.cloudinary.com/ztpl-images/image/upload/q_auto,f_auto,fl_lossy/v1659616446/website/icons/react_hmd8zn.png" /> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Banner Section */}
            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >
                                    Looking for a website(web) development expert?
                                </h2>
                                <p className="contact_section second-para-resp">
                                    You’re here at the right moment.
                                </p>
                                <p className="contact_subtext">
                                    Outstanding developers, Let’s kickstart the project.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(+91)720-288-2277" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(+91) 720-288-2277</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default WebsiteDevelopment