import React, { useState } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import {
  faMemory,
  faMicrochip,
  faSimCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPhone, faPlay } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Components/Footer";
import "../../Styles/Redirecting Styles/NavbarStyles/Software.css";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import shape6 from "../../assets/brand/choose-shape-2.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const DigitalMarket = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Helmet>
        <title>
          Top Software Development Company in England,UK | Zithas Technologies
        </title>
        <meta
          name="description"
          content="Zithas is the top software development company in Bristol, London, Cardiff, and Wales. We are the UK's best custom software solutions provider including ERP and CRM."
        />
        <meta
          name="keywords"
          content="
        software development company in Bristol,
        software development company in london,
        software development company in cardiff,
        software development company in wales,
        Software Development in Bristol,
        software development company,
        software development company uk,
        software development uk,
        top software companies,
        software development in england, 
        best software development company,
        top software company in uk
        "
        />
      </Helmet>

      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg3 pt-220 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="bd-page-title text-center z-index"
                style={{ bottom: "45px" }}
              >
                <h1
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Software Development
                </h1>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      Transform your customer experience and streamline
                      operations with our cutting-edge solution.
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-about-area">
        <div className="bd-custom-container">
          <div className="bd-about-four-bg  pt-80 pb-80 fix">
            <div className="bd-about-shape-box">
              <img
                className="bd-about-shape bd-about-shape-1"
                src="assets/img/shape/about-shape-3.png"
                alt="shape img not found"
              />
              <img
                className="bd-about-shape bd-about-shape-2"
                src="assets/img/shape/about-shape-4.png"
                alt="shape img not found"
              />
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div
                  className="row justify-content-center align-item-center"
                  style={{ alignItems: "center" }}
                >
                  <div className="col-xl-6 col-lg-8 software-first-section1">
                    <div className="bd-choose-img text-end  main-section-software z-index">
                      <div className="row align-items-center custom-mar-15">
                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                          <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                            <img
                              src="https://img.zithas.co.uk/software-dev-m-l_h6lnnm.avif"
                              className="bordertop-img1"
                              alt="img not found"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                          <div className="bd-choose-img-inner mb-15">
                            <img
                              src="https://img.zithas.co.uk/software-dev-section-1_dousml.avif"
                              className="bordertop-img2"
                              alt="img not found"
                            />
                          </div>
                          <div className="bd-choose-img-inner mb-15">
                            <img
                              src="https://img.zithas.co.uk/software-development-section1.3_vhobmo.avif"
                              className="bordertop-img3"
                              alt="img not found"
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        className="bd-choose-shape bd-choose-shape-2"
                        src={Shape2}
                        alt="shape img not found"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 software-first-section2">
                    <div className="bd-about-text bd-about-text-four z-index">
                      <div className="bd-section-title-wrapper">
                        <h2 className="bd-section-title mb-15 first_section-title md-pr-50">
                          End-to-End{" "}
                          <span style={{ color: "#ffbf1e" }}>
                            {" "}
                            Software Dev{" "}
                          </span>{" "}
                          in Bristol, England
                        </h2>
                        <p className="">
                          At our company, we specialize in designing and
                          developing software for a wide range of businesses in
                          Bristol, London, Cardiff, Wales and England. Our
                          experienced{" "}
                          <Link
                            to="/hire-a-resource"
                            style={{ fontWeight: "500" }}
                          >
                            software development team
                          </Link>{" "}
                          is dedicated to creating user-friendly and easy-to-use
                          software that can be accessed by a wide range of
                          users.
                        </p>
                        <p>
                          Our software development process prioritizes
                          user-friendliness and accessibility. We use the latest
                          technologies and design principles to create intuitive
                          software that enables quick and easy access to
                          information.
                        </p>
                        <p className="">
                          Additionally, we make sure that the software we create
                          is compatible with different devices, platforms and
                          browsers, which means it can be accessed from
                          anywhere, at any time, by anyone, regardless of their
                          technical abilities. This allows our clients to reach
                          a wider audience and provide better service to their
                          customers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-choose-area fix">
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="bd-choose-shape pb-70">
            <div className="row responsive-second-part">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1 second-responsive-software1">
                <div className="bd-choose-text  z-index">
                  <h2 className="bd-section-title ml-15 software-responsive-tittle">
                    Paradox of
                    <span style={{ color: "#ffbf1e" }}>
                      {" "}
                      Software Development{" "}
                    </span>{" "}
                    Roles.
                  </h2>
                  <div className="bd-section-title-wrapper">
                    <p className="ml-25 para-software">
                      At our company, we believe that software development roles
                      are not one-size-fits-all. There are many different
                      languages and tools that can be used in software
                      development, and each has its own strengths and
                      weaknesses. It is important to understand that the choice
                      of language and tools used in software development can
                      greatly impact the final product. It's important to choose
                      the right tools and languages for the job to deliver the
                      best results to the end-users.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2 mt-0 second-responsive-software2">
                <div className="bd-choose-img mb-30 z-index pt-30">
                  <div className="bd-choose-info-wrapper">
                    <div className="container">
                      <div className="row mb-50">
                        <div className="col-6 ">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/transaction-64_gjs6ug.avif"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Enhanced Security and Data Protection
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/unique_lufjti.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Greater Scalability and Flexibility
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row ">
                        <div className="col mr-25">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/business-process-64_o09iuu.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Real-time Data and Insights
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="animate-hover">
                            <div className="bd-choose-info-icon mb-15">
                              <i>
                                <img
                                  src="https://img.zithas.co.uk/cost-64_vtijdz.webp"
                                  alt="img not found"
                                />
                              </i>
                            </div>
                            <div className="bd-choose-info-text">
                              <h2
                                className="bd-choose-info-title mb-15"
                                style={{ color: "#5e5858" }}
                              >
                                Automation of Repetitive tasks
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-105 pb-120"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <p className="contact_section">
                  You can get it done, When you need it.
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-six-area    pt-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="bd-about-six-text">
                <div className="bd-section-title-wrapper mb-25">
                  <h2 className="bd-section-title mb-15 kick-section-tittle">
                    Kick-Off Your Projects like a Pro
                  </h2>
                </div>
                <div className="bd-about-six-feature">
                  <ul className="mb-45">
                    <li className="point-software" style={{ fontSize: "20px" }}>
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Assign Resources
                    </li>
                    <p className="soft-para">
                      Identify the resources required for the project and assign
                      them to the respective tasks.
                    </p>
                    <li className="point-software" style={{ fontSize: "20px" }}>
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Communicate and Collaborate
                    </li>
                    <p className="soft-para">
                      Establish effective communication and collaboration
                      channels to ensure smooth execution of the project.
                    </p>
                    <li className="point-software" style={{ fontSize: "20px" }}>
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      Monitor and Adjust
                    </li>
                    <p className="soft-para">
                      Regularly monitor the progress and make adjustments as
                      necessary to ensure the project stays on track.
                    </p>
                    <li className="point-software" style={{ fontSize: "20px" }}>
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      Review and Evaluate
                    </li>
                    <p className="soft-para">
                      Review and evaluate the project at the end, to identify
                      the areas of improvement for future projects
                    </p>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bd-about-six-img">
                <img
                  src="https://img.zithas.co.uk/software-development-section-2-ext-back_jvvclm.avif"
                  className="pl-60"
                  alt="img not found"
                />
                <img
                  src="https://img.zithas.co.uk/software-development-section-2-back_fgoxcl.avif"
                  className="bd-about-six-img-second"
                  alt="img not found"
                />
                <img
                  src="https://img.zithas.co.uk/software-development-section-2-main_prqdum.avif"
                  className="bd-about-six-img-third"
                  alt="img not found"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="packages-text pb-80 pt-90">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="packages-quest">
            <h1
              className="fiveth-section-soft mb-25"
              style={{ fontWeight: "700" }}
            >
              A Guide to Picking the Right{" "}
              <span style={{ color: "#ffbf1e" }}>Software Development</span>{" "}
              Company in Bristol
            </h1>
            <ul className="soft-responsive pl-20">
              <li
                className="packages-content-text extra-para pb-25"
                style={{ listStyle: "none" }}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    paddingRight: "20px",
                    color: "var(--clr-body-heading)",
                  }}
                />
                Understand Your Needs: Clearly define your business needs and
                requirements to ensure the software development company you
                choose is able to deliver what you need.
              </li>
              <li
                className="packages-content-text pb-25"
                style={{ listStyle: "none" }}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    paddingRight: "20px",
                    color: "var(--clr-body-heading)",
                  }}
                />{" "}
                Check for Expertise and Quality of Services: Look for top
                software companies that have expertise in the specific
                technologies and platforms that you need for your project. Also,
                look for a top software company in the UK like ours that offers
                services at the best rate and quality.
              </li>
              <li
                className="packages-content-text"
                style={{ listStyle: "none" }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    paddingRight: "20px",
                    color: "var(--clr-body-heading)",
                  }}
                />
                Communication and Collaboration: Choose the best software
                development company that is easy to find, responsive and willing
                to collaborate with you throughout the development process. This
                is crucial for ensuring the best result for your project.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="bd-service-area pb-70 ">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row"></div>
          <div className="row sixth-main-responsive">
            <h2 className="mb-30 sixth-section-soft">
              Efficiency-Boosting Process in Bristol
            </h2>
            <div className="col-lg-4 col-md-6 sixth-soft-section1">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <p>
                  Several tools that are frequently used in software development
                  include Git, Code Management Systems like Git Lab, and Build
                  Management Systems such as Jenkins.
                </p>
                <table style={{ marginLeft: "50px" }}>
                  <tbody>
                    <tr style={{ marginBottom: "20px" }}>
                      <td className="pb-30" style={{ width: "180px" }}>
                        <img
                          src="https://img.zithas.co.uk/filezilla_yn0i63.webp"
                          alt="img not found"
                        />
                      </td>
                      <td className="pb-30" style={{ width: "180px" }}>
                        <img
                          src="https://img.zithas.co.uk/visual-studio-code_nyobf4.webp"
                          alt="img not found"
                        />
                      </td>
                      <td className="pb-30">
                        <img
                          src="https://img.zithas.co.uk/windows-10_jyd9gq.webp"
                          alt="img not found"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <img
                          src="https://img.zithas.co.uk/linux-server_lmaj90.webp"
                          alt="img not found"
                        />
                      </td>
                      <td>
                        <img
                          src="https://img.zithas.co.uk/xcode_c90doc.webp"
                          alt="img not found"
                        />
                      </td>
                      <td>
                        <img
                          src="https://img.zithas.co.uk/postman_z2efal.webp"
                          alt="img not found"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sixth-soft-section1">
              <div
                className="bd-service mb-30"
                style={{ backgroundColor: "var(--clr-bg-gray)" }}
              >
                <p>
                  Businesses often use software development tools, programming
                  languages, and application development frameworks as part of
                  their process technologies.
                </p>
                <table style={{ marginLeft: "50px" }}>
                  <tbody>
                    <tr style={{ marginBottom: "20px" }}>
                      <td className="pb-30" style={{ width: "180px" }}>
                        <img
                          src="https://img.zithas.co.uk/dot-net_d8moon.webp"
                          alt="img not found"
                        />
                      </td>
                      <td className="pb-30" style={{ width: "180px" }}>
                        <img
                          src="https://img.zithas.co.uk/angularjs_riezyc.webp"
                          alt="img not found"
                        />
                      </td>
                      <td className="pb-30">
                        <img
                          src="https://img.zithas.co.uk/nodejs_tucva7.webp"
                          alt="img not found"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="https://img.zithas.co.uk/flutter_i9rkzu.webp"
                          alt="img not found"
                        />
                      </td>
                      <td>
                        <img
                          src="https://img.zithas.co.uk/php_id3y82.webp"
                          alt="img not found"
                        />
                      </td>
                      <td>
                        <img
                          src="https://img.zithas.co.uk/react_aucrdv.webp"
                          alt="img not found"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bd-about-area">
        <div className="bd-custom-container">
          <div className="bd-about-four-bg pb-70 fix">
            <div className="bd-about-shape-box">
              <img
                className="bd-about-shape bd-about-shape-1"
                src="assets/img/shape/about-shape-3.png"
                alt="shape img not found"
              />
              <img
                className="bd-about-shape bd-about-shape-2"
                src="assets/img/shape/about-shape-4.png"
                alt="shape img not found"
              />
              <div className="container" style={{ maxWidth: "1270px" }}>
                <div className="row justify-content-center seventh-main">
                  <div className="col-xl-6 col-lg-8 seventh-soft-section1">
                    <div className="bd-about-img mb-30">
                      <img
                        src="https://img.zithas.co.uk/software-dev-bottom_mtorik.avif"
                        style={{ width: "450px", height: "430px" }}
                        className="xss-w-full rounded-10"
                        alt="about img not found"
                      />
                      <img
                        className="bd-about-shape bd-about-shape-6 hero-1-dot"
                        src={shape6}
                        alt="shape img not found"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8 pl-30 seventh-soft-section2">
                    <div className="bd-about-text bd-about-text-four mb-40 z-index">
                      <div className="bd-section-title-wrapper">
                        <h2 className="bd-section-title mb-25  md-pr-50 seventh-soft-tittle">
                          Unmatched Expertise: The Key to Your Next Project's
                          Success
                        </h2>
                        <p className="">
                          We have the latest technical tools and expert
                          management for your next project in Bristol, UK. Our
                          team is equipped with the latest technologies and
                          tools to ensure that your project is delivered on time
                          and to the highest standards. We use the
                          industry-standard methodologies and best practices to
                          make sure that your project is delivered on time,
                          within budget and to the highest standards. Our
                          management team is comprised of experienced
                          professionals who have a deep understanding of the
                          software development process and can guide your
                          project to success. With our technical tools and
                          expert management, we are confident that we can
                          deliver the best results for your next project in
                          Bristol, UK.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for Software Development Expert?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great Development, Let's get started right now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default DigitalMarket;
