import React, { Suspense } from 'react'
import About from '../About'
import Blog from '../Blog'
import { BrandArea } from '../BrandArea'
import Choose from '../Choose'
import Fact from '../Fact'
import Footer from '../Footer'
import Header from '../Header'
import Portfolio from '../Portfolio'
import Service from '../Service'
import SliderArea from '../SliderArea'
import Testimonial from '../Testimonial'
import ScrollTop from '../seperate Components/ScrollToTop';
import { Helmet } from 'react-helmet'


const Main = () => {
  return (
    <div>
      <Helmet>
        <title>Top Software and App Development Company in England, UK | Zithas Technologies</title>
        <meta name='description' content='We are a leading bespoke software company in England, UK with expertise in business automation, digital marketing services, and building an e-commerce website.' />

        <meta name="keywords" content="web and mobile application development bristol, build ecommerce website, building an ecommerce website, bespoke software company uk, top app development companies in uk, digital marketing services in uk, business automation service uk, software development company in england" />
      </Helmet>
      <Suspense >
        <ScrollTop />
        <Header />
        <SliderArea />
        <Service />
        <About />
        <Fact />
        <Choose />
        <Portfolio />
        <Testimonial />
        <Blog />
        <BrandArea />
        <Footer />
      </Suspense>
    </div>
  )
}
export default Main