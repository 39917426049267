import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header1 from "../Components/seperate Components/Header1";
import axios from "axios";
import Pagination from "../Components/seperate Components/Pagination";
import Spinner from "../Components/seperate Components/Spinner";

const EmployeeVideo = () => {
  const [empTestimonial, SetEmpTestimonial] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(6);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          "https://mars.zithas.com/api/empTestimonial"
        );
        SetEmpTestimonial(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  const indexOfLastPage = currentPage * postPerPage;
  const indexOfFirstPage = indexOfLastPage - postPerPage;
  const currentPosts = empTestimonial.slice(indexOfFirstPage, indexOfLastPage);
  const paginate = (currentPage) => {
    setCurrentPage(currentPage);
  };
  return (
    <div>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg_career pt-175 pb-155 main-heading"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Employee Testimonial
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      How We're Helping You Achieve Your Career Goals!
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-service-area  ">
        <div className="container" style={{ maxWidth: "1270px" }}>
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="row">
              {currentPosts.map((video) => {
                return (
                  <div className="col-lg-4 col-md-6 first-resp-section mt-80">
                    <div
                      className="bd-service"
                      style={{
                        backgroundColor: "var(--clr-bg-gray)",
                        paddingRight: "17px",
                        paddingLeft: "18px",
                        paddingTop: "13px",
                        paddingBottom: "14px",
                      }}
                    >
                      <div className="testimonial_video">
                        <iframe
                          src={video.video_link + "?autoplay=1&rel=0"}
                          className="resp-videos"
                          allowFullScreen="allowFullScreen"
                          frameBorder="0"
                          width="576"
                          height="300"
                          title="Everytime You Go Away - Paul Young"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
      <Pagination
        postPerPage={postPerPage}
        totalPosts={empTestimonial.length}
        paginate={paginate}
      />
      <Footer />
    </div>
  );
};

export default EmployeeVideo;
