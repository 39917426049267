import React from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone} from '@fortawesome/free-solid-svg-icons'
import Footer from '../../Components/Footer';
import { Helmet } from 'react-helmet';

const MicroService = () => {
    return (
        <div>

            <Helmet>
                <title>Micro Service Provider England, UK | Zithas Technologies</title>
                <meta name='description' content='We assist small enterprises in achieving their goals by offering supplementary services like graphic services, data management, content writing, etc.' />

                <meta name="keywords" content="
                Graphic Services,
                micro services uk,
                micro services england, 
                data management services,
                content writing uk" />
            </Helmet>

            <Header1 />
            <div className="bd-page-title-area bd-page-title-bg2 pt-220 pb-155" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index" style={{ bottom: "45px" }}>
                                <h1 className="breadcrumb-title" style={{ fontSize: '50px' }}>MICRO Services</h1>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                        <p style={{ color: "black" }}>Small Businesses Can Now Streamline their Processes with the Help of Our Additional Service Provider in England, UK.</p>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bd-service-area pb-80 pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 pt-20">
                            <div className="bd-section-title-wrapper micro_respon mb-50">
                                <h2 className="bd-section-title micro-service-tittle"><span style={{ color: "#ffbf1e" }}> MICRO Services </span>  Services that are best for Your Business</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i >
                                        <img src="https://img.zithas.co.uk/graphic-design_iqax72.webp"  alt="img not found" />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Graphic Services</h4>
                                <p>Design your very own marketing materials for your business with our professional graphic design services!</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://img.zithas.co.uk/video-64_rqbuvh.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Corporate Videos</h4>
                                <p>Get professional corporate videos that include top customer testimonials and new product demonstrations!</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://img.zithas.co.uk/content-64_pvjkoj.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Content Writing</h4>
                                <p>Get professional corporate videos that include top customer testimonials and new product demonstrations!</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://img.zithas.co.uk/lead-64_yapasi.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Lead Generation</h4>
                                <p>Our lead generation services can help you increase your leads pipeline and identify opportunities to generate new business.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bd-service mb-30" style={{ backgroundColor: "var(--clr-bg-gray)" }}>
                                <div className="bd-service-icon mb-20">
                                    <i>
                                        <img src="https://img.zithas.co.uk/recruitment-64_b0twsa.webp" alt="img not found"  />
                                    </i>
                                </div>
                                <h4 className="bd-service-title mb-20">Data management and cleaning</h4>
                                <p>A robust data management and cleaning process is crucial for organizations relying on data for their operations to drive business results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bd-video-area pt-135 pb-130" style={{ backgroundColor: "#5c0385" }}>
                <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row responsive-banner">
                        <div className="col-lg-12 first_section">
                            <div className="bd-video-text">
                                <h2 className="bd-video-title first-para-resp" >Looking for Micro Service Expert?</h2>
                                <p className="contact_section second-para-resp">You're at the right place. The right time</p>
                                <p className="contact_subtext">Surprisingly great Micro Service, Let's get started right now !</p>
                            </div>
                        </div>
                        <div className="col-lg-12 second_section">
                            <div className="contact-stuff pt-30">
                                <div className="contact_icon icon_respon pt-15 pl-50">
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: "30px" }} />
                                </div>
                                <div className="contact_txt ">
                                    <p className="contact-sub-text">CALL US NOW</p>
                                    <a href="tel:(0) 776-742-6142" style={{ fontSize: "17px" }} className="mbb-5 pb-20">(0) 776-742-6142</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />


        </div>
    )
}

export default MicroService