import "./Styles/Cookies.css";
import Main from "./Components/Home/Main";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import BusinessAutomation from "./RedirectingPages/NavbarPages/BusinessAutomation";
import BusinessAudit from "./RedirectingPages/NavbarPages/BusinessAudit";
import BespokeSystem from "./RedirectingPages/NavbarPages/BespokeSystem";
import SoftwareDevelopment from "./RedirectingPages/NavbarPages/SoftwareDevelopment";
import WebsiteDevelopment from "./RedirectingPages/NavbarPages/WebsiteDevelopment";
import MobileApp from "./RedirectingPages/NavbarPages/MobileApp";
import DedicatedResource from "./RedirectingPages/NavbarPages/DedicatedResource";
import Blog from "./RedirectingPages/FooterPages/Blog";
import BlogDetails from "./RedirectingPages/FooterPages/AllBlogs/BlogDetails";
import { Fragment } from "react";
import ScrollToTop from "./Components/seperate Components/ScrollToTop";
import ContactUs from "./RedirectingPages/FooterPages/ContactUs";
import Details2 from "./RedirectingPages/FooterPages/CareerDetails/Details2";
import AboutCompany from "./RedirectingPages/FooterPages/AboutCompany";
import Policy from "./RedirectingPages/FooterPages/Policy";
import PricingTable from "./RedirectingPages/FooterPages/PricingTable";
import SeoManagement from "./RedirectingPages/Packages/SeoManagement";
import MainTestimonial from "./RedirectingPages/FooterPages/MainTestimonial";
import DigitalMarketing from "./RedirectingPages/FooterPages/DigitalMarketing";
import HostingServices from "./RedirectingPages/FooterPages/HostingServices";
import MicroService from "./RedirectingPages/FooterPages/MicroService";
import CurrentOPening from "./RedirectingPages/FooterPages/CurrentOPening";
import CaseStudy1 from "./Components/Case Studies/CaseStudy1";
import PageNotFound from "./Components/seperate Components/PageNotFound";
import SiteMap from "./Components/seperate Components/SiteMap";
import MainCaseStudy from "./Components/Case Studies/MainCaseStudy";
import EmployeeVideo from "./RedirectingPages/EmployeeVideo";
import TeamMembers from "./RedirectingPages/TeamMembers";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TermsAndCondition from "./RedirectingPages/FooterPages/Terms&Condition";
import "bootstrap/dist/js/bootstrap";
import YoutubePage from "./Components/seperate Components/YoutubePage";
import ScrollTop from "./RedirectingPages/NavbarPages/ScrollTop";
import CallBack from "./RedirectingPages/NavbarPages/CallBack";
import Creditsafe from "./RedirectingPages/FooterPages/Creditsafe";

ReactGA.initialize("UA-76559070-1");
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [ip, setIP] = useState("");
  const [long, setLong] = useState("");
  const [latt, setLatt] = useState("");
  const [city, setCity] = useState("");

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setLatt(res.data.latitude);
    setLong(res.data.longitude);
    setIP(res.data.IPv4);
    setCity(res.data.city);
  };
  useEffect(() => {
    getData();
  }, []);

  const [shown, setShown] = useState(true);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 2 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + true + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let user = getCookie("zithasTech");
    if (user != "") {
      var x = document.getElementById("cookiesBox");
      x.style.display = "none";
    } else {
      setCookie("zithasTech", user, 30);
    }
    localStorage.setItem("latitude", latt);
    localStorage.setItem("longitude", long);
    localStorage.setItem("IpAddress", ip);
    localStorage.setItem("City", city);
    var x = document.getElementById("cookiesBox");
    x.style.display = "none";
    // }
  }

  useEffect(() => {
    let user = getCookie("zithasTech");
    if (user != "") {
      var x = document.getElementById("cookiesBox");
      x.style.display = "none";
    }
  }, []);

  function checkCookieBanner() {
    let user = getCookie("zithasTech");
    if (user != "") {
      var x = document.getElementById("cookiesBox");
      x.style.display = "none";
    } else {
      var x = document.getElementById("cookiesBox");
      x.style.display = "block";
    }
  }

  const onDecline = () => {
    var x = document.getElementById("cookiesBox");
    x.style.display = "none";
  };

  const onSaveSettings = () => {
    let user = getCookie("zithasTech");
    if (user != "") {
      var x = document.getElementById("cookiesBox");
      x.style.display = "none";
    } else {
      setCookie("zithasTech", user, 30);
    }
    var x = document.getElementById("cookiesBox");
    x.style.display = "none";

    var checkBox = document.getElementById("analytics_cookies");
    if (checkBox.checked == true) {
      localStorage.setItem("latitude", latt);
      localStorage.setItem("longitude", long);
      localStorage.setItem("IpAddress", ip);
      localStorage.setItem("City", city);
    } else if (checkBox.checked == false) {
      return false;
    }

    var checkBox = document.getElementById("functional_cookies");
    if (checkBox.checked == true) {
      // console.log("Functional Coocikes is Acctivated");
    } else {
      // console.log("Functional Coocikes is Deacctivated");
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Fragment>
          <ScrollToTop />
          {/* <ScrollToTop /> */}
          <ScrollTop />
          <CallBack />

          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route path="*" element={<PageNotFound />} />

            <Route path="/404" element={<PageNotFound />} />

            <Route
              path="/business-automation"
              element={<BusinessAutomation />}
            />
            <Route
              path="/business-software-audit"
              element={<BusinessAudit />}
            />
            <Route path="/bespoke-systems" element={<BespokeSystem />} />
            <Route
              path="/software-development"
              element={<SoftwareDevelopment />}
            />
            <Route
              path="/website-development"
              element={<WebsiteDevelopment />}
            />
            <Route path="/mobile-app-development" element={<MobileApp />} />
            <Route path="/hire-a-resource" element={<DedicatedResource />} />

            <Route path="/blog" element={<Blog />} />
            <Route path="/testimonial-video" element={<YoutubePage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/employee-testimonial" element={<EmployeeVideo />} />
            <Route path="/pillars-of-zithas" element={<TeamMembers />} />

            <Route path="/case-study/:permalink" element={<CaseStudy1 />} />

            <Route path="/about-us" element={<AboutCompany />} />
            <Route path="/privacy-policy" element={<Policy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndCondition />}
            />
            <Route path="/Maintenance-Packages" element={<PricingTable />} />
            <Route path="/testimonials" element={<MainTestimonial />} />
            <Route path="/creditsafe" element={<Creditsafe />} />
            <Route path="/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/hosting-service" element={<HostingServices />} />
            <Route path="/micro-service" element={<MicroService />} />
            <Route path="/blog/:slug" element={<BlogDetails />} />
            <Route path="/current-opening/:permalink" element={<Details2 />} />
            <Route
              path="/seo-management-packages"
              element={<SeoManagement />}
            />
            <Route path="/current-opening" element={<CurrentOPening />} />
            <Route path="/sitemap" element={<SiteMap />} />
            <Route path="/case-studies" element={<MainCaseStudy />} />
          </Routes>

          <div className="bd-fade-cookie">
            <div>
              <div id="cookiesBox" className="custom-cookies-box">
                <div className="cookies-text">
                  We are using cookies to give you the best experience on our
                  website. See our{" "}
                  <Link
                    to="/privacy-policy"
                    style={{
                      textDecoration: "underline",
                      color: "rgb(255, 191, 30)",
                      fontWeight: "600",
                    }}
                  >
                    Privacy Policy
                  </Link>{" "}
                  for More.
                  <br />
                  You can find out more about which cookies we are using or
                  switch them off in settings.
                </div>
                <div className="bd-three-btn">
                  <div className="bd-cookies-section2">
                    <button
                      className="bd-setting-cookie"
                      data-bs-toggle="modal"
                      data-bs-target="#cookieModal"
                      id="rcc-confirm-button"
                      aria-label="Accept cookies"
                    >
                      Cookie Setting
                    </button>
                  </div>

                  <div className="bd-right-mar" style={{ width: "35%" }}>
                    <button
                      className="bd-accept-btn"
                      onClick={checkCookie}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Accept
                    </button>
                  </div>
                  <div>
                    <button
                      className="bd-decline-btn"
                      onClick={onDecline}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Decline
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {shown && (
            <div
              className="modal fade "
              id="cookieModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl modal-dialog-centered mt-75">
                <div
                  className="modal-content "
                  style={{
                    maxWidth: "800px",
                    paddingLeft: "0px",
                    paddingRight: "20px",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    className="modal-header"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <h5 className="modal-title w100" id="exampleModalLabel2">
                      Privacy Preference Center
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{ marginLeft: "50px" }}
                    ></button>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <div className="bd-tab">
                        <Tabs>
                          <div className="bd-modal-cookie-1">
                            <TabList>
                              <Tab style={{ borderTop: "none" }}>
                                Your Privacy
                              </Tab>
                              <Tab>Strictly Necessary Cookies</Tab>
                              <Tab>Analytics Cookies</Tab>
                            </TabList>
                          </div>

                          <div className="bd-modal-cookie-2">
                            <TabPanel>
                              <h6>Your Privacy</h6>
                              <p className="bd-cookie-text">
                                At zithas we care about your privacy and believe
                                in transparency. That’s why we are committed to
                                being upfront about our privacy practices and
                                provide settings that allow you to choose how
                                certain information is used. Our Site uses
                                Cookies to identify you. Cookies can store your
                                account identifier, ordering status,
                                personalisation or website tracking. They can
                                also be used for technical purposes such as
                                keeping track of your current shopping session
                                or to save information for your languages
                                preferences, so that we can offer improved and
                                more personalised services, products and other
                                relevant communication tailored to you,
                                including to display targeted, or interest-based
                                offers based on the products you currently own
                                or have recently viewed.
                              </p>
                            </TabPanel>
                            <TabPanel>
                              <h6>Strictly Necessary Cookies</h6>
                              <p className="bd-cookie-text">
                                These cookies are necessary for the website to
                                function and cannot be switched off in our
                                systems. They are usually only set in response
                                to actions made by you which amount to a request
                                for services, such as setting your privacy
                                preferences, logging in or filling in forms. You
                                can set your browser to block or alert you about
                                these cookies, but some parts of the site will
                                not then work. These cookies do not store any
                                personally identifiable information.{" "}
                              </p>
                            </TabPanel>

                            <TabPanel>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{ paddingTop: "5px", width: "92%" }}
                                >
                                  <h6>Analytics Cookies</h6>
                                </div>
                                <div>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="analytics_cookies"
                                      style={{ fontSize: "20px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <p className="bd-cookie-text">
                                These cookies allow us to count visits and
                                traffic sources so we can measure and improve
                                the performance of our site. They help us to
                                know which pages are the most and least popular
                                and see how visitors move around the site. All
                                information these cookies collect is aggregated
                                and therefore anonymous. If you do not allow
                                these cookies we will not know when you have
                                visited our site, and will not be able to
                                monitor its performance.
                              </p>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      paddingTop: "25px",
                      borderTop: "1px solid rgba(0,0,0,0.4)",
                    }}
                  >
                    <div className="bd-btns-cookie">
                      <button
                        className="bd-save-cookie-sett"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        tabIndex="0"
                        id="rcc-confirm-button1"
                        onClick={onSaveSettings}
                      >
                        Save Settings
                      </button>
                    </div>
                    <div className="bd-btns-cookie2">
                      <button
                        className="bd-save-cookie-sett"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={checkCookie}
                      >
                        Allow All cookies
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      </BrowserRouter>
    </div>
  );
}

export default App;
