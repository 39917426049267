import React, { useState, useEffect } from "react";
import Header1 from "../../../Components/seperate Components/Header1";
import "../../../Styles/Redirecting Styles/FooterStyle/CareerDetails.css";
import Footer from "../../../Components/Footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import Spinner from "../../../Components/seperate Components/Spinner";
import swal from "sweetalert";

const Details2 = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [experience, setExperience] = useState("");
  const [ctc, setCtc] = useState("");
  const [expected, setExpected] = useState("");
  const [notice_period, setNoticePeriod] = useState("");
  const [resume, setResume] = useState(null);
  const [Loading, setPostLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    {
      opening.map((jobs) => {
        formData.append("designation", jobs.position_name);
      });
    }

    formData.append("experience", experience);
    formData.append("ctc", ctc);
    formData.append("expected", expected);
    formData.append("notice_period", notice_period);
    formData.append("resume", resume);
    setPostLoading(true);
    try {
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/career",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setPostLoading(false);
      swal({
        icon: "success",
        text: "Data send Sucessfully",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const { permalink } = useParams();
  const [opening, SetOpening] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `https://mars.zithas.com/api/currentopening/${permalink}`
        );
        SetOpening(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg_careerDetail detail-responsive-tittle pt-175 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                {opening.map((jobs) => {
                  return (
                    <>
                      <h2 className="breadcrumb-title1 detail-main-tittle">
                        {jobs.position_name}
                      </h2>
                      <div className="breadcrumb-menu">
                        <nav className="breadcrumb-trail breadcrumbs"></nav>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Blogs section */}
      <section className="bd-blog-deatils-area pt-80 pb-70">
        <div className="container center" style={{ maxWidth: "1300px" }}>
          <div className="row">
            <div className="col-lg-8 careerdetail-reponsive-section1">
              {isLoading ? (
                <Spinner />
              ) : (
                <div
                  className="blog__details--wrapper mr-00 "
                  style={{ width: "auto" }}
                >
                  {opening.map((jobs) => {
                    return (
                      <>
                        <h3 className="bd-blog-details-title mb-25">
                          {jobs.position_name} Job Description
                        </h3>
                        <ul
                          className="career-text pl-20"
                          style={{ lineHeight: "1.9", fontSize: "17px" }}
                        >
                          <p
                            className="pb-10"
                            dangerouslySetInnerHTML={{
                              __html: jobs.job_description,
                            }}
                          />
                        </ul>
                        <h3>Roles & Responsibilities:</h3>
                        <ul
                          className="career-text pl-20"
                          style={{ lineHeight: "1.9", fontSize: "17px" }}
                        >
                          <div
                            className="pb-20"
                            dangerouslySetInnerHTML={{
                              __html: jobs.roles_responsibilities,
                            }}
                          />
                        </ul>
                        <ul className="career-text ">
                          <h3>Soft Skills Requirements</h3>
                          <ul
                            className="career-text pl-20"
                            style={{ lineHeight: "1.9", fontSize: "17px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: jobs.soft_skill_requirement,
                              }}
                            />
                          </ul>
                        </ul>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="col-lg-6 opening-reponsive">
              <div className="blog__details--wrapper mr-0 mb-50">
                <div className="ablog__sidebar mb-40">
                  <div
                    className="col-xxl-8 col-xl-7 col-lg-7 "
                    style={{ width: "100%" }}
                  >
                    <div className="bd-contact-form-wrapper mb-50">
                      <div
                        className="bd-service mb-30"
                        style={{ backgroundColor: "#fdd796" }}
                      >
                        <h2
                          style={{ textAlign: "center", marginBottom: "20px" }}
                        >
                          Apply Here
                        </h2>
                        {Loading && <Spinner />}
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="bd-contact-field mb-20">
                              <input
                                className="blog-form"
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                style={{ width: "300px" }}
                                placeholder="Name"
                                name="user_name"
                                required
                              />
                            </div>
                            <div className="bd-contact-field mb-20">
                              <input
                                type="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                name="user_email"
                                style={{ width: "300px" }}
                                required
                              />
                            </div>

                            <div className="bd-contact-field mb-20 ">
                              <input
                                type="text"
                                placeholder="Contact No"
                                name="user_contact"
                                onChange={(e) => setMobile(e.target.value)}
                                style={{ width: "300px" }}
                                required
                              />
                            </div>
                            {opening.map((jobs) => {
                              return (
                                <div className="bd-contact-field mb-20 ">
                                  <input
                                    type="text"
                                    placeholder={jobs.position_name}
                                    disabled
                                    name="user_field"
                                    style={{ width: "300px" }}
                                    required
                                  />
                                </div>
                              );
                            })}
                            <div className="bd-contact-field mb-20">
                              <input
                                type="text"
                                placeholder="Years Of Experience"
                                name="user_experience"
                                onChange={(e) => setExperience(e.target.value)}
                                style={{ width: "300px" }}
                                required
                              />
                            </div>
                            <div className="bd-contact-field mb-20">
                              <input
                                type="text"
                                placeholder="Current CTC"
                                name="user_ctc"
                                onChange={(e) => setCtc(e.target.value)}
                                style={{ width: "300px" }}
                                required
                              />
                            </div>
                            <div className="bd-contact-field mb-20">
                              <input
                                type="text"
                                onChange={(e) => setExpected(e.target.value)}
                                placeholder="Expected CTC"
                                style={{ width: "300px" }}
                                name="user_expectedctc"
                                required
                              />
                            </div>
                            <div className="bd-contact-field mb-20">
                              <input
                                type="text"
                                placeholder="Notice Period"
                                onChange={(e) =>
                                  setNoticePeriod(e.target.value)
                                }
                                name="user_period"
                                style={{ width: "300px" }}
                                required
                              />
                            </div>
                            <div className="bd-contact-field mb-20">
                              <input
                                type="file"
                                className="pt-25"
                                style={{ width: "300px", paddingTop: "15px" }}
                                onChange={(e) => setResume(e.target.files[0])}
                                required
                              />
                            </div>
                            <div className="col-12 mb-20">
                              <div className="bd-contact-field">
                                <button type="submit" className="theme-btn">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Details2;
