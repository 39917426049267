import React from 'react'
import Header1 from '../../Components/seperate Components/Header1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import '../../Styles/Redirecting Styles/FooterStyle/Policy.css'
import Footer from '../../Components/Footer';
import "../../Styles/Redirecting Styles/NavbarStyles/Software.css"
import { Helmet } from 'react-helmet';

const Policy = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy | Top Digital Marketing Company | Zithas Technologies</title>
                <meta name='description' content='See our company privacy policy. We offer best digital marketing services including SEO, Website Design, mobile app development, business automation, etc.' />

                <meta name="keywords" content="zithas technologies privacy policy" />
            </Helmet>

            <Header1 />


            {/* Banner section */}
            <div className="bd-page-title-area bd-page-title-bg_policy pt-175 pb-155 main-privacy-responsive" style={{ height: "380px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bd-page-title text-center z-index">
                                <h2 className="breadcrumb-title" style={{ fontSize: '50px', color: "#4a026c" }}>Privacy Policy</h2>
                                <div className="breadcrumb-menu">
                                    <nav className="breadcrumb-trail breadcrumbs">
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bd-about-six-area    pt-80">
                <div className="container" style={{ maxWidth: "1250px" }}>
                    <div className="row">
                        <div className="col-lg-12 col-md-8">
                            <div className="bd-about-six-text">
                                <div className="bd-section-title-wrapper mb-25">
                                </div>
                                <div className="bd-about-six-feature pt-20">
                                    <ul className="mb-45">
                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i> Introduction</li>
                                        <p className="soft-para mb-25"> The privacy and security of our customers and users is of the utmost importance to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our services. By using our services, you agree to the collection, use, and sharing of your personal information as outlined in this Privacy Policy.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i> Information Collection and Use</li>
                                        <p className="soft-para mb-25">We may collect personal information such as your name, contact information, and payment details when you create an account, make a purchase, or use our services. This information is used to process transactions, provide customer support, and improve our services.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Cookies and Tracking Technologies </li>
                                        <p className="soft-para mb-25">We may use cookies and other tracking technologies to collect information about your browsing activities on our website. This information is used to improve our website's performance and personalize your experience. You can choose to disable cookies in your browser settings, but this may affect your ability to access some features of our website.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Sharing of Personal Information</li>
                                        <p className="soft-para mb-25 ">We may share your personal information with third-party service providers to assist with processing transactions, providing customer support, and improving our services. These third parties are bound by strict confidentiality agreements and are only permitted to use your personal information for the purpose of providing services to us.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Data Retention</li>
                                        <p className="soft-para mb-25">We will retain your personal information for as long as necessary to provide you with our services, comply with legal obligations, and resolve disputes.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Data Security</li>
                                        <p className="soft-para mb-25">We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. We use industry-standard security measures, such as encryption and firewalls, to protect your personal information.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Your Rights</li>
                                        <p className="soft-para mb-25">Under UK data protection laws, you have the right to request access to your personal information, request that we rectify or delete it, and object to its processing. You also have the right to request that we restrict the processing of your personal information.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Updates to this Privacy Policy</li>
                                        <p className="soft-para mb-25">We may update this Privacy Policy from time to time to reflect changes in our services or applicable laws. We will notify you of any changes by posting the updated Privacy Policy on our website.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Contact Us</li>
                                        <p className="soft-para mb-25">If you have any questions or concerns about our Privacy Policy, please contact us at <a href="tel:(0) 776-742-6142">(0) 776-742-6142</a>.</p>

                                        <li className="point-software" style={{ fontSize: "20px", marginBottom: "10px" }}><i><FontAwesomeIcon icon={faCheck} /></i>Compliance</li>
                                        <p className="soft-para mb-25">We are committed to complying with all applicable data protection laws and regulations in England, UK, including the General Data Protection Regulation (GDPR).</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </div >
    )
}

export default Policy