import React, { useEffect } from "react";
import "../Styles/Footer.css";
import "../Styles/Theme.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faTwitterSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <footer className="heading-bg" style={{ backgroundColor: "#161616" }}>
        <div className="bd-footer-area pt-60 pb-10">
          <div className="container" style={{ maxWidth: "1270px" }}>
            <div className="row footer-column">
              <div className="col-lg-3 col-sm-6 column-first">
                <div className="bd-footer-widget footer-col-1">
                  <div className="bd-footer-info">
                    <div className="bd-footer-info-logo mb-20">
                      <Link to="/">
                        <img
                          src="https://img.zithas.co.uk/zithas-main-logo_zagcfx.avif"
                          style={{ width: "150px" }}
                          className="img-fluid"
                          alt="img not found"
                        />
                      </Link>
                    </div>

                    <div className="my-4" style={{ paddingLeft: "10px" }}>
                      <h5
                        style={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "15px",
                        }}
                      >
                        {" "}
                        Company Reg. No.
                      </h5>
                      <span style={{ fontSize: "17px" }}>11608749</span>
                    </div>

                    <div style={{ paddingLeft: "10px" }}>
                      <h5
                        style={{
                          color: "white",
                          fontWeight: "400",
                          fontSize: "15px",
                        }}
                      >
                        {" "}
                        Stay Connected :
                      </h5>
                      <div className="bd-header-social  mb-50">
                        <a
                          href="https://www.facebook.com/zithastechnologiesuk"
                          aria-label="Facebook"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faFacebookF} />
                        </a>

                        <a
                          href="https://www.youtube.com/@ZithasTechnologiesUK"
                          aria-label="Youtube"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faYoutube} />
                        </a>

                        <a
                          href="https://twitter.com/zithasuk"
                          aria-label="Twitter"
                          target="_blank"
                        >
                          <i className="fa-brands fa-x-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/zithastechnologies.uk/"
                          aria-label="Instagram"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                        </a>

                        <a
                          href="https://www.linkedin.com/company/zithas-technologies-uk"
                          aria-label="Linekedin"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 column-second">
                <div
                  className="bd-footer-widget footer-col-2 mb-30"
                  style={{ paddingLeft: "0px" }}
                >
                  <h5 className="bd-footer-widget-title mb-35">Quick Links</h5>
                  <ul>
                    <li>
                      <Link to={"/about-us"} onClick={toggleContact}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact-us"} onClick={toggleContact}>
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/blog"}
                        onClick={toggleContact}
                        spy="true"
                        smooth="true"
                        exact="true"
                      >
                        Blog
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={"/testimonials"}
                        onClick={toggleContact}
                        spy="true"
                        smooth="true"
                        exact="true"
                      >
                        {" "}
                        Testimonials{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/creditsafe"}
                        onClick={toggleContact}
                        spy="true"
                        smooth="true"
                        exact="true"
                      >
                        {" "}
                        Creditsafe{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 column-third">
                <div
                  className="bd-footer-widget footer-col-3 mb-30"
                  style={{ paddingLeft: "0px" }}
                >
                  <h5 className="bd-footer-widget-title mb-35">
                    More Information
                  </h5>
                  <ul>
                    <li>
                      <Link
                        to={"/Maintenance-Packages"}
                        onClick={toggleContact}
                      >
                        {" "}
                        Maintenance Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/seo-management-packages"}
                        onClick={toggleContact}
                      >
                        {" "}
                        SEO Packages
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"} onClick={toggleContact}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/terms-and-conditions"}
                        onClick={toggleContact}
                        spy="true"
                        smooth="true"
                        exact="true"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 column-fourth">
                <div
                  className="bd-footer-widget footer-col-3 mb-30"
                  style={{ paddingLeft: "0px" }}
                >
                  <h5 className="bd-footer-widget-title mb-35">
                    Other Services
                  </h5>
                  <ul>
                    <li>
                      <Link
                        to={"/business-software-audit"}
                        onClick={toggleContact}
                      >
                        Business software Audit
                      </Link>
                    </li>
                    <li>
                      <Link to={"/digital-marketing"} onClick={toggleContact}>
                        Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link to={"/hosting-service"} onClick={toggleContact}>
                        Hosting Services
                      </Link>
                    </li>
                    <li>
                      <Link to={"/micro-service"} onClick={toggleContact}>
                        MICRO Services
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 column-fifth">
                <div className="bd-footer-widget footer-col-4 mb-30">
                  <div className="bd-footer-info-item mb-10">
                    <h5 className="bd-footer-widget-title mb-35">Contact Us</h5>
                    <h6>Phone : </h6>
                    <a href="tel:(0) 776-742-6142">(0) 776-742-6142</a>
                  </div>
                  <div className="bd-footer-info-item mb-10">
                    <h6>Email : </h6>
                    <a href="mailto:hello@zithas.co.uk">hello@zithas.co.uk</a>
                  </div>
                  <div className="bd-footer-info-item mb-10">
                    <h6>Location :</h6>
                    <p className="address"> Thornbury, Bristol, England, UK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bd-copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bd-copyright text-center">
                  <p className="m-0">
                    © {new Date().getFullYear()} All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
