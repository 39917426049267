import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header1 from "../Components/seperate Components/Header1";
import axios from "axios";
import { Helmet } from "react-helmet";

const TeamMembers = () => {
  const [team, setTeam] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get("https://mars.zithas.com/api/team_uk");
        setTeam(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Pillars of the Company | Zithas Technologies</title>
        <meta
          name="description"
          content="Zithas has a diverse network of industry experts and consultants with a multicultural mindset to help their clients."
        />

        <meta name="keywords" content="backbone of zithas technologies" />
      </Helmet>

      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg_about pt-175 pb-155"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Pillars of Zithas
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      Zithas team is a diverse network of consultants and
                      industry professionals with a global mindset and a
                      culture.
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bd-portfolio-area bd-portfolio-spacing pt-80  pb-60">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row mb-30">
            {team.map((img) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <div className="bd-portfolio mb-30">
                    <img src={img.link} alt="Portfolio not found" />
                    <div className="bd-portfolio-text">
                      <h5 className="bd-team-four-title">{img.name}</h5>
                      <span className="pb-15">{img.designation}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TeamMembers;
