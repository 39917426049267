import React, { useState } from "react";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import { Link } from "react-router-dom";

const Choos = () => {
  return (
    <div>
      <section className="bd-choose-area fix  pt-80 pb-70">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="bd-choose-shape">
            <div className="row">
              <div className="col-xl-6 col-lg-6 order-2 order-lg-1">
                <div className="bd-choose-text  z-index">
                  <div className="bd-section-title-wrapper mb-25">
                    <h2 className="bd-section-title mb-15">
                      Analyze <span style={{ color: "#ffbf1e" }}> Audits </span>{" "}
                      to Ease Missions
                    </h2>
                  </div>

                  <p
                    className="mb-20"
                    style={{ width: "95%", fontSize: "18px" }}
                  >
                    Our business software auditing services assist organizations
                    in identifying and analyzing gaps in their software and
                    processes, compared to their business requirements. This
                    allows companies to prioritize areas for improvement and see
                    how technology can positively impact their operations. By
                    filling these gaps, organizations can experience increased
                    growth and success.
                  </p>
                  <p className="mb-20" style={{ fontSize: "18px" }}>
                    Gaps in an organization can be a major hindrance to its
                    success. Many organizations struggle with effectively
                    managing employee attendance and utilizing outdated
                    technology, often relying on spreadsheets for data
                    comparison. However, our findings show that these issues can
                    be overcome by adopting{" "}
                    <Link
                      to="/software-development"
                      style={{ fontWeight: "500" }}
                    >
                      {" "}
                      cloud-based software
                    </Link>{" "}
                    solutions, avoiding the risks of using unsupported
                    technology.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 order-1 order-lg-2">
                <div className="bd-choose-img text-end  z-index">
                  <div className="row align-items-center media-respon-digital custom-mar-15">
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1">
                        <img
                          src="https://img.zithas.co.uk/business-audit_vvkick.avif"
                          className="resp-img bordertop-img1"
                          alt="img not found"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                      <div className="bd-choose-img-inner mb-15">
                        <img
                          src="https://img.zithas.co.uk/business-audit-section1_pixjwt.avif"
                          className="resp-img bordertop-img2"
                          alt="img not found"
                        />
                      </div>
                      <div className="bd-choose-img-inner mb-15">
                        <img
                          src="https://img.zithas.co.uk/business-audit-section1.1_bvazg8.avif"
                          className="resp-img bordertop-img3"
                          alt="img not found"
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    className="bd-choose-shape bd-choose-shape-2"
                    src={Shape2}
                    alt="shape img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Choos;
