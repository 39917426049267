import React, { useState, useEffect } from "react";
import Header1 from "../../../Components/seperate Components/Header1";
import { faCalendarDay, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../../../Components/Footer";
import Reply from "../../../Components/seperate Components/Reply";
import RecentPost from "../../../Components/seperate Components/RecentPost";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import Spinner from "../../../Components/seperate Components/Spinner";
import "../../../Styles/Redirecting Styles/FooterStyle/BlogDetails.css";
import moment from "moment";

import { Helmet } from "react-helmet";
import PageNotFound from "../../../Components/seperate Components/PageNotFound";

const BlogDetails = ({ match }) => {
  const { slug } = useParams();
  const [comments, setComments] = useState([]);

  // for meta tags
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  const navigate = useNavigate();

  const fetchComments = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://mars.zithas.com/api/ukblogcomments_reply/${slug}`
      );
      setComments(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchComments();
  }, [slug]);
  const [post, SetPost] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `https://mars.zithas.com/api/zithasukblog/${slug}`
        );
        SetPost(data);
        if (data.length == 0) {
          navigate("/404");
        }

        setTitle(data[0].meta_title);
        setDescription(data[0].meta_description);

        // switch (data[0].slug) {
        //     case "sustainability-with-erp-systems":
        //         setTitle('Sustainability with ERP systems!');
        //         setDescription('ERP (Enterprise Resource Planning) and custom made software systems integrate several business operations and activities into one centralized system.');
        //         setKeywords('erp systems, Custom Made Software');
        //         break;
        //     case "digital-tools-that-have-made-the-healthcare-industry-more-efficient":
        //         setTitle('Digital Tools: More Efficient for Healthcare Industry');
        //         setDescription('Digital tools play an important role in every industry. For healthcare industry, it has smooth-lined all the processes from patient admission to reliving.');
        //         setKeywords('digital tools for health industry');
        //         break;
        //     case "how-real-estate-is-adapting-through-virtual-tours-and-bespoke-systems":
        //         setTitle('Bespoke System for Real Estate');
        //         setDescription('The bespoke system plays an important role in any business. For real estate business, it works as a bridge between customers and businesses.');
        //         setKeywords('bespoke system for business, bespoke software development')
        //         break;
        //     case "essential-to-business-survival-digital-transformation":
        //         setTitle('Essentiality of Digital Transformation');
        //         setDescription('To stay competitive in this race and generate money through digital tactics, digital transformation is a crucial component.');
        //         setKeywords('digital transformation process, mobile apps development uk')
        //         break;
        //     case "technology-that-helps-in-critical-care-management":
        //         setTitle('Bespoke software development in critical care management');
        //         setDescription('By using bespoke software development, the healthcare sector is employing virtual clinics to separate high-risk patients and lessen the workload.');
        //         setKeywords('critical care management technology, bespoke software development')
        //         break;
        //     case "immigration-tech-in-2021-and-beyond":
        //         setTitle('Immigration tech in 2021 and beyond');
        //         setDescription("With the changing of the country's rules, providers of visa and immigration services face several requirements. Immigration Tech assists them in every way.");
        //         setKeywords('immigration tech provider')
        //         break;
        //     case "changes-in-customer-experience-due-to-covid-19-pandemic":
        //         setTitle('Changes in customer experience due to Covid-19 Through Custom Made Software');
        //         setDescription('The scenario altered the CRM in 2020. Things online abruptly changed extremely quickly, forcing businesses to change to custom made software in order to survive.');
        //         setKeywords('CRM Software, Custom Made Software')
        //         break;
        //     case "5-main-reasons-to-consider-outsourcing-your-bespoke-software-development":
        //         setTitle('5 Reasons for Outsourcing Bespoke Software Development');
        //         setDescription('It is obvious that for the majority of business owners today, outsourcing bespoke software development is the best choice. This is because it offers many advantages.');
        //         setKeywords('bespoke software development, bespoke software development uk, bespoke software company uk')
        //         break;
        //     case "is-quality-and-functionality-two-sides-of-a-coin-heres-how-we-make-your-ecommerce-business-shine":
        //         setTitle('How We Make Your ECommerce Business Shine');
        //         setDescription('Before making your website available to customers, make sure that every aspect of it is flawless if you want to have a high-quality online storefront.');
        //         setKeywords('e-commerce business, build ecommerce website')
        //         break;
        // }
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, [slug]);

  return (
    <div>
      {isLoading ? <Spinner /> : null}
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />

          {/* <meta name="keywords" content={keywords} /> */}
        </Helmet>
        <Header1 />
        <div
          className="bd-page-title-area bd-page-title-bg_blog blogdetail_responsive-ban pt-180 pb-155"
          style={{ height: "380px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                {post.map((blogCont, i) => {
                  const publishDate = moment(blogCont.publish_date).format(
                    "Do MMM YYYY"
                  );
                  return (
                    <div key={i} className="bd-page-title text-center z-index">
                      <h2 className="breadcrumb-title1 blogdetail-main-tittle">
                        {blogCont.title}
                      </h2>
                      <div className="breadcrumb-menu">
                        <div className="bd-blog-meta mb-15">
                          <ul>
                            <li>
                              <a href="" style={{ textTransform: "none" }}>
                                <i>
                                  <FontAwesomeIcon icon={faCalendarDay} />
                                </i>
                                {publishDate}
                              </a>
                            </li>
                            <li>
                              <a href="">
                                <i>
                                  <FontAwesomeIcon icon={faMessage} />
                                </i>
                                {comments.length} Comments
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <section className="bd-blog-deatils-area pt-105 pb-100">
          <div className="container " style={{ maxWidth: "1270px" }}>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="row">
                {post.map((blogContent, i) => {
                  return (
                    <>
                      <div
                        key={i}
                        className="col-lg-8 Blog-responsive-Section1"
                      >
                        <div className="blog__details--wrapper mr-50 mb-50">
                          <div className="ablog__img">
                            <img
                              src={blogContent.detail_image}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                          <div
                            className="bg-blog-ul"
                            dangerouslySetInnerHTML={{
                              __html: blogContent.description,
                            }}
                          />
                          <Reply posts={post} comments={comments} />
                        </div>
                      </div>
                    </>
                  );
                })}
                <RecentPost />
              </div>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default BlogDetails;
