import React from "react";
import Footer from "../Footer";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  faBars,
  faTimes,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animateScroll as scroll } from "react-scroll";

const YoutubePage = () => {
  const [navbar, setNavbar] = useState(false);
  const [stickyClass, setStickyClass] = useState("");

  // sticky Navbar
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 80 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  window.addEventListener("scroll", stickNavbar);
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.addEventListener("scroll", stickNavbar);
  }, []);
  const change = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", change);
    return () => window.addEventListener("scroll", change);
  }, []);
  const [click, setClick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  window.addEventListener("resize", function (event) {
    setWidth(window.innerWidth);
  });
  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div>
      <header>
        <div className="bd-header-area active1">
          <div className="bd-header-border">
            <div className="bd-custom-container bd-spacing">
              <div className="row align-items-center txt-cent">
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-7 order-1 order-xl-1 respon">
                  <div>
                    <Link to="/">
                      <img
                        src="https://img.zithas.co.uk/zithas-logo-og_wfrysv.avif"
                        style={{ zIndex: "999" }}
                        alt="logo not found"
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg-1 col-md-1 col-sm-1 col-1 order-3 order-xl-2 respons2"
                  style={{ paddingLeft: "0px" }}
                >
                  <div className="bd-main-menu1 bd-main-menu">
                    <nav id="bd-mobile-menu" style={{ textAlign: "center" }}>
                      <ul>
                        <li className="menu-item-has-children">
                          <Link
                            onClick={toggleContact}
                            className={
                              splitLocation[1] === "business-software-audit"
                                ? "active"
                                : ""
                            }
                            to={"/business-software-audit"}
                          >
                            BUSINESS SOFTWARE AUDIT
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link
                            onClick={toggleContact}
                            className={
                              splitLocation[1] === "bespoke-systems"
                                ? "active"
                                : ""
                            }
                            to={"/bespoke-systems"}
                          >
                            BESPOKE SYSTEM{" "}
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link
                            onClick={toggleContact}
                            className={
                              splitLocation[1] === "business-automation"
                                ? "active"
                                : ""
                            }
                            to={"/business-automation"}
                          >
                            {" "}
                            BUSINESS AUTOMATION
                          </Link>
                        </li>
                        <li className="menu-item-has-children item-2 ">
                          <Link
                            onClick={toggleContact}
                            to={"/software-development"}
                            className={
                              splitLocation[1] === "software-development"
                                ? "active"
                                : "" ||
                                  splitLocation[1] === "mobile-app-development"
                                ? "active"
                                : ""
                            }
                          >
                            DEVELOPMENT{" "}
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              style={{ paddingLeft: "3px", fontSize: "12px" }}
                            />
                          </Link>
                          <ul className="sub-menu">
                            <Link
                              to={"/software-development"}
                              style={{ padding: "0px", color: "black" }}
                            >
                              <li>Software Development</li>
                            </Link>
                            <Link
                              to={"/mobile-app-development"}
                              style={{ padding: "0px", color: "black" }}
                            >
                              <li>Mobile Development</li>
                            </Link>
                          </ul>
                        </li>
                        <li className="menu-item-has-children">
                          <Link
                            onClick={toggleContact}
                            className={
                              splitLocation[1] === "dedicated-resource"
                                ? "active"
                                : ""
                            }
                            to={"/hire-a-resource"}
                          >
                            DEDICATED RESOURCES
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div
                    className="side-menu-icon d-xl-none text-end"
                    onClick={handleClick}
                  >
                    <button
                      className="side-toggle"
                      aria-label="bars"
                      onClick={handleClick}
                    >
                      {" "}
                      <i>
                        <FontAwesomeIcon icon={faBars} />
                      </i>
                    </button>
                  </div>
                </div>
                <div
                  className="col-sm-1 col-sm-4 col col col order-2 order-xl-3"
                  style={{ paddingRight: "0px", width: "10%" }}
                >
                  <div className="bd-header-btn">
                    <a href="tel:(0) 776-742-6142">
                      <button className="get-start">CALL NOW</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="fix">
        <div
          className={click ? "side-info1 info-open1 " : "side-info1 "}
          onClick={handleClick}
        >
          <i>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </i>
          <div className="side-info-content mt-20">
            <div
              className={
                width <= 1199
                  ? "bd-mobile-menu mean-container"
                  : "bd-mobile-menu "
              }
            >
              <div className="bd-mobile-menu mean-container">
                <div className="mean-bar">
                  <a
                    href="#nav"
                    className="meanmenu-reveal"
                    style={{ right: "0px", left: "auto", display: "inline" }}
                  >
                    <span>
                      <span>
                        <span></span>
                      </span>
                    </span>
                  </a>
                  <nav className="mean-nav">
                    <ul style={{ display: "none" }}>
                      <li className="menu-item-has-children">
                        <Link
                          to={"/business-software-audit"}
                          className={
                            splitLocation[1] === "business-software-audit"
                              ? "active"
                              : ""
                          }
                        >
                          BUSINESS SOFTWARE AUDIT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link
                          className={
                            splitLocation[1] === "bespoke-systems"
                              ? "active"
                              : ""
                          }
                          to={"/bespoke-systems"}
                        >
                          BESPOKE SYSTEMS{" "}
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          className={
                            splitLocation[1] === "business-automation"
                              ? "active"
                              : ""
                          }
                          to={"/business-automation"}
                        >
                          BUSINESS AUTOMATION
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          className={
                            splitLocation[1] === "software-development"
                              ? "active"
                              : ""
                          }
                          to={"/software-development"}
                        >
                          SOFTWARE DEVELOPMENT
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          className={
                            splitLocation[1] === "mobile-app-development"
                              ? "active"
                              : ""
                          }
                          to={"/mobile-app-development"}
                        >
                          MOBILE APPS DEVELOPMENT
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          className={
                            splitLocation[1] === "dedicated-resource"
                              ? "active"
                              : ""
                          }
                          to={"/hire-a-resource"}
                        >
                          DEDICATED RESOURCE
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <div className="bd-header-btn">
                    <button className="get-start">GET STARTED</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-infos mb-30"></div>
          </div>
        </div>
      </div>
      <div className={click ? "offcanvas-overlay" : "overlay-open"}></div>

      <section className="bd-service-area main-top-service pt-150 pb-50 gray-bg resp-top-Youtube">
        <div className="container text-center">
          <div class="col-lg-12 col-md-12 d-flex justify-content-center">
            <div class="bd-service mb-30 col-md-9">
              <div class="bd-service-icon mb-20">
                <iframe
                  src="https://www.youtube-nocookie.com/embed/EyE6_8Uklpg?autoplay=0&rel=0"
                  className="resp-videos bd-next-video"
                  allowFullScreen="allowFullScreen"
                  frameBorder="0"
                  title="Everytime You Go Away - Paul Young"
                  width="100%"
                  height="460"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="bd-header-btn2 pb-50">
              <Link to="/testimonials">
                <button className="get-starts">
                  View More Testimonial Videos
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default YoutubePage;
