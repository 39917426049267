import React, { useState } from "react";
import Spinner from "./Spinner";
import swal from "sweetalert";

const Categories = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let res = await fetch("https://mars.zithas.com/api/getintouch", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMessage("");
        setLoading(false);
        swal({
          icon: "success",
          text: "Data send Sucessfully",
        });
      } else {
        alert("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="widget mb-40">
        <div className="blog__details--wrapper mb-50">
          <div className="ablog__sidebar mb-40">
            <div
              className="bd-contact-form-wrapper mb-50"
              style={{ width: "300px" }}
            >
              <h3 className="sidebar__widget--title mb-30">Get In Touch</h3>
              <div className="widget">
                {isLoading && <Spinner />}
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="bd-contact-field mb-20">
                      <input
                        className="blog-form"
                        type="text"
                        style={{ width: "300px" }}
                        placeholder="Name"
                        name="fname"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="bd-contact-field mb-20">
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ width: "300px" }}
                        required
                      />
                    </div>

                    <div className="bd-contact-field mb-20">
                      <textarea
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{ width: "300px" }}
                        required
                      ></textarea>
                    </div>
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <div className="col-12 mt-20 mb-20">
                        <div className="bd-contact-field">
                          <button type="submit" className="theme-btn">
                            Send A Message
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
