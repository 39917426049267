import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Categories from "../../Components/seperate Components/Categories";
import axios from "axios";
import moment from "moment/moment";

const RecentPost = () => {
  const { permalink } = useParams();
  const [post, SetPost] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          "https://mars.zithas.com/api/zithasukblog"
        );
        SetPost(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);
  return (
    <div className="col-lg-4 rescentPost-responsive">
      <div className="ablog__sidebar mb-40">
        <div className="widget mb-45">
          <h3 className="sidebar__widget--title mb-30">Recent Posts</h3>
          {post.slice(0, 4).map((Recent) => {
            const publishDate = moment(Recent.publish_date).format(
              "Do MMM YYYY"
            );
            return (
              <div className="sidebar--widget__post mb-20">
                <div className="sidebar__post--thumb">
                  <Link to={`/blog/${Recent.permalink}`}>
                    <div className="post__img">
                      <img
                        className="post"
                        src={Recent.recent_image}
                        alt="img not found"
                      />
                    </div>
                  </Link>
                </div>
                <div className="sidebar__post--text">
                  <h4 className="sidebar__post--title">
                    <Link to={`/blog/${Recent.permalink}`}>{Recent.title}</Link>
                  </h4>
                  <span
                    style={{ color: "var(--clr-body-text)", fontWeight: "400" }}
                  >
                    {publishDate}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Categories />
    </div>
  );
};

export default RecentPost;
