import React from 'react'
import { faArrowRight, faCalendarDay, faMessage, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, } from "react-router-dom"
import Spinner from '../../Components/seperate Components/Spinner';
import moment from 'moment/moment';

const CompanyBlogs = ({ posts, isLoading }) => {
    return (
        <div>
            <section>
                <div className="container pt-80">
                    {isLoading ? (<Spinner />) : (
                        <div className="row">
                            {posts.map((blogs, i) => {
                                const publishDate = moment(blogs.publish_date).format("MMM Do YYYY")
                                return (
                                    <div key={i} className="col-xl-4 col-lg-4 col-md-6">
                                        <div className="col-md-12">
                                            <div className="bd-blog mb-30 grid">
                                                <div className="bd-blog-img">
                                                    <Link to={`/blog/${blogs.permalink}`}><img src={blogs.main_image} alt="blog image not found" /></Link>
                                                </div>
                                                <div className="bd-blog-text">
                                                    <div className="bd-blog-meta mb-15">
                                                        <ul>
                                                            <li><Link to={`/blog/${blogs.permalink}`} style={{textTransform:"none"}}><i > <FontAwesomeIcon icon={faCalendarDay} /></i>{publishDate}</Link></li>
                                                            <li><Link to={`/blog/${blogs.permalink}`}><i ><FontAwesomeIcon icon={faMessage} /></i>0 Comments</Link></li>
                                                        </ul>
                                                    </div>
                                                    <h4 className="bd-blog-title mb-40"><Link to={`/blog/${blogs.permalink}`}>
                                                        {blogs.title.slice(0, 36) + " ..."}
                                                    </Link></h4>
                                                    <div className="bd-blog-author">
                                                        <div className="bd-blog-author-info">
                                                            <img src="https://img.zithas.co.uk/eva-blog_nj5r1k.webp" alt="author image not found" />
                                                            <h6 className="bd-blog-author-info-title">Eva Fernandez</h6>
                                                        </div>
                                                        <div className="bd-blog-author-link">
                                                            <Link to={`/blog/${blogs.permalink}`}>Read More <i ><FontAwesomeIcon icon={faArrowRight} /></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}

export default CompanyBlogs