import React, { useEffect, useState } from "react";
import Header1 from "../../Components/seperate Components/Header1";
import Footer from "../../Components/Footer";
import "../../Styles/Redirecting Styles/FooterStyle/MainTestimonial.css";
import "../../Styles/Testimonial.css";
import trustPilot from "../../assets/logo/trustpilot.png";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPhone, faPlay } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import CompanyTestimonial from "../../Components/seperate Components/CompanyTestimonial";
import Pagination from "../../Components/seperate Components/Pagination";
import { Helmet } from "react-helmet";
import zithasImg from "../../assets/All Images/zithas.png";
import creditsafe from "../../assets/All Images/creditsafe.png";
import swal from "sweetalert";
import Spinner from "../../Components/seperate Components/Spinner";
import Shape2 from "../../assets/brand/choose-shape-2.png";
import flat from "../../assets/All Images/flat.jpg";
import powerfullApi from "../../assets/All Images/Api-Un.avif";
import partner1 from "../../assets/partner/partnership1.avif";
import partner2 from "../../assets/partner/partnership2.jpg";
import partner3 from "../../assets/partner/partnership3.avif";

import "../../Styles/AnimateImg.css";

const Creditsafe = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [spinner, setSpinner] = useState(false);

  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function ValidateEmail() {
    var email = document.getElementById("txtEmail").value;
    var lblError = document.getElementById("lblError");
    lblError.innerHTML = "";
    var expr =
      /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (!expr.test(email)) {
      lblError.innerHTML = "Invalid email address.";
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    // https://mars.zithas.com/api/crediSafe
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("contact", mobile);
      formData.append("message", "Lead from the creditSafe");
      formData.append("industry", company_name);
      formData.append("service", "-");
      formData.append("location", 12);
      const response = await axios({
        method: "post",
        url: "https://mars.zithas.com/api/add_client",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      if (response) {
        setSpinner(false);
        setName("");
        setCompany_name("");
        setEmail("");
        setMobile("");
        swal({
          icon: "success",
          text: "Data has been send Sucessfully we will get in touch with you as soon as Possible",
        });
      }
    } catch (err) {
      setSpinner(false);
      console.log(err);
    }
  };

  return (
    <div>
      {spinner && <Spinner />}
      <Helmet>
        <title>Client Testimonials | Zithas Technologies</title>
        <meta
          name="description"
          content="Some wonderful comments from our clients about our software and mobile app development company. See a few of their endorsements right here."
        />

        <meta name="keywords" content="Creditsafe" />
      </Helmet>
      <Header1 />

      <div className="">
        <div
          className=" bd-page-title-bg3 pt-220 pb-155"
          style={{ height: "380px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="bd-page-title text-center z-index"
                  style={{ bottom: "45px" }}
                >
                  <h1
                    className="breadcrumb-title"
                    style={{ fontSize: "50px", color: "#4a026c" }}
                  >
                    {/* Creditsafe */}
                    Strategic Partnership with Creditsafe
                    {/* Strategic Partnership */}
                  </h1>
                  <div className="breadcrumb-menu">
                    <nav className="breadcrumb-trail breadcrumbs">
                      <p style={{ color: "black" }}>
                        {/* Zithas Technologies and Creditsafe */}
                      </p>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="bd-about-area  bd-section-title-wrapper">
        <div className="bd-about-six-area pt-80">
          <div className="container" style={{ maxWidth: "1270px" }}>
            <div className="row">
              <h2 className="bd-section-title mb-3  ">
                Strategic Partnership with Creditsafe
              </h2>
              <p className=" fw-bold">Zithas Technologies and Creditsafe</p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bd-about-six-area pt-80">
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row">
            <div className="col-lg-6 order-2 order-md-1">
              <div className="bd-about-six-text">
                <div className="bd-section-title-wrapper mb-25">
                  <p className="mb-15 kick-section-tittle">
                    At Zithas Technologies, we believe in the power of
                    collaboration to drive innovation and deliver exceptional
                    value to our clients. We're proud to announce our strategic
                    partnership with Creditsafe, a global leader in business
                    intelligence solutions.
                  </p>
                  <h4 className="mb-15">Key Benefits of Our Partnership:</h4>
                </div>
                <div className="bd-about-six-feature">
                  <ul className="mb-45">
                    <li className="point-software" style={{ fontSize: "20px" }}>
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Expertise and Resources Combined:
                    </li>
                    <p className="soft-para">
                      Leveraging the strengths of both Zithas and Creditsafe to
                      deliver superior solutions.
                    </p>
                    <li className="point-software" style={{ fontSize: "20px" }}>
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>{" "}
                      Innovative Products and Services:
                    </li>
                    <p className="soft-para">
                      Developing advanced tools to help businesses make smarter
                      decisions.
                    </p>
                    <li className="point-software" style={{ fontSize: "20px" }}>
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      Enhanced Business Intelligence:
                    </li>
                    <p className="soft-para">
                      Providing unparalleled access to critical data for
                      strategic decision-making.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-6 order-1 order-md-2">
              <div className="bd-choose-img text-end mb-30 pt-md-0 pt-4 z-index">
                <div className="row align-items-center custom-mar-15">
                  <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                    <div className="bd-choose-img-inner mb-15 bd-choose-img-inner-1 animate-hover">
                      <i>
                        <img
                          src={partner1}
                          // src="https://img.zithas.co.uk/bespoke-section-1.1_j08lfy.avif"
                          className="resp-img bordertop-img1 h-mb-resp-img"
                          alt="img not found"
                          style={{ height: "16rem", objectFit: "cover" }}
                        />
                      </i>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-6 custom-pad-15">
                    <div className="bd-choose-img-inner mb-15 animate-hover">
                      <i>
                        <img
                          src={partner2}
                          // src="https://img.zithas.co.uk/bespoke-section-1_xkcd9x.avif"
                          className="resp-img bordertop-img2 h-mb-resp-img"
                          alt="img not found"
                          style={{ height: "16rem", objectFit: "cover" }}
                        />
                      </i>
                    </div>
                    <div className="bd-choose-img-inner mb-15 animate-hover">
                      <i>
                        <img
                          src={partner3}
                          // src="https://img.zithas.co.uk/bespoke-section-1.2_dabfk6.avif"
                          className="resp-img bordertop-img3 h-mb-resp-img"
                          alt="img not found"
                          style={{ height: "16rem", objectFit: "cover" }}
                        />
                      </i>
                    </div>
                  </div>
                </div>
                <img
                  className="bd-choose-shape bd-choose-shape-2"
                  src={Shape2}
                  alt="shape img not found"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* pb-3 pt-md-3 mt-md-4 */}
      <section className="bd-about-area py-5">
        <div className="bd-custom-container">
          <div className="">
            <div className="bd-about-shape-box">
              <div
                className="container bd-section-title-wrapper"
                style={{ maxWidth: "1270px" }}
              >
                <div className="">
                  <h3 className="four-point-tittle mb-md-4">
                    Harnessing Creditsafe for Better Decisions
                  </h3>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-9 order-2 order-md-1">
                    <div
                    //   className="border p-4 shadow-sm"
                    //   style={{ borderRadius: "0 0 8rem 0" }}
                    >
                      <p style={{ textAlign: "justify" }}>
                        Creditsafe is more than a credit reporting agency—it's a
                        vital tool for unlocking business intelligence. It
                        provides comprehensive data and insights, including
                        credit scores, financial health metrics, and company
                        background information. This enables businesses to make
                        informed decisions, assess risks, and identify
                        opportunities. With Creditsafe, businesses can optimize
                        performance, stay competitive, and seize new
                        opportunities confidently.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 pb-4 pb-md-0 order-1 order-md-2 d-flex justify-content-center pt-3 pt-md-0">
                    <div className="w-50 animate-hover">
                      <i>
                        <img
                          src={creditsafe}
                          className="img-fluid shadow-lg"
                          alt="about img not found"
                          style={{ borderRadius: "2rem 0 2rem 0" }}
                        />
                      </i>
                      {/* <img
                        className="animateImg2"
                        src={Shape2}
                        alt="shape img not found"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bd-about-area">
        <div className="bd-custom-container pt-md-4">
          <div className="pt-md-5">
            <div className="bd-about-shape-box">
              <div className="container" style={{ maxWidth: "1270px" }}>
                <h3 className="four-point-tittle mb-md-4 bespoke-lastSection_tittle md-pr-50">
                  Streamlined Access with Zithas API Integration
                </h3>
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-lg-8">
                    <div className="bd-about-text bd-about-text-four mb-40 z-index">
                      <div className="bd-section-title-wrapper">
                        <p className="">
                          In today's dynamic business environment, seamless
                          integration is essential for productivity. Zithas
                          Technologies' API expertise streamlines operations and
                          provides access to critical financial information
                          across platforms like QuickBooks, Xero, and custom
                          CRMs. Our API aids in assessing creditworthiness,
                          monitoring financial health, and identifying risks,
                          enabling informed decisions, optimized cash flow, and
                          sustainable growth.Experience the power of Zithas
                          Technologies' API integration and revolutionize your
                          business operations today.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xl-6 col-lg-8 pl-30 fifth_section_respon2">
                    <div className="bd-about-img mb-30">
                      <img
                        src={powerfullApi}
                        style={{ height: "405px" }}
                        className="xss-w-full rounded-10"
                        alt="about img not found"
                      />
                    </div>
                  </div> */}
                  <div className="col-xl-6 col-lg-4  mt-0">
                    <div className="bd-choose-img mb-30 z-index">
                      <div className="bd-choose-info-wrapper">
                        <div className="container">
                          <div className="row mb-50">
                            <div className="col-6 ">
                              <div className="animate-hover">
                                <div className="bd-choose-info-icon mb-15">
                                  <i>
                                    <img
                                      src="https://img.zithas.co.uk/transaction-64_gjs6ug.avif"
                                      alt="img not found"
                                    />
                                  </i>
                                </div>
                                <div className="bd-choose-info-text">
                                  <h3
                                    className="bd-choose-info-title mb-15"
                                    style={{ color: "#5e5858" }}
                                  >
                                    Streamlined Operations
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="animate-hover">
                                <div className="bd-choose-info-icon mb-15">
                                  <i>
                                    <img
                                      src="https://img.zithas.co.uk/unique_lufjti.webp"
                                      alt="img not found"
                                    />
                                  </i>
                                </div>
                                <div className="bd-choose-info-text">
                                  <h3
                                    className="bd-choose-info-title mb-15"
                                    style={{ color: "#5e5858" }}
                                  >
                                    User-Friendly Interface
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row ">
                            <div className="col mr-25">
                              <div className="animate-hover">
                                <div className="bd-choose-info-icon mb-15">
                                  <i>
                                    <img
                                      src="https://img.zithas.co.uk/business-process-64_o09iuu.webp"
                                      alt="img not found"
                                    />
                                  </i>
                                </div>
                                <div className="bd-choose-info-text">
                                  <h3
                                    className="bd-choose-info-title mb-15"
                                    style={{ color: "#5e5858" }}
                                  >
                                    Instant Access to Financial Information
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="animate-hover">
                                <div className="bd-choose-info-icon mb-15">
                                  <i>
                                    <img
                                      src="https://img.zithas.co.uk/cost-64_vtijdz.webp"
                                      alt="img not found"
                                    />
                                  </i>
                                </div>
                                <div className="bd-choose-info-text">
                                  <h3
                                    className="bd-choose-info-title mb-15"
                                    style={{ color: "#5e5858" }}
                                  >
                                    Enhanced Decision-Making
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bd-about-area py-5">
        <div className="bd-custom-container">
          <div className="pb-5">
            <div className="bd-about-shape-box">
              <div
                className="container bd-section-title-wrapper"
                style={{ maxWidth: "1270px" }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-4 animate-hover">
                    <i>
                      <img
                        className="img-fluid"
                        src={powerfullApi}
                        // src={flat}
                        alt="shape img not found"
                      />
                    </i>
                    <img
                      className="animateImg3"
                      src={Shape2}
                      alt="shape img not found"
                    />
                  </div>
                  <div className="col-lg-8">
                    <div className="ps-3">
                      <div className="">
                        <h3 className="mb-4 four-point-tittle">
                          {/* Seamless API Integration Starting at */}
                          API Integration Starting at
                          <span
                            style={{
                              color: "rgb(195, 41, 72)",
                              // color: "rgb(255, 191, 30)"
                            }}
                          >
                            {" "}
                            £1199
                          </span>
                        </h3>
                      </div>
                      <div className="">
                        <div>
                          <div
                            className=""
                            //   style={{ borderRadius: "0 0 8rem 0" }}
                          >
                            <p
                              className="mb-0"
                              style={{ textAlign: "justify" }}
                            >
                              At Zithas Technologies, we prioritize delivering
                              value without compromise. We're offering our
                              integration package starting at an affordable
                              £1199. This solution allows businesses of all
                              sizes to fully leverage Creditsafe, accessing
                              critical financial information, mitigating risks,
                              and making informed decisions seamlessly. From
                              startups to large enterprises, our integration
                              package provides exceptional value without
                              breaking the bank. With Zithas Technologies,
                              accessing valuable insights has never been easier
                              or more affordable.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        className="bd-about-area "
        style={{ backgroundColor: "rgba(234, 188, 255, 0.63)" }}
        // style={{ backgroundColor: "#eb282321" }}
      >
        <div className="bd-custom-container">
          <div className="py-5">
            <div className="bd-about-shape-box">
              <div
                className="container bd-section-title-wrapper d-flex justify-content-center"
                style={{ maxWidth: "1270px" }}
              >
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <div>
                      <h4
                        className="first-para-resp"
                        style={{ color: "#4a026c" }}
                      >
                        Looking for Top-Tier Integration Solutions?
                      </h4>
                      <h3
                        className="contact_section"
                        style={{ color: "#4a026c" }}
                      >
                        You've come to the right place.
                      </h3>
                      <h4
                        className="first-para-resp"
                        style={{ color: "#4a026c" }}
                      >
                        Unmatched integration solutions—let's get started today!
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-5 pt-4 pt-md-0">
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row contact-reponsive">
                        <div className="col-md-12">
                          <div className="bd-contact-field mb-3">
                            <input
                              type="text"
                              placeholder="Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              className="contact-form form-reponsive"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12 email-form-responsive">
                          <div className="bd-contact-field mb-3">
                            <input
                              type="email"
                              value={email}
                              className="form-reponsive"
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Email"
                              id="txtEmail"
                              onKeyUp={ValidateEmail}
                              required
                            />
                            <span id="lblError" className="text-danger"></span>
                          </div>
                        </div>
                        <div className="col-md-12 email-form-responsive">
                          <div className="bd-contact-field mb-3">
                            <input
                              type="text"
                              value={mobile}
                              className="form-reponsive phone-field"
                              onChange={(e) => setMobile(e.target.value)}
                              placeholder="Phone No"
                              required
                              maxLength={10}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key))
                                  event.preventDefault();
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="bd-contact-field mb-3">
                            <input
                              type="text"
                              className="form-reponsive"
                              value={company_name}
                              onChange={(e) => setCompany_name(e.target.value)}
                              placeholder="Company Name"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="bd-contact-field">
                            <button type="submit" className="theme-btn">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section
        className="bd-video-area py-5"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container py-3" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner align-items-center">
            <div className="col-md-7">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for Top-Tier Integration Solutions?
                  {/* Ready to take your business to the next level? */}
                </h2>
                <p className="contact_section second-para-resp">
                  You've come to the right place.
                  {/* Contact us today to learn more about our partnership with
                  Creditsafe. */}
                </p>
                <p className="contact_subtext">
                  Unmatched integration solutions—let's get started today!{" "}
                  {/* Explore how our integration solutions can benefit your
                  business! */}
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="pt-4 pt-md-0">
                <form action="" onSubmit={handleSubmit}>
                  <div className="row contact-reponsive">
                    <div className="col-md-12">
                      <div className="bd-contact-field mb-3">
                        <input
                          type="text"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="contact-form form-reponsive"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12 email-form-responsive">
                      <div className="bd-contact-field mb-3">
                        <input
                          type="email"
                          value={email}
                          className="form-reponsive"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          id="txtEmail"
                          onKeyUp={ValidateEmail}
                          required
                        />
                        <span id="lblError" className="text-danger"></span>
                      </div>
                    </div>
                    <div className="col-md-12 email-form-responsive">
                      <div className="bd-contact-field mb-3">
                        <input
                          type="text"
                          value={mobile}
                          className="form-reponsive phone-field"
                          onChange={(e) => setMobile(e.target.value)}
                          placeholder="Phone No"
                          required
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key))
                              event.preventDefault();
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="bd-contact-field mb-3">
                        <input
                          type="text"
                          className="form-reponsive"
                          value={company_name}
                          onChange={(e) => setCompany_name(e.target.value)}
                          placeholder="Company Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="bd-contact-field">
                        <button
                          type="submit"
                          className="theme-btn text-white"
                          style={{
                            // color: "rgb(92, 3, 133)",
                            backgroundColor: "rgb(195, 41, 72)",
                          }}
                          // style={{ color: "rgb(195, 41, 72)" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Creditsafe;
