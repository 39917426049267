import React, { useState, useEffect } from "react";
import Header1 from "../seperate Components/Header1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPhone } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer";
import "../../Styles/seperate Comp Css/CaseStudy.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import Spinner from "../seperate Components/Spinner";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { Helmet } from "react-helmet";

const CaseStudy1 = ({ data }) => {
  const { permalink } = useParams();
  const [cases, SetCases] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // for meta tags
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `https://mars.zithas.com/api/casestudies_uk/${permalink}`
        );
        SetCases(data);

        switch (data[0].permalink) {
          case "picton-house-case-study":
            setTitle("Website Design and Development of Picton House Ltd.");
            setDescription(
              "Any design and development work of a website is done by Zithas. Here we have done for Picton House Ltd.- a hotel chain business."
            );
            setKeywords("Case study: Website Development for Picton House");
            break;
          case "c2k-bms-casestudy":
            setTitle("Website Design and Development of Control 2K Limited");
            setDescription(
              "Any design and development of software and website is done by our creative team. Here we have developed website for Control 2k Limited which is engaged in Control Systems Training business."
            );
            setKeywords("Case Study: Website Development for c2k bms");
            break;
          case "cyclotricity-uk":
            setTitle(
              "Software and Website Design and Development of Cyclotricity Limited"
            );
            setDescription(
              "Cyclotricity produces and sells ordinary and electric bikes. We have design and develop a website through which they can easily sell cycles."
            );
            setKeywords("Case Study: Website Development for cyclotricity uk");
            break;
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Header1 />
      <div
        className="bd-page-title-area bd-page-title-bg_career pt-175 pb-155 main-heading"
        style={{ height: "380px" }}
      >
        <div className="container">
          <div className="row display-flex-wrap-resp">
            <div className="col-12">
              <div className="bd-page-title text-center z-index">
                <h2
                  className="breadcrumb-title"
                  style={{ fontSize: "50px", color: "#4a026c" }}
                >
                  Case Study
                </h2>
                <div className="breadcrumb-menu">
                  <nav className="breadcrumb-trail breadcrumbs">
                    <p style={{ color: "black" }}>
                      How We Help Your Business to get Automised and Succeed!
                    </p>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {cases.map((studies) => {
            return (
              <>
                <section className="bd-service-details-area pt-80 pb-80">
                  <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                      <div className="col-lg-8 case-study-section">
                        <div className="bd-service-details">
                          <div className="bd-service-details-img ">
                            <img
                              src={studies.detailed_image}
                              className="main-image1"
                              alt="img not found"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="bd-service-area">
                  <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 bd-case-studies-section1">
                        <div className="bd-service1- mb-30">
                          <h4 className="bd-service-title mb-20">
                            {studies.company_name}
                          </h4>
                          <p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: studies.about_company,
                              }}
                            ></div>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 bd-case-studies-section2">
                        <div className="bd-service1-">
                          <h4 className="bd-service-title mb-20">Problems.</h4>
                          <ul className="mb-45" style={{ listStyle: "none" }}>
                            <li className="case-point-sec pb-10">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: studies.problems,
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 bd-case-studies-section3">
                        <div className="bd-service1- mb-30">
                          <p
                            className="pt-30 pb-20"
                            style={{ textAlign: "center" }}
                          >
                            {" "}
                            <img
                              src={studies.company_logo}
                              width="150px"
                              alt="img not found"
                            />
                          </p>
                          <div
                            className="bd-blog-author-link"
                            style={{ textAlign: "center" }}
                          >
                            <a target="_blank" href={studies.website}>
                              Visit Website{" "}
                              <FontAwesomeIcon icon={faArrowRight} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="bd-service-area pt-30 pb-70 ">
                  <div className="container" style={{ maxWidth: "1270px" }}>
                    <div className="row"></div>
                    <div className="row sixth-main-responsive">
                      <h2 className="mb-30 sixth-section-soft text-center">
                        Strategy & Approach
                      </h2>
                      <div className="col-lg-4 col-md-6 sixth-soft-section1">
                        <div className="bd-service1 mb-30">
                          <h4 className="bd-service-title mb-20">
                            Tools & Method used
                          </h4>
                          <ul className="mb-45" style={{ listStyle: "none" }}>
                            <li className="case-point-sec pb-10">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: studies.tools_method,
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 sixth-soft-section1">
                        <div className="bd-service1 mb-30">
                          <p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: studies.description,
                              }}
                            ></div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="bd-service-area pb-60 ">
                  <div className="container" style={{ maxWidth: "1270px" }}>
                    <h2 className="mb-30 sixth-section-soft text-center">
                      Achived Results
                    </h2>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="bd-service1 mb-30">
                          <div
                            className="bd-service-icon mb-20"
                            style={{ textAlign: "center" }}
                          >
                            <i>
                              <img src={studies.icon_1} alt="img not found" />
                            </i>
                          </div>
                          <p className="case-study-boxes">
                            {studies.i1_description}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="bd-service1 mb-30">
                          <div
                            className="bd-service-icon mb-20 "
                            style={{ textAlign: "center" }}
                          >
                            <i>
                              <img src={studies.icon_2} alt="img not found" />
                            </i>
                          </div>
                          <p className="case-study-boxes">
                            {studies.i2_description}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="bd-service1 mb-30">
                          <div
                            className="bd-service-icon mb-20"
                            style={{ textAlign: "center" }}
                          >
                            <i>
                              <img src={studies.icon_3} alt="img not found" />
                            </i>
                          </div>
                          <p className="case-study-boxes">
                            {studies.i3_description}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="bd-service1 mb-30">
                          <div
                            className="bd-service-icon mb-20"
                            style={{ textAlign: "center" }}
                          >
                            <i>
                              <img src={studies.icon_4} alt="img not found" />
                            </i>
                          </div>
                          <p className="case-study-boxes">
                            {studies.i4_description}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="bd-service1 mb-30">
                          <div
                            className="bd-service-icon mb-20"
                            style={{ textAlign: "center" }}
                          >
                            <i>
                              <img src={studies.icon_5} alt="img not found" />
                            </i>
                          </div>
                          <p className="case-study-boxes">
                            {studies.i5_description}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="bd-service1 mb-30">
                          <div
                            className="bd-service-icon cell mb-20"
                            style={{ textAlign: "center" }}
                          >
                            <i>
                              <img src={studies.icon_6} alt="img not found" />
                            </i>
                          </div>
                          <p className="case-study-boxes">
                            {studies.i6_description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="bd-testimonial-area-four pb-80">
                  <div className="container">
                    <div className="bd-testimonial-four-active swiper-container">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="bd-testimonial-four">
                            <div className="bd-testimonial-four-img">
                              <img
                                src={studies.testimonial_image}
                                alt="img not found"
                              />
                            </div>
                            <div className="bd-testimonial-four-text">
                              <i className="fas fa-quote-left mb-20"></i>
                              <p>
                                {" "}
                                <div
                                  className="bd-testimonial-four-text-title mb-15"
                                  dangerouslySetInnerHTML={{
                                    __html: studies.testimonial_description,
                                  }}
                                ></div>
                              </p>
                              <h6>{studies.testimonial_name}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="case-studie-button pb-80">
                  <Link to={"/case-studies"} onClick={toggleContact}>
                    <button className="get-start size-button">
                      View More case Studies
                    </button>
                  </Link>
                </div>
              </>
            );
          })}
        </>
      )}
      <section
        className="bd-video-area pt-135 pb-130"
        style={{ backgroundColor: "#5c0385" }}
      >
        <div className="container" style={{ maxWidth: "1270px" }}>
          <div className="row responsive-banner">
            <div className="col-lg-12 first_section">
              <div className="bd-video-text">
                <h2 className="bd-video-title first-para-resp">
                  Looking for Business Automation Expert?
                </h2>
                <p className="contact_section second-para-resp">
                  You're at the right place. The right time
                </p>
                <p className="contact_subtext">
                  Surprisingly great results achieved, Let's get started right
                  now !
                </p>
              </div>
            </div>
            <div className="col-lg-12 second_section">
              <div className="contact-stuff pt-30">
                <div className="contact_icon icon_respon pt-15 pl-50">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ fontSize: "30px" }}
                  />
                </div>
                <div className="contact_txt ">
                  <p className="contact-sub-text">CALL US NOW</p>
                  <a
                    href="tel:(0) 776-742-6142"
                    style={{ fontSize: "17px" }}
                    className="mbb-5 pb-20"
                  >
                    (0) 776-742-6142
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CaseStudy1;
