import React, { useState, useEffect } from "react";
import "../Styles/header.css";
import "../Styles/Theme.css";
import "../Styles/meanmenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faGlobe,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const [stickyClass, setStickyClass] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // You can adjust the threshold (100 in this example) as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // sticky Navbar
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 80 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  window.addEventListener("scroll", stickNavbar);
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.addEventListener("scroll", stickNavbar);
  }, []);
  const change = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", change);
    return () => window.addEventListener("scroll", change);
  }, []);
  const [click, setClick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  window.addEventListener("resize", function (event) {
    setWidth(window.innerWidth);
  });
  const toggleContact = () => {
    scroll.scrollToTop(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    const redirectionLinks = {
      android: "#",
      ios: "#",
      web: "https://clients.zithas.com",
    };

    const selectedLink = redirectionLinks[selectedValue];

    window.open(selectedLink, "_blank");
  };

  return (
    <div>
      <header>
        <div
          style={{ top: "0" }}
          className={
            navbar
              ? "bd-header-area active bg-white"
              : `bd-header-area-lg ${stickyClass} bg-white`
          }
        >
          <div className="bd-header-border">
            <div className="bd-custom-container">
              <div className="row align-items-center justify-content-center pt-md-2 mx-0">
                <div className="col-md-4 col-6">
                  <div className="logo-navbar">
                    <NavLink to="/">
                      <img
                        src={
                          "https://img.zithas.co.uk/zithas-logo-og_wfrysv.avif"
                        }
                        style={{ width: "180px", zIndex: "999" }}
                        alt="logo not found"
                      />
                    </NavLink>
                  </div>
                </div>

                <div className="col-md-8 col-6">
                  <div className=" d-flex align-items-center justify-content-end">
                    {/* call */}
                    <div
                      className="text-end d-md-block d-none"
                      style={{ paddingTop: "6px" }}
                    >
                      <div className="">
                        <a
                          href="tell:(0) 776-742-6142"
                          class="text-decoration-none text-dark"
                          style={{ fontSize: "18px" }}
                        >
                          {" "}
                          <span class=" fw-bold"> Call for Inquiry : </span> (0)
                          776-742-6142
                        </a>
                      </div>
                    </div>

                    {/* <!-- mobile menu activation --> */}
                    <div
                      className="side-menu-icon d-xl-none text-end ms-4"
                      onClick={handleClick}
                    >
                      <button
                        className="side-toggle pt-3"
                        onClick={handleClick}
                      >
                        {" "}
                        <i className={``}>
                          <FontAwesomeIcon icon={faBars} />
                        </i>
                      </button>
                    </div>

                    {/* login btn */}
                    <div
                      className="text-end ms-md-4"
                      style={{ paddingTop: "6px" }}
                    >
                      <div className="bd-header-btn">
                        <button
                          className="get-start2 me-md-4"
                          data-bs-toggle="modal"
                          data-bs-target="#clientLoginModal2"
                        >
                          Client Login{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* =============================== */}

                <div
                  className={`row justify-content-center scrolled ${
                    isScrolled ? "" : "scrolled"
                  }`}
                  style={{
                    paddingLeft: "0px",
                    marginTop: "10px",
                    marginRight: "-10px",
                  }}
                >
                  {/* <div className="col-md-1"></div> */}
                  <div className="col-md-12 bd-main-menu txt-cent">
                    <nav id="bd-mobile-menu">
                      <ul style={{ textAlign: "center" }}>
                        <li className="menu-item-has-children">
                          <NavLink
                            onClick={toggleContact}
                            to={"/business-software-audit"}
                            className="nav_txt_size"
                          >
                            BUSINESS SOFTWARE AUDIT
                          </NavLink>
                        </li>
                        <li className="menu-item-has-children">
                          <NavLink
                            onClick={toggleContact}
                            to={"/bespoke-systems"}
                          >
                            BESPOKE SYSTEMS{" "}
                          </NavLink>
                        </li>
                        <li className="menu-item-has-children">
                          <NavLink
                            onClick={toggleContact}
                            to={"/business-automation"}
                          >
                            BUSINESS AUTOMATION{" "}
                          </NavLink>
                        </li>

                        <li className="menu-item-has-children">
                          <NavLink
                            onClick={toggleContact}
                            to={"/software-development"}
                          >
                            SOFTWARE DEVELOPMENT{" "}
                          </NavLink>
                        </li>

                        <li className="menu-item-has-children">
                          <NavLink
                            onClick={toggleContact}
                            to={"/website-development"}
                          >
                            WEBSITE DEVELOPMENT{" "}
                          </NavLink>
                        </li>

                        <li className="menu-item-has-children">
                          <NavLink
                            onClick={toggleContact}
                            to={"/mobile-app-development"}
                          >
                            MOBILE DEVELOPMENT{" "}
                          </NavLink>
                        </li>

                        {/* <li className="menu-item-has-children item-2 "><NavLink onClick={toggleContact} to={"/software-development"} >DEVELOPMENT <FontAwesomeIcon icon={faChevronDown} style={{ paddingLeft: "3px", fontSize: "12px" }} /></NavLink>
                                                    <ul className="sub-menu">
                                                        <NavLink to={"/software-development"} style={{ padding: "0px", color: "#000" }} >
                                                            <li>Software Development</li>
                                                        </NavLink>
                                                        <NavLink to={"/website-development"} style={{ padding: "0px", color: "#000" }} >
                                                            <li>Website Development</li>
                                                        </NavLink>
                                                        <NavLink to={"/mobile-app-development"} style={{ padding: "0px", color: "#000" }} >
                                                            <li>Mobile Development</li>
                                                        </NavLink>
                                                    </ul>
                                                </li> */}

                        <li className="menu-item-has-children">
                          <NavLink
                            onClick={toggleContact}
                            to={"/hire-a-resource"}
                          >
                            HIRE A RESOURCE
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  {/* </div> */}
                </div>

                {/* <div className="col-xl-6 col-lg-1 col-md-1 col-sm-1 col-1 order-3 order-xl-2 respons2" style={{ paddingLeft: "0px" }}>
                                    <div className="bd-main-menu txt-cent">
                                        <nav id="bd-mobile-menu">
                                            <ul style={{ textAlign: "center" }}>
                                                <li className="menu-item-has-children"><Link onClick={toggleContact} to={"/business-software-audit"} className="nav_txt_size">BUSINESS SOFTWARE AUDIT</Link>
                                                </li>
                                                <li className="menu-item-has-children"><Link onClick={toggleContact} to={"/bespoke-systems"}  >BESPOKE SYSTEMS </Link>
                                                </li>
                                                <li className="menu-item-has-children"><Link onClick={toggleContact} to={"/business-automation"}  >BUSINESS AUTOMATION </Link>
                                                </li>
                                                <li className="menu-item-has-children item-2 "><Link onClick={toggleContact} to={"/software-development"} >DEVELOPMENT <FontAwesomeIcon icon={faChevronDown} style={{ paddingLeft: "3px", fontSize: "12px" }} /></Link>
                                                    <ul className="sub-menu">
                                                        <Link to={"/software-development"} style={{ padding: "0px", color: "black" }} >
                                                            <li>Software Development</li>
                                                        </Link>
                                                        <Link to={"/website-development"} style={{ padding: "0px", color: "black" }} >
                                                            <li>Website Development</li>
                                                        </Link>
                                                        <Link to={"/mobile-app-development"} style={{ padding: "0px", color: "black" }} >
                                                            <li>Mobile Development</li>
                                                        </Link>
                                                    </ul>
                                                </li>
                                                <li className="menu-item-has-children"><Link onClick={toggleContact} to={"/hire-a-resource"}  >HIRE A RESOURCE</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="side-menu-icon d-xl-none text-end" onClick={handleClick}>
                                        <button className="side-toggle" aria-label="bars" onClick={handleClick}> <i><FontAwesomeIcon icon={faBars} /></i></button>
                                    </div>
                                </div> */}

                {/* 
                                <div className="col-sm-1 col-sm-4 col col col order-2 order-xl-3 btn_respon" style={{ paddingRight: "0px", width: "10%" }}>
                                    <div className="bd-header-right text-end">
                                        <div className="bd-header-btn">
                                            <a href="tel:(0) 776-742-6142">
                                                <button className={navbar ? "get-start" : "get-start2"} href="tel:(0) 776-742-6142" > CALL NOW</button>
                                            </a>
                                        </div>

                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* mobile */}
      <div className="fix">
        <div
          className={click ? "side-info1 info-open1 " : "side-info1 "}
          onClick={handleClick}
        >
          <i>
            <FontAwesomeIcon icon={faTimes} />{" "}
          </i>
          <div className="side-info-content mt-20">
            <div
              className={
                width <= 1199
                  ? "bd-mobile-menu mean-container"
                  : "bd-mobile-menu "
              }
            >
              <div className="bd-mobile-menu mean-container">
                <div className="mean-bar">
                  <a
                    href="#nav"
                    className="meanmenu-reveal"
                    style={{ right: "0px", left: "auto", display: "inline" }}
                  >
                    <span>
                      <span>
                        <span></span>
                      </span>
                    </span>
                  </a>
                  <nav className="mean-nav">
                    <ul style={{ display: "none" }}>
                      <li className="menu-item-has-children">
                        <Link to={"/business-software-audit"}>
                          BUSINESS SOFTWARE AUDIT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/bespoke-systems"}>BESPOKE SYSTEMS </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/business-automation"}>
                          BUSINESS AUTOMATION
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/software-development"}>
                          SOFTWARE DEVELOPMENT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/website-development"}>
                          WEBSITE DEVELOPMENT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/mobile-app-development"}>
                          MOBILE APPS DEVELOPMENT
                        </Link>
                      </li>

                      <li className="menu-item-has-children">
                        <Link to={"/hire-a-resource"}>HIRE A RESOURCE</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="mt-20">
                  <button
                    className="get-start"
                    style={{ backgroundColor: "#c32948" }}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
            <div className="contact-infos mb-30"></div>
          </div>
        </div>
      </div>
      <div className={click ? "offcanvas-overlay" : "overlay-open"}></div>

      {/* popup */}

      <div
        className="modal fade"
        id="clientLoginModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  pb-direct-btn">
          <div
            className="modal-content"
            style={{ width: "80%", padding: "12px" }}
          >
            <div className="bd-redirect">
              <div style={{ width: "100%" }}>
                <div>
                  <a href="https://clients.zithas.co.uk" target="_blank">
                    <span>
                      <FontAwesomeIcon icon={faGlobe} className=" pr-10" />
                    </span>
                    Login via Web
                  </a>
                </div>

                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mars.bms&pli=1"
                    target="_blank"
                  >
                    {/* <span><FontAwesomeIcon icon={faAndroid} className=" pr-10" /></span> */}
                    <i
                      className="bi bi-android2 pr-10"
                      style={{ fontSize: "22px" }}
                    ></i>
                    Download our Android App
                  </a>
                </div>

                {/* <div>
                                    <a>
                                        <span><FontAwesomeIcon icon={faApple} className=" pe-3" /></span>
                                        Download our IOS App
                                    </a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
